import { AuthContext } from "Context/AuthContext";
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

const ProtectedUpdateEmail = (props) => {
  const { isMailAdded } = useContext(AuthContext);
  if (isMailAdded === false) {
    return props.children;
  } else {
      return <Navigate to={"/profile"}></Navigate>;
  }
};

export default ProtectedUpdateEmail;
