import React, { useContext, useEffect, useState } from "react";
import deleteIcon from "../../Assets/filter/delete-folder.svg";
import addIcon from "../../Assets/filter/plus-squared.svg";
import EditScrapbookImgModal from "./Scrapbook/PopUp/EditScrapbookImgModal";
import AddNote from "./Scrapbook/PopUp/AddNote";
import VoiceModal from "./Scrapbook/PopUp/VoiceModal";
import AddLink from "./Scrapbook/PopUp/AddLink";
import { useLocation, useParams } from "react-router-dom";
import { DeleteScrapbookImagesContext } from "Context/DeleteScrapbookImagesContext";
import { PopUpContext } from "Context/PopUpContext";
import axios from "axios";
import { toast } from "react-toastify";
import { DeleteScrapbookFilesContext } from "Context/DeleteScrapbookFilesContext";
import AddFile from "./Scrapbook/PopUp/AddFile";
import useSettings from "hooks/useSettings";
import { SettingsContext } from "Context/SettingsContext";

const DetailsActions = ({ actions, isImage }) => {
  const [editScrapbookImgModal, setEditScrapbookImgModal] = useState(false);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [addVoiceModal, setAddVoiceModal] = useState(false);
  const [addLinkModal, setAddLinkModal] = useState(false);
  const [addFileModal, setAddFileModal] = useState(false);

  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState(pathname);
  useEffect(() => {
    setActiveLink(pathname.split("/")[3]);
  }, [pathname, activeLink]);

  const { frondId } = useParams();
  let authToken = localStorage.getItem("token");

  const { setIsAddScrapbookImage, setIsAddScrapbookFile } =
    useContext(PopUpContext);
  const {
    openDeleteCheckbox,
    setOpenDeleteCheckbox,
    deleteProjects,
    setDeleteProjects,
  } = useContext(DeleteScrapbookImagesContext);

  const {
    openDeleteFilesCheckbox,
    setOpenDeleteFilesCheckbox,
    deleteFilesProjects,
    setDeleteFilesProjects,
  } = useContext(DeleteScrapbookFilesContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [isLoading2, setIsLoading2] = useState(false);
  const [isDisabled2, setIsDisabled2] = useState(false);

  async function deleteImage() {
    setIsLoading(true);
    setIsDisabled(true);
    for (let i = 0; i < deleteProjects.length; i++) {
      try {
        const { data } = await axios.delete(
          `https://frond-admin.code-minds.tech/api/scrapbook-images/${frondId}/${deleteProjects[i]}`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (i === deleteProjects.length - 1) {
          setIsAddScrapbookImage(true);
          setTimeout(() => {
            setIsAddScrapbookImage(false);
          }, 1000);
          toast("Images deleted successfully", {
            autoClose: 1500,
            position: "bottom-right",
            hideProgressBar: true,
            className: "rounded-4xl custom-toast success-toast",
          });
          setDeleteProjects([]);
          setOpenDeleteCheckbox(false);
        }
      } catch (error) {
        toast(error?.response?.data?.message, {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
      }
    }
    setIsDisabled(false);
    setIsLoading(false);
  }

  async function deleteFiles() {
    setIsLoading2(true);
    setIsDisabled2(true);
    for (let i = 0; i < deleteFilesProjects.length; i++) {
      try {
        const { data } = await axios.delete(
          `https://frond-admin.code-minds.tech/api/scrapbook-files/${frondId}/${deleteFilesProjects[i]}`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (i === deleteFilesProjects.length - 1) {
          setIsAddScrapbookFile(true);
          setTimeout(() => {
            setIsAddScrapbookFile(false);
          }, 1000);
          toast("Files deleted successfully", {
            autoClose: 1500,
            position: "bottom-right",
            hideProgressBar: true,
            className: "rounded-4xl custom-toast success-toast",
          });
          setDeleteFilesProjects([]);
          setOpenDeleteFilesCheckbox(false);
        }
      } catch (error) {
        toast(error?.response?.data?.message, {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
      }
    }
    setIsDisabled2(false);
    setIsLoading2(false);
  }

  useEffect(() => {}, [isImage]);

  const { loadSettings } = useSettings({ projectId: frondId });
  const { frondSettings } = useContext(SettingsContext);

  const [showImgBtn, setShowImgBtn] = useState(false);
  const [showFileBtn, setShowFileBtn] = useState(false);
  const [showNoteBtn, setShowNoteBtn] = useState(false);
  const [showVoiceBtn, setShowVoiceBtn] = useState(false);
  const [showLinkBtn, setShowLinkBtn] = useState(false);

  useEffect(() => {
    if (!loadSettings) {
      if (frondSettings.can_edit_photos && !frondSettings.is_owner) {
        setShowImgBtn(true);
      } else if (frondSettings.is_owner) {
        setShowImgBtn(true);
      } else {
        setShowImgBtn(false);
      }
    }
  }, [loadSettings, frondSettings]);

  useEffect(() => {
    if (!loadSettings) {
      if (frondSettings.can_edit_files && !frondSettings.is_owner) {
        setShowFileBtn(true);
      } else if (frondSettings.is_owner) {
        setShowFileBtn(true);
      } else {
        setShowFileBtn(false);
      }
    }
  }, [loadSettings, frondSettings]);

  useEffect(() => {
    if (!loadSettings) {
      if (frondSettings.can_edit_notes && !frondSettings.is_owner) {
        setShowNoteBtn(true);
      } else if (frondSettings.is_owner) {
        setShowNoteBtn(true);
      } else {
        setShowNoteBtn(false);
      }
    }
  }, [loadSettings, frondSettings]);

  useEffect(() => {
    if (!loadSettings) {
      if (frondSettings.can_edit_voice && !frondSettings.is_owner) {
        setShowVoiceBtn(true);
      } else if (frondSettings.is_owner) {
        setShowVoiceBtn(true);
      } else {
        setShowVoiceBtn(false);
      }
    }
  }, [loadSettings, frondSettings]);

  useEffect(() => {
    if (!loadSettings) {
      if (frondSettings.can_edit_links && !frondSettings.is_owner) {
        setShowLinkBtn(true);
      } else if (frondSettings.is_owner) {
        setShowLinkBtn(true);
      } else {
        setShowLinkBtn(false);
      }
    }
  }, [loadSettings, frondSettings]);

  return (
    <>
      {!loadSettings && (
        <div className="flex items-center phone:justify-start justify-center phone:w-auto w-full gap-2.5">
          {actions &&
            actions.map((item, idx) =>
              item === "Delete" ? (
                activeLink === "images" ? (
                  showImgBtn ? (
                    <button
                      disabled={isDisabled}
                      onClick={() => {
                        if (openDeleteCheckbox && deleteProjects.length > 0) {
                          deleteImage();
                        } else {
                          setOpenDeleteCheckbox(!openDeleteCheckbox);
                        }
                      }}
                      key={idx}
                      className={`flex items-center justify-center gap-2.5 py-[6px] min-w-[95px] min-h-[36px] phone:px-2.5 px-[8px] rounded-4xl phone:text-base text-[12px] font-normal leading-[18.4px]
                 ${
                   openDeleteCheckbox
                     ? "bg-darkBlue text-white"
                     : "bg-clip-text text-transparent bg-mainGradiant"
                 }`}
                    >
                      {isLoading ? (
                        <span className="flex justify-center items-center text-white">
                          <i className="fa-solid fa-spinner fa-spin"></i>
                        </span>
                      ) : (
                        <>
                          {!openDeleteCheckbox && (
                            <img
                              src={deleteIcon}
                              alt="Delete Image"
                              className="phone:w-[18px] phone:h-[18px] w-[12px] h-[12px]"
                            />
                          )}
                          <span>{item}</span>
                        </>
                      )}
                    </button>
                  ) : null
                ) : showFileBtn ? (
                  <button
                    disabled={isDisabled2}
                    onClick={() => {
                      if (
                        openDeleteFilesCheckbox &&
                        deleteFilesProjects.length > 0
                      ) {
                        deleteFiles();
                      } else {
                        setOpenDeleteFilesCheckbox(!openDeleteFilesCheckbox);
                      }
                    }}
                    key={idx}
                    className={`flex items-center justify-center gap-2.5 py-[6px] min-w-[95px] min-h-[36px] phone:px-2.5 px-[8px] rounded-4xl phone:text-base text-[12px] font-normal leading-[18.4px]
                 ${
                   openDeleteFilesCheckbox
                     ? "bg-darkBlue text-white"
                     : "bg-clip-text text-transparent bg-mainGradiant"
                 }`}
                  >
                    {isLoading2 ? (
                      <span className="flex justify-center items-center text-white">
                        <i className="fa-solid fa-spinner fa-spin"></i>
                      </span>
                    ) : (
                      <>
                        {!openDeleteFilesCheckbox && (
                          <img
                            src={deleteIcon}
                            alt="Delete Image"
                            className="phone:w-[18px] phone:h-[18px] w-[12px] h-[12px]"
                          />
                        )}
                        <span>{item}</span>
                      </>
                    )}
                  </button>
                ) : null
              ) : (
                <>
                  {item === "Add Image" && showImgBtn ? (
                    <button
                      onClick={() => {
                        if (item === "Add Image") {
                          setEditScrapbookImgModal(true);
                        }
                      }}
                      key={idx}
                      className={`flex items-center gap-2.5 py-[6px] phone:px-2.5 px-[8px] rounded-4xl phone:text-base text-[12px] font-normal leading-[18.4px] ${
                        item === "Add Image" ||
                        item === "Add File" ||
                        item === "Add Note" ||
                        item === "Add Voice Memo" ||
                        item === "Add Link"
                          ? "bg-mainGradiant text-white shadow-editImgShadow"
                          : "bg-clip-text text-transparent bg-mainGradiant"
                      } `}
                    >
                      <img
                        src={addIcon}
                        alt={item}
                        className="phone:w-[18px] phone:h-[18px] w-[12px] h-[12px]"
                      />
                      <span>{item}</span>
                    </button>
                  ) : null}

                  {item === "Add File" && showFileBtn ? (
                    <button
                      onClick={() => {
                        if (item === "Add File") {
                          setAddFileModal(true);
                        }
                      }}
                      key={idx}
                      className={`flex items-center gap-2.5 py-[6px] phone:px-2.5 px-[8px] rounded-4xl phone:text-base text-[12px] font-normal leading-[18.4px] ${
                        item === "Add Image" ||
                        item === "Add File" ||
                        item === "Add Note" ||
                        item === "Add Voice Memo" ||
                        item === "Add Link"
                          ? "bg-mainGradiant text-white shadow-editImgShadow"
                          : "bg-clip-text text-transparent bg-mainGradiant"
                      } `}
                    >
                      <img
                        src={addIcon}
                        alt={item}
                        className="phone:w-[18px] phone:h-[18px] w-[12px] h-[12px]"
                      />
                      <span>{item}</span>
                    </button>
                  ) : null}

                  {item === "Add Note" && showNoteBtn ? (
                    <button
                      onClick={() => {
                        if (item === "Add Note") {
                          setAddNoteModal(true);
                        }
                      }}
                      key={idx}
                      className={`flex items-center gap-2.5 py-[6px] phone:px-2.5 px-[8px] rounded-4xl phone:text-base text-[12px] font-normal leading-[18.4px] ${
                        item === "Add Image" ||
                        item === "Add File" ||
                        item === "Add Note" ||
                        item === "Add Voice Memo" ||
                        item === "Add Link"
                          ? "bg-mainGradiant text-white shadow-editImgShadow"
                          : "bg-clip-text text-transparent bg-mainGradiant"
                      } `}
                    >
                      <img
                        src={addIcon}
                        alt={item}
                        className="phone:w-[18px] phone:h-[18px] w-[12px] h-[12px]"
                      />
                      <span>{item}</span>
                    </button>
                  ) : null}

                  {item === "Add Voice Memo" && showVoiceBtn ? (
                    <button
                      onClick={() => {
                        if (item === "Add Voice Memo") {
                          setAddVoiceModal(true);
                        }
                      }}
                      key={idx}
                      className={`flex items-center gap-2.5 py-[6px] phone:px-2.5 px-[8px] rounded-4xl phone:text-base text-[12px] font-normal leading-[18.4px] ${
                        item === "Add Image" ||
                        item === "Add File" ||
                        item === "Add Note" ||
                        item === "Add Voice Memo" ||
                        item === "Add Link"
                          ? "bg-mainGradiant text-white shadow-editImgShadow"
                          : "bg-clip-text text-transparent bg-mainGradiant"
                      } `}
                    >
                      <img
                        src={addIcon}
                        alt={item}
                        className="phone:w-[18px] phone:h-[18px] w-[12px] h-[12px]"
                      />
                      <span>{item}</span>
                    </button>
                  ) : null}

                  {item === "Add Link" && showLinkBtn ? (
                    <button
                      onClick={() => {
                        if (item === "Add Link") {
                          setAddLinkModal(true);
                        }
                      }}
                      key={idx}
                      className={`flex items-center gap-2.5 py-[6px] phone:px-2.5 px-[8px] rounded-4xl phone:text-base text-[12px] font-normal leading-[18.4px] ${
                        item === "Add Image" ||
                        item === "Add File" ||
                        item === "Add Note" ||
                        item === "Add Voice Memo" ||
                        item === "Add Link"
                          ? "bg-mainGradiant text-white shadow-editImgShadow"
                          : "bg-clip-text text-transparent bg-mainGradiant"
                      } `}
                    >
                      <img
                        src={addIcon}
                        alt={item}
                        className="phone:w-[18px] phone:h-[18px] w-[12px] h-[12px]"
                      />
                      <span>{item}</span>
                    </button>
                  ) : null}
                </>
              )
            )}
        </div>
      )}

      {editScrapbookImgModal && (
        <EditScrapbookImgModal
          isOpen={editScrapbookImgModal}
          onClose={() => {
            setEditScrapbookImgModal(false);
          }}
          isModalOpen={true}
        />
      )}

      {addNoteModal && (
        <AddNote
          isOpen={addNoteModal}
          onClose={() => {
            setAddNoteModal(false);
          }}
          frondId={frondId}
          isModalOpen={true}
        />
      )}

      {addVoiceModal && (
        <VoiceModal
          isOpen={addVoiceModal}
          onClose={() => {
            setAddVoiceModal(false);
          }}
          frondId={frondId}
        />
      )}

      {addLinkModal && (
        <AddLink
          isOpen={addLinkModal}
          onClose={() => {
            setAddLinkModal(false);
          }}
          frondId={frondId}
          isModalOpen={true}
        />
      )}

      {addFileModal && (
        <AddFile
          isOpen={addFileModal}
          onClose={() => {
            setAddFileModal(false);
          }}
          frondId={frondId}
          isModalOpen={true}
        />
      )}
    </>
  );
};

export default DetailsActions;
