import { Image } from "@nextui-org/react";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import leftArrow from "../../Assets/sidebar/arrows/left.svg";
import rightArrow from "../../Assets/sidebar/arrows/right.svg";
import { SidebarContext } from "Context/SidebarContext";
import { LoadingContext } from "Context/LoadingContext";

const Sidebar = () => {
  const icons = require.context("../../Assets/sidebar", true);
  const iconsList = icons.keys().map((image) => icons(image));
  const sidebarData = [
    {
      title: "My fronds",
      link: "/",
    },
    {
      title: "My Tasks",
      link: "/my-tasks",
    },
    {
      title: "Discover",
      link: "/discover",
    },
    {
      title: "Community",
      link: "/community",
    },
    {
      title: "Help & Support",
      link: "/help-support",
    },
    {
      title: "Contact Us",
      link: "/contact-us",
    },
  ];

  const { isSidebarLoaded, setIsidebarLoaded } = useContext(LoadingContext);

  useLayoutEffect(() => {
    if (!isSidebarLoaded) {
      setTimeout(() => {
        setIsidebarLoaded(true);
      }, 2000);
    }
  }, [isSidebarLoaded]);

  const { pathname } = useLocation();

  const formatPathname = (path) => {
    if (path === "/") return path;
    return path.replace(/\/+$/, "");
  };

  const formattedPathname = formatPathname(pathname);
  useEffect(() => {}, [pathname]);

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [showText, setShowText] = useState(false);

  const handleSidebarToggle = () => {
    setIsSideBarOpen(!isSideBarOpen);
    setIsOpen(!isOpen);
    if (isSideBarOpen) {
      setShowText(false);
    } else {
      setTimeout(() => {
        setShowText(true);
      }, 300);
    }
  };

  // useEffect(() => {
  //   const sidebarBox = document.querySelector(".sidebar");
  //   if (!isSideBarOpen) {
  //     sidebarBox.classList.remove("md:fixed");
  //     sidebarBox.classList.add("md:fixed");
  //     setTimeout(() => {
  //       sidebarBox.classList.remove("md:fixed");
  //       sidebarBox.classList.add("md:fixed");
  //     }, 300);
  //   } else {
  //     sidebarBox.classList.remove("md:fixed");
  //     sidebarBox.classList.add("md:fixed");
  //     setTimeout(() => {
  //       sidebarBox.classList.remove("md:fixed");
  //       sidebarBox.classList.add("md:fixed");
  //     }, 300);
  //   }
  // }, [isSideBarOpen]);

  const { isOpen, setIsOpen } = useContext(SidebarContext);

  function closeSidebar() {
    if (window.innerWidth <= 767) {
      setIsSideBarOpen(false);
      setIsOpen(false);
      setShowText(false);
    }
  }

  useLayoutEffect(() => {
    closeSidebar();

    const handleResize = () => {
      closeSidebar();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <aside
      className={`sidebar md:shadow-none shadow-goalsShadow bg-white md:px-[18px] px-[15px] md:py-[30px] py-2.5 md:min-h-[calc(100vh-104px)] min-h-[100vh] z-[9999] md:fixed fixed md:left-auto md:top-[89px] md:bottom-auto left-0 bottom-0 top-[69px] ${
        isSideBarOpen
          ? "md:min-w-[218px] md:max-w-[218px] min-w-[189px] max-w-[189px]"
          : "md:min-w-[84px] md:max-w-[84px] min-w-[54px] max-w-[54px]"
      } transition-all duration-400 md:border-2 md:border-solid md:border-grayBorder md:rounded-4xl flex flex-col md:gap-4 gap-2.5 no-focus ${
        !isSidebarLoaded ? "opacity-0" : "opacity-100"
      }`}
    >
      <button
        onClick={handleSidebarToggle}
        className={`transition-all min-w-[24px] min-h-[24px] duration-600 absolute top-[12px] md:right-[-12px] right-[15px] bg-mainGradiant rounded-[5px] flex justify-center items-center`}
        style={{
          opacity: isSideBarOpen ? "1" : "0",
          visibility: isSideBarOpen ? "visible" : "hidden",
        }}
      >
        <Image src={leftArrow} alt="<" className="w[14px] h-[14px]" />
      </button>
      <button
        onClick={handleSidebarToggle}
        className={`transition-all min-w-[24px] min-h-[24px] duration-600 md:absolute top-[12px] right-[-12px] bg-mainGradiant rounded-[5px] flex justify-center items-center`}
        style={{
          opacity: !isSideBarOpen ? "1" : "0",
          visibility: !isSideBarOpen ? "visible" : "hidden",
        }}
      >
        <Image src={rightArrow} alt=">" className="w[14px] h-[14px]" />
      </button>
      {sidebarData.map((item, idx) => (
        <div
          key={idx}
          className={`${idx === 1 || idx === 3 ? "mb-[10px]" : ""} ${
            idx === 0 && isSideBarOpen ? "md:mt-0 mt-2.5" : ""
          } flex items-center relative`}
        >
          <Link to={item.link} className="w-full tooltip-box">
            <div
              className={`flex gap-3 md:rounded-[10px] rounded-[5px] w-full items-center ${
                isSideBarOpen ? "px-3" : "md:px-2.5 px-[3px]"
              } md:py-2.5 py-[3px] ${
                idx === 1 || idx === 3
                  ? "relative after:absolute after:left-0 after:right-0 after:h-px after:bg-[#EDEAFF] after:bottom-[-15px]"
                  : ""
              } ${
                item.link === formattedPathname ||
                (item.link === "/" && formattedPathname === "/home") ||
                (item.link === "/discover" &&
                  formattedPathname.includes("suggestions"))
                  ? "bg-[#F5F6FF]"
                  : "bg-white"
              }`}
            >
              <Image
                src={iconsList[idx]}
                alt={item.title}
                className="md:min-w-[24px] md:min-h-[24px] md:max-w-[24px] md:max-h-[24px] min-w-[18px] min-h-[18px] max-w-[18px] max-h-[18px]"
              />
              <h5
                className={`text-textColor md:text-base text-xs whitespace-nowrap ${
                  item.link === formattedPathname ||
                  (item.link === "/" && formattedPathname === "/home") ||
                  (item.link === "/discover" &&
                    formattedPathname.includes("suggestions"))
                    ? "font-bold"
                    : "font-normal"
                } ${!showText ? "hidden" : ""}`}
              >
                {item.title}
              </h5>
            </div>
          </Link>
          {!isOpen && (
            <div className="absolute right-[-110px] top-[55%] translate-y-[-50%] z-50 w-[110px] flex justify-center items-center bg-white rounded-2xl p-[5px] border-1 border-solid border-cardGrayColor transition-all duration-300 opacity-0 tooltip">
              <span className="text-[13.5px] font-normal text-textColor">
                {item.title}
              </span>
            </div>
          )}
        </div>
      ))}
    </aside>
  );
};

export default Sidebar;
