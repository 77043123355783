import Echo from 'laravel-echo';
import Pusher from "pusher-js";

const EchoClient = window.Echo = new Echo({
  broadcaster: 'pusher',
  key: '3cc06550fa014f427847',
  cluster: 'eu',
  forceTLS: true
});


export default EchoClient;