import { useClerk, useUser } from "@clerk/clerk-react";
import { Image } from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import ProfileData from "./ProfileData";
import penIcon from "../../Assets/profile/pen.svg";
import lockIcon from "../../Assets/profile/lock.svg";
import changeImgIcon from "../../Assets/profile/change-photo.svg";
import EditProfileData from "./EditProfileData";
import EditProfilePassword from "./EditProfilePassword";
import { PopUpContext } from "Context/PopUpContext";

export default function ProfilePage() {
  const [userEmail, setUserEmail] = useState(null);
  const [userFullName, setUserFullName] = useState(null);
  const [userImg, setUserImg] = useState(null);
  const [userPhoneNumber, setUserPhoneNumber] = useState(null);
  const [userId, setUserId] = useState(null);

  const clerkUser = useUser();
  const { session } = useClerk();
  const isSignedIn = localStorage.getItem("token");

  const { isUpdateProfile } = useContext(PopUpContext);

  const getData = () => {
    if (session?.user && isSignedIn) {
      setUserImg(clerkUser.user.imageUrl);
      setUserId(clerkUser.user.id);
      setUserFullName(clerkUser.user.fullName);
      setUserEmail(clerkUser.user.primaryEmailAddress.emailAddress);
      if (clerkUser.user.primaryPhoneNumber?.phoneNumber) {
        setUserPhoneNumber(clerkUser.user.primaryPhoneNumber.phoneNumber);
      }
    }
  };

  useEffect(() => {
    getData();
    console.log(clerkUser.user);
  }, [clerkUser.user]);

  // Edit Data

  const [isEdit, setIsEdit] = useState(false);
  const [isEditPass, setIsEditPass] = useState(false);

  const [enableEditData, setEnableEditData] = useState(false);
  const [enableEditPass, setEnableEditPass] = useState(false);

  useEffect(() => {
    if (isUpdateProfile) {
      getData();
      setIsEdit(false);
      setIsEditPass(false);
      setEnableEditData(false);
    }
  }, [isUpdateProfile]);

  return (
    <section className="border-2 border-solid border-grayBorder no-focus rounded-4xl bg-white">
      <div className="flex items-center flex-col">
        <div className="bg-mainGradiant w-full min-h-[126px] max-h-[126px] rounded-t-4xl"></div>
        <div className="relative w-full top-[-55px] flex flex-col items-center">
          <div className="relative flex justify-center w-[240px]">
            <Image
              src={userImg}
              alt="Profile Pic"
              className="min-w-[110px] min-h-[110px] max-h-[110px] max-w-[110px] bg-cover rounded-full"
            />
            <button
              className={`flex items-center absolute smallPhone:right-[-30px] right-[20px] smallPhone:top-[80px] top-[100px] gap-[5px] transition-all duration-300 ${
                isEdit && !isEditPass ? "opacity-100 z-20" : "opacity-0 z-10"
              }`}
            >
              <Image
                src={changeImgIcon}
                className="w-[34px] h-[34px]"
                alt="Change Photo"
              />
              <span className="text-blueTimeline font-bold text-sm underline underline-offset-1">
                Change Photo
              </span>
            </button>
          </div>
          <h3 className="text-textColor font-bold text-base leading-[22px] mb-[30px] smallPhone:mt-5 mt-8">
            {userFullName}
          </h3>
          <div className="relative h-[45px] w-full flex items-center justify-center sm:mt-0 mt-5">
            <div
              className={`flex items-center justify-center gap-2.5 absolute transition-all duration-300 sm:flex-nowrap flex-wrap px-5 ${
                isEdit || isEditPass ? "opacity-0 z-10" : "opacity-100 z-20"
              }`}
            >
              <button
                onClick={() => {
                  setIsEdit(true);
                }}
                className="w-[200px] h-[44px] rounded-4xl bg-mainGradiant py-2.5 px-[15px] flex justify-center items-center gap-2.5"
              >
                <Image src={penIcon} className="w-6 h-6" alt="Edit Profile" />
                <h4 className="text-base font-normal leading-[18.4px] text-white">
                  Edit Profile
                </h4>
              </button>
              <button
                onClick={() => {
                  setIsEditPass(true);
                }}
                className="w-[200px] h-[44px] rounded-4xl gradiantBorder no-focus py-2.5 px-[15px] flex justify-center items-center gap-2.5"
              >
                <Image
                  src={lockIcon}
                  className="w-6 h-6"
                  alt="Change Passwrod"
                />
                <h4 className="text-base font-normal leading-[18.4px] text-transparent bg-mainGradiant bg-clip-text">
                  Change Password
                </h4>
              </button>
            </div>
            <div
              className={`flex items-center justify-center gap-2.5 absolute transition-all duration-300 sm:flex-nowrap flex-wrap px-5 ${
                isEdit && !isEditPass ? "opacity-100 z-20" : "opacity-0 z-10"
              }`}
            >
              <button
                onClick={() => {
                  setEnableEditData(true);
                  // setTimeout(() => {
                  //   setEnableEditData(false);
                  // }, 1000);
                }}
                className="w-[153px] h-[45px] rounded-4xl bg-mainGradiant py-2.5 px-[15px] flex justify-center items-center text-white text-lg font-bold"
              >
                Save
              </button>
              <button
                onClick={() => {
                  setIsEdit(false);
                }}
                className="w-[153px] h-[45px] rounded-4xl gradiantBorder no-focus py-2.5 px-[15px] flex justify-center items-center"
              >
                <span className="text-transparent bg-mainGradiant bg-clip-text text-lg font-bold">
                  Discard
                </span>
              </button>
            </div>
            <div
              className={`flex items-center justify-center gap-2.5 absolute transition-all duration-300 sm:flex-nowrap flex-wrap px-5 ${
                isEditPass && !isEdit ? "opacity-100 z-20" : "opacity-0 z-10"
              }`}
            >
              <button
                onClick={() => {
                  setEnableEditPass(true);
                }}
                className="w-[153px] h-[45px] rounded-4xl bg-mainGradiant py-2.5 px-[15px] flex justify-center items-center text-white text-lg font-bold"
              >
                Save
              </button>
              <button
                onClick={() => {
                  setIsEditPass(false);
                  setTimeout(() => {
                    setEnableEditPass("reset");
                  }, 500);
                  setTimeout(() => {
                    setEnableEditPass(false);
                  }, 1000);
                }}
                className="w-[153px] h-[45px] rounded-4xl gradiantBorder no-focus py-2.5 px-[15px] flex justify-center items-center"
              >
                <span className="text-transparent bg-mainGradiant bg-clip-text text-lg font-bold">
                  Discard
                </span>
              </button>
            </div>
          </div>
          <div className="relative sm:h-[300px] h-[480px] w-full flex justify-center">
            <div
              className={`profile-data mt-6 absolute transition-all duration-300 ${
                isEdit || isEditPass ? "opacity-0 z-10" : "opacity-100 z-20"
              }`}
            >
              <ProfileData
                userEmail={userEmail}
                userPhone={"+2012 345 67890"}
              />
            </div>
            <div
              className={`edit-profile-data mt-6 absolute xl:w-[50%] sm:w-[75%] w-[95%] transition-all duration-300 ${
                isEdit && !isEditPass ? "opacity-100 z-20" : "opacity-0 z-10"
              }`}
            >
              <EditProfileData
                userId={userId}
                userName={userFullName}
                userEmail={userEmail}
                userPhone={"1234567890"}
                userCountryCode={"+20"}
                triggerForm={enableEditData}
              />
            </div>
            <div
              className={`edit-profile-data mt-6 absolute xl:w-[50%] sm:w-[75%] w-[95%] transition-all duration-300 ${
                isEditPass && !isEdit ? "opacity-100 z-20" : "opacity-0 z-10"
              }`}
            >
              <EditProfilePassword triggerForm={enableEditPass} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
