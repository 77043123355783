import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "Context/AuthContext";
import OTPInput from "react-otp-input";
import { useClerk, useSignIn, useSignUp, useUser } from "@clerk/clerk-react";
import axios from "axios";
import { Error500Context } from "Context/Error500Context";
import { toast } from "react-toastify";
import ProfileNavbar from "components/ProfileNavbar/ProfileNavbar";

const ProfileOtp = () => {
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [timer, setTimer] = useState(0);
  const navigate = useNavigate();
  const { newEmail, setIsMailAdded } = useContext(AuthContext);

  useEffect(() => {
    otp.length === 6 ? setIsDisabled(false) : setIsDisabled(true);
  }, [otp]);

  const { setIsServerError } = useContext(Error500Context);
  const [isResentOtp, setIsResentOtp] = useState(false);

  async function callVerify() {
    setErrorMessage("");
    setIsLoading(true);
    setIsDisabled(true);

    if (!otp.match(/^[0-9]{6}$/)) {
      setErrorMessage("OTP must be 6 digits");
      setIsLoading(false);
      setIsDisabled(false);
      return;
    }

    try {
      const emailAddresses = user.emailAddresses;
      const emailToVerify = emailAddresses.find(
        (email) => email.emailAddress === newEmail
      );

      if (emailToVerify) {
        const emailVerifyAttempt = await emailToVerify?.attemptVerification({
          code: otp,
        });

        if (emailVerifyAttempt?.verification?.status === "verified") {
          // Update the primary email
          await user.update({ primaryEmailAddressId: emailToVerify.id });

          // Remove all other email addresses
          const otherEmails = emailAddresses.filter(
            (email) => email.emailAddress !== newEmail
          );
          for (const email of otherEmails) {
            await email.destroy();
          }
          setIsMailAdded("unknown");

          toast("Email updated successfully", {
            autoClose: 1500,
            position: "bottom-right",
            hideProgressBar: true,
            className: "rounded-4xl custom-toast success-toast",
          });
          navigate("/profile");
        }
      } else {
        setErrorMessage("Email not found for verification.");
      }
    } catch (err) {
      if (err?.response?.status === 500) {
        setIsServerError(true);
        navigate("/server-error");
      } else {
        setErrorMessage(err.errors ? err.errors[0].message : err.message);
      }
    } finally {
      setIsLoading(false);
      setIsDisabled(false);
    }
  }

  const { user } = useUser();

  async function resendRegisterOtp() {
    try {
      const emailToResend = user.emailAddresses.find(
        (email) => email.emailAddress === newEmail
      );

      if (emailToResend && emailToResend.verification?.status !== "verified") {
        await emailToResend.prepareVerification({ strategy: "email_code" });
        setIsResentOtp(true);
        startTimer();
      } else if (!emailToResend) {
        const createdEmail = await user.createEmailAddress({
          emailAddress: newEmail,
        });
        if (createdEmail.verification?.status !== "verified") {
          await createdEmail.prepareVerification({ strategy: "email_code" });
        }
        setIsResentOtp(true);
        startTimer();
      } else {
        setErrorMessage("Email is already verified.");
      }
    } catch (error) {
      const message =
        error?.errors?.[0]?.longMessage ||
        "An error occurred during OTP resend.";
      setErrorMessage(message);
    }
  }

  function startTimer() {
    setTimer(59);
  }

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    } else {
      setIsResentOtp(false);
    }
  }, [timer]);

  function getOtp(t) {
    setOtp(t);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ProfileNavbar />
      <section className="verify-password h-[calc(100vh-69px)] flex justify-center items-center relative overflow-x-hidden">
        <div className="container xl:px-44 sm:px-12 px-4 mx-auto relative z-10">
          <div className="box sm:max-w-[435px] max-w-[310px] mx-auto shadow-authShadow rounded-20px flex flex-col justify-center sm:p-10 p-5 relative z-20 bg-white">
            <h2 className="font-bold text-[20.72px] mb-1 text-center">
              Confirm OTP
            </h2>
            <p className="text-cardGrayColor text-base text-center mb-6">
              Check your e-mail {newEmail}
            </p>
            <div className="verify-password-form mb-8">
              {errorMessage && (
                <div className="text-white bg-cardColor py-3 mb-10 text-center rounded-15px text-sm">
                  <span className="bg-clip-text text-transparent bg-mainGradiant font-bold">
                    {errorMessage}
                  </span>
                </div>
              )}
              <div className="mb-8">
                <OTPInput
                  value={otp}
                  onChange={getOtp}
                  numInputs={6}
                  containerStyle={"justify-between"}
                  renderInput={(props, idx) => {
                    return (
                      <input
                        data-id={idx}
                        value={otp}
                        {...props}
                        className={`otp-input border-[0.86px] border-solid ${
                          props.value.length > 0
                            ? "border-[#5CCDB0] focusBorder"
                            : "border-cardColor normalBorder"
                        } rounded-[8.64px] sm:min-w-[46px] sm:h-[40px] min-w-[36px] h-[36px] bg-white text-[15.55px] font-normal text-[#2B2B2B]`}
                      />
                    );
                  }}
                />
              </div>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  callVerify();
                }}
                disabled={isDisabled}
                className="w-full bg-mainGradiant h-[44px] text-white text-[15.54px] font-bold rounded-[12.95px]"
              >
                {isLoading ? (
                  <span className="flex gap-2 justify-center items-center">
                    <i className="fa-solid fa-spinner fa-spin text-white"></i>
                  </span>
                ) : (
                  "Confirm OTP"
                )}
              </button>
            </div>
            <div className="sign-up text-center text-[13.81px]">
              {isResentOtp ? (
                <>
                  <span>
                    Resend OTP in{" "}
                    <span className="text-transparent bg-clip-text bg-mainGradiant">
                      {timer}
                    </span>{" "}
                    seconds
                  </span>
                </>
              ) : (
                <>
                  Didn’t receive code?
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      resendRegisterOtp();
                    }}
                    className="ms-1 bg-clip-text text-transparent bg-mainGradiant relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-px after:bg-mainGradiant"
                  >
                    Resend OTP
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileOtp;
