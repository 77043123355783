import { Image, Input } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import lockIcon from "../../Assets/profile/password.svg";
import eyeIcon from "../../Assets/auth/eye.svg";
import slashEyeIcon from "../../Assets/auth/eye-slash.svg";

export default function EditProfilePassword({ triggerForm }) {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function changePassword(data) {
    console.log(data);
  }

  useEffect(() => {
    if (triggerForm) {
      handleSubmit(changePassword)();
    } else {
      reset();
    }
  }, [triggerForm, handleSubmit]);

  useEffect(() => {
    if (triggerForm === "reset") {
      reset();
    }
  }, [triggerForm]);

  return (
    <form
      className="sm:flex-nowrap flex-wrap px-5 justify-center sm:mt-0 mt-5"
      onSubmit={handleSubmit(changePassword)}
    >
      <div
        className={`flex flex-col gap-2 w-full ${
          errors.oldPassword?.message ? "mb-[30px]" : "mb-5"
        }`}
      >
        <label htmlFor="oldPassField">
          <span className="text-cardGrayColor text-sm">Old Password</span>
        </label>
        <Controller
          name="oldPassword"
          control={control}
          rules={{
            required: "Old password required",
            minLength: {
              value: 3,
              message: "Minimum number of characters is 3",
            },
            maxLength: {
              value: 60,
              message: "Maximum number of characters is 60",
            },
            pattern: {
              value:
                /(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>\/?`~\-])/gi,
              message:
                "Password must contain small, capital, number and special character",
            },
          }}
          render={({ field }) => (
            <Input
              id="oldPassField"
              {...field}
              variant="bordered"
              placeholder="Enter old password"
              classNames={{
                base: `input-noMargin ${
                  errors.oldPassword?.message ? "h-[39px]" : "h-[39px]"
                }`,
                label: "text-textColor font-normal phone:text-sm text-[10px]",
                input: "border-none px-[18px] mx-2 phone:text-base text-[10px]",
                mainWrapper: `${
                  errors.oldPassword?.message ? "h-[39px]" : "h-[39px]"
                }`,
                innerWrapper: "h-[39px]",
                inputWrapper: `border-[0.86px] py-0 px-[17px] border-solid ${
                  errors.oldPassword?.message
                    ? "invalid-text"
                    : "border-[#E5E4EB]"
                } rounded-[8px] h-[39px]`,
              }}
              isInvalid={errors.oldPassword?.message ? true : false}
              errorMessage={errors.oldPassword?.message}
              startContent={
                <Image src={lockIcon} className="min-w-[18px] min-h-[18px]" />
              }
              endContent={
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={() => {
                    setShowOldPass(!showOldPass);
                  }}
                >
                  {showOldPass ? (
                    <Image
                      className="min-w-[18px] min-h-[18px]"
                      src={slashEyeIcon}
                    />
                  ) : (
                    <Image
                      className="min-w-[18px] min-h-[18px]"
                      src={eyeIcon}
                    />
                  )}
                </button>
              }
              type={showOldPass ? "text" : "password"}
            />
          )}
        />
      </div>
      <div
        className={`flex flex-col gap-2 w-full ${
          errors.newPassword?.message ? "mb-[30px]" : "mb-5"
        }`}
      >
        <label htmlFor="newPassField">
          <span className="text-cardGrayColor text-sm">New Password</span>
        </label>
        <Controller
          name="newPassword"
          control={control}
          rules={{
            required: "New password required",
            minLength: {
              value: 3,
              message: "Minimum number of characters is 3",
            },
            maxLength: {
              value: 60,
              message: "Maximum number of characters is 60",
            },
            pattern: {
              value:
                /(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>\/?`~\-])/gi,
              message:
                "Password must contain small, capital, number and special character",
            },
          }}
          render={({ field }) => (
            <Input
              id="newPassField"
              {...field}
              variant="bordered"
              placeholder="Enter new password"
              classNames={{
                base: `input-noMargin ${
                  errors.newPassword?.message ? "h-[39px]" : "h-[39px]"
                }`,
                label: "text-textColor font-normal phone:text-sm text-[10px]",
                input: "border-none px-[18px] mx-2 phone:text-base text-[10px]",
                mainWrapper: `${
                  errors.newPassword?.message ? "h-[39px]" : "h-[39px]"
                }`,
                innerWrapper: "h-[39px]",
                inputWrapper: `border-[0.86px] py-0 px-[17px] border-solid ${
                  errors.newPassword?.message
                    ? "invalid-text"
                    : "border-[#E5E4EB]"
                } rounded-[8px] h-[39px]`,
              }}
              isInvalid={errors.newPassword?.message ? true : false}
              errorMessage={errors.newPassword?.message}
              startContent={
                <Image src={lockIcon} className="min-w-[18px] min-h-[18px]" />
              }
              endContent={
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={() => {
                    setShowNewPass(!showNewPass);
                  }}
                >
                  {showNewPass ? (
                    <Image
                      className="min-w-[18px] min-h-[18px]"
                      src={slashEyeIcon}
                    />
                  ) : (
                    <Image
                      className="min-w-[18px] min-h-[18px]"
                      src={eyeIcon}
                    />
                  )}
                </button>
              }
              type={showNewPass ? "text" : "password"}
            />
          )}
        />
      </div>
      <div
        className={`flex flex-col gap-2 w-full ${
          errors.confirmPassword?.message ? "mb-[30px]" : "mb-5"
        }`}
      >
        <label htmlFor="confirmPassField">
          <span className="text-cardGrayColor text-sm">Confirm Password</span>
        </label>
        <Controller
          name="confirmPassword"
          control={control}
          rules={{
            required: "Confirm password required",
            validate: (value) =>
              value === watch("newPassword") || "Passwords do not match",
          }}
          render={({ field }) => (
            <Input
              id="confirmPassField"
              {...field}
              variant="bordered"
              placeholder="Retype your password"
              classNames={{
                base: `input-noMargin ${
                  errors.confirmPassword?.message ? "h-[39px]" : "h-[39px]"
                }`,
                label: "text-textColor font-normal phone:text-sm text-[10px]",
                input: "border-none px-[18px] mx-2 phone:text-base text-[10px]",
                mainWrapper: `${
                  errors.confirmPassword?.message ? "h-[39px]" : "h-[39px]"
                }`,
                innerWrapper: "h-[39px]",
                inputWrapper: `border-[0.86px] py-0 px-[17px] border-solid ${
                  errors.confirmPassword?.message
                    ? "invalid-text"
                    : "border-[#E5E4EB]"
                } rounded-[8px] h-[39px]`,
              }}
              isInvalid={errors.confirmPassword?.message ? true : false}
              errorMessage={errors.confirmPassword?.message}
              startContent={
                <Image src={lockIcon} className="min-w-[18px] min-h-[18px]" />
              }
              endContent={
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={() => {
                    setShowConfirmPass(!showConfirmPass);
                  }}
                >
                  {showConfirmPass ? (
                    <Image
                      className="min-w-[18px] min-h-[18px]"
                      src={slashEyeIcon}
                    />
                  ) : (
                    <Image
                      className="min-w-[18px] min-h-[18px]"
                      src={eyeIcon}
                    />
                  )}
                </button>
              }
              type={showConfirmPass ? "text" : "password"}
            />
          )}
        />
      </div>
    </form>
  );
}
