import React, { useEffect, useRef, useState } from "react";
import useTypewriter from "hooks/useTypewriter";

export default function DiscoverModal({ isOpen, onClose, discoverData }) {
  const [isLoading, setIsLoading] = useState(true);
  const bottomRef = useRef(null);

  const formattedResponseText =
    discoverData && discoverData.plan
      ? Object.entries(discoverData.plan)
          .map(([key, value]) => `${key}:\n${value || "No details provided"}`)
          .join("\n\n")
      : "";

  const loadingMessage = useTypewriter("Generating your response...", 100);
  const responseText = useTypewriter(formattedResponseText, 50, true);

  useEffect(() => {
    if (discoverData?.plan) {
      setIsLoading(false);
    }
  }, [discoverData]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [responseText]);

  return (
    <div className="response-content">
      {!isLoading ? (
        <pre className="response-message text-lg font-semibold whitespace-pre-wrap">
          {responseText}
        </pre>
      ) : (
        <p className="loading-message text-lg font-semibold">
          {loadingMessage}
        </p>
      )}
      <div ref={bottomRef} />
    </div>
  );
}
