import { Input } from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import { useFormik } from "formik";
import { AuthContext } from "Context/AuthContext";
import passIcon from "../../Assets/auth/lock.svg";
import visibleEye from "../../Assets/auth/eye.svg";
import hiddenEye from "../../Assets/auth/eye-slash.svg";
import { useSignIn, useUser } from "@clerk/clerk-react";
import { Error500Context } from "Context/Error500Context";

const ResetPassword = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const [isVisible2, setIsVisible2] = useState(false);

  const toggleVisibility2 = () => setIsVisible2(!isVisible2);

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    password: Yup.string()
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>\/?`~\-])/,
        "Password must contain small, capital, number and special character"
      )
      .min(8, "Weak password")
      .max(30, "Password is too long")
      .required("Password required"),
    confirm_password: Yup.string()
      .oneOf(
        [Yup.ref("password")],
        "Password and confirm password should match"
      )
      .required("Confirm password required"),
  });

  const forgetForm = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema,
    onSubmit: callForget,
  });

  const {
    setIsVerified,
    setIsForgetPassword,
    forgetOtp,
    forgetEmail,
    setToken,
  } = useContext(AuthContext);
  const { signIn, setActive } = useSignIn();
  const { user } = useUser();

  const { setIsServerError } = useContext(Error500Context);

  async function callForget(reqBody) {
    setErrorMessage("");
    setIsLoading(true);
    setIsDisabled(true);
    try {
      const data = await signIn?.attemptFirstFactor({
        strategy: "reset_password_email_code",
        code: forgetOtp,
        password: reqBody.password,
      });
      if (data.status === "complete") {
        setIsVerified(true);
        setIsForgetPassword(false);
        navigate("/loading");
        await setActive({ session: data.createdSessionId });

        await axios
          .post("/user", {
            name: data.userData.firstName + " " + data.userData.lastName,
            email: forgetEmail,
            password: forgetEmail,
            img_url: user.imageUrl,
          })
          .then((response) => {
            let authToken = localStorage.setItem(
              "token",
              response.data.response.data.token
            );
            setToken(response.data.response.data.token);

            axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
            setIsLoading(false);
          })
          .catch((err) => {
            if (err?.response?.status === 500) {
              setIsServerError(true);
              navigate("/server-error");
            } else {
              setErrorMessage(err.errors ? err.errors[0].message : err.message);
            }
          });
      }
    } catch (error) {
      setErrorMessage(error.errors[0].longMessage || "An error occurred");
      if (error?.errors[0]?.longMessage === "Incorrect code") {
        setTimeout(() => {
          forgetForm.handleReset();
          setIsVerified(false);
          navigate("/verify-password");
        }, 2000);
      }
      setIsLoading(false);
    }
    setIsDisabled(false);
  }

  return (
    <section className="reset-password h-[calc(100vh-85px)] flex justify-center items-center relative overflow-x-hidden">
      <div className="absolute bottom-0 left-0 w-[450px] h-[412px] bg-authImg1 bg-cover bg-no-repeat z-0"></div>
      <div className="absolute top-[30px] right-0 w-[214px] h-[287px] bg-authImg2 bg-cover bg-no-repeat z-0"></div>
      <div className="container xl:px-44 sm:px-6 px-4 mx-auto relative z-10">
        <div className="box sm:mx-auto sm:w-[395px] w-auto shadow-authShadow rounded-20px flex flex-col justify-center sm:p-10 p-5 relative z-20 bg-white">
          <h2 className="font-bold text-[20.72px] mb-6 text-center">
            Reset Password?
          </h2>
          <div className="forget-password-form mb-8">
            {errorMessage && (
              <div className="text-white bg-cardColor py-3 mb-10 text-center rounded-15px text-sm">
                <span className="bg-clip-text text-transparent bg-mainGradiant font-bold">
                  {errorMessage}
                </span>
              </div>
            )}
            <form onSubmit={forgetForm.handleSubmit}>
              <div className="mb-8">
                <Input
                  name="password"
                  label="Password"
                  variant="bordered"
                  labelPlacement="outside"
                  placeholder="Enter your password"
                  startContent={<img src={passIcon} />}
                  endContent={
                    <button
                      className="focus:outline-none"
                      type="button"
                      onClick={toggleVisibility}
                    >
                      {isVisible ? (
                        <img src={hiddenEye} />
                      ) : (
                        <img src={visibleEye} />
                      )}
                    </button>
                  }
                  type={isVisible ? "text" : "password"}
                  classNames={{
                    label: "text-[13.81px] font-normal opacity-75 mt-1",
                    input: "border-none py-[10.36px]",
                  }}
                  onChange={forgetForm.handleChange}
                  onBlur={forgetForm.handleBlur}
                  value={forgetForm.values.password}
                  isInvalid={forgetForm.errors.password}
                  errorMessage={
                    forgetForm.errors.password && forgetForm.touched.password
                      ? forgetForm.errors.password
                      : null
                  }
                />
              </div>

              <div className="mb-8">
                <Input
                  name="confirm_password"
                  label="Confirm Password"
                  variant="bordered"
                  labelPlacement="outside"
                  placeholder="Retype your password"
                  startContent={<img src={passIcon} />}
                  endContent={
                    <button
                      className="focus:outline-none"
                      type="button"
                      onClick={toggleVisibility2}
                    >
                      {isVisible2 ? (
                        <img src={hiddenEye} />
                      ) : (
                        <img src={visibleEye} />
                      )}
                    </button>
                  }
                  type={isVisible2 ? "text" : "password"}
                  classNames={{
                    label: "text-[13.81px] font-normal opacity-75 mt-1",
                    input: "border-none py-[10.36px]",
                  }}
                  onChange={forgetForm.handleChange}
                  onBlur={forgetForm.handleBlur}
                  value={forgetForm.values.confirm_password}
                  isInvalid={forgetForm.errors.confirm_password}
                  errorMessage={
                    forgetForm.errors.confirm_password &&
                    forgetForm.touched.confirm_password
                      ? forgetForm.errors.confirm_password
                      : null
                  }
                />
              </div>
              <button
                type="submit"
                disabled={
                  !(forgetForm.isValid && forgetForm.dirty) || isDisabled
                }
                className="w-full bg-mainGradiant h-[44px] text-white text-[15.54px] font-bold rounded-[12.95px]"
              >
                {isLoading ? (
                  <span className="flex gap-2 justify-center items-center">
                    <i className="fa-solid fa-spinner fa-spin text-white"></i>
                  </span>
                ) : (
                  "Reset password"
                )}
              </button>
            </form>
          </div>
          <div className="sign-up text-center text-[13.81px]">
            Don’t have an account?
            <Link
              to={"/signup"}
              className="ms-1 bg-clip-text text-transparent bg-mainGradiant relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-px after:bg-mainGradiant"
            >
              Sign up
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
