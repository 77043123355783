import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import WaveSurfer from "wavesurfer.js";
import playIcon from "../../../Assets/audio/play-icon.svg";
import pauseIcon from "../../../Assets/audio/pause-icon.svg";
import { Image } from "@nextui-org/react";
import AudioPlayer from "./Voice/components/AudioPlayer";
import useSize from "hooks/useSize";

export default function VoiceAudio({ audio }) {
  const waveformRef = useRef();
  const [wavesurfer, setWavesurfer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioFile, setAudioFile] = useState(null);
  const [duration, setDuration] = useState(0); // Total audio duration
  const [currentTime, setCurrentTime] = useState(0); // Current playback time

  // Function to format the time from seconds to mm:ss
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  useLayoutEffect(() => {
    const fetchAudio = async () => {
      try {
        const response = await fetch(audio);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const audioBlob = await response.blob();
        const audioURL = URL.createObjectURL(audioBlob); // Convert Blob to URL
        setAudioFile(audioURL);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching audio:", error);
        }
      }
    };

    fetchAudio();
  }, []);

  //   useEffect(() => {
  //     if (waveformRef.current && audioFile) {
  //       const wavesurferInstance = WaveSurfer.create({
  //         container: waveformRef.current,
  //         waveColor: "#fff", // Customize to match the design
  //         progressColor: "#2B2B2B", // Customize progress bar color
  //         cursorColor: "#000", // Cursor color
  //         height: 44, // Height of the waveform
  //         responsive: true,
  //         barWidth: 2, // Width of each wave bar
  //       });

  //       wavesurferInstance.load(audioFile); // Load the audio file URL

  //       wavesurferInstance.on("ready", () => {
  //         const durationInSeconds = wavesurferInstance.getDuration();
  //         setDuration(durationInSeconds); // Set the total duration when the audio is ready
  //       });

  //       wavesurferInstance.on("audioprocess", () => {
  //         setCurrentTime(wavesurferInstance.getCurrentTime()); // Update current playback time
  //       });

  //       setWavesurfer(wavesurferInstance);

  //       return () => {
  //         wavesurferInstance.destroy(); // Destroy the WaveSurfer instance when the component unmounts
  //       };
  //     }
  //   }, [audioFile]);

  //   const handlePlayPause = () => {
  //     if (wavesurfer) {
  //       wavesurfer.playPause();
  //       setIsPlaying(!isPlaying);
  //     }
  //   };

  useEffect(() => {
    const icon = document.querySelector(
      ".wave > div > div > div:first-of-type img"
    );

    icon.src = isPlaying ? pauseIcon : playIcon;
  }, []);

  const { width } = useSize();

  if (width === 0) {
    return null;
  }

  return (
    // <div className="wave">
    //   <div ref={waveformRef}></div>
    //   <div className="audio-controls">
    //     <button onClick={handlePlayPause}>
    //       <Image
    //         src={isPlaying ? pauseIcon : playIcon}
    //         className="min-w-6 min-h-6"
    //         alt="Toggle Voice"
    //       />
    //     </button>
    //     {/* Display the formatted current time */}
    //     <span className="audio-time">
    //       {formatTime(currentTime)} / {formatTime(duration)}
    //     </span>
    //   </div>
    //
    // </div>
    <div className="wave">
      <AudioPlayer
        src={audio}
        minimal={true}
        width={width > 550 ? 400 : 165}
        trackHeight={width > 550 ? 44 : 32}
        barWidth={2}
        gap={1}
        visualise={true}
        backgroundColor="rgba(255,255,255, 0)"
        barColor="#fff"
        barPlayedColor="#2B2B2B"
        skipDuration={2}
        showLoopOption={true}
        showVolumeControl={true}
      />
    </div>
  );
}
