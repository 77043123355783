import React, { useContext, useEffect, useState } from "react";
import addIcon from "../../../Assets/filter/plus-squared.svg";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
} from "@nextui-org/react";
import linkImg from "../../../Assets/landing/feedbackCardImg.svg";
import { PopUpContext } from "Context/PopUpContext";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import dots from "../../../Assets/scrapbook/dots-vertical.svg";
import AddTask from "../Tasklist/AddTask";
import AddUpdate from "./AddUpdate";
import DeleteUpdate from "./DeleteUpdate";
import useSettings from "hooks/useSettings";
import { SettingsContext } from "Context/SettingsContext";
import DataLoader from "pages/DataLoader/DataLoader";

export default function UpdatesTemplate() {
  const [targetId, setTargetId] = useState(null);
  const [itemKey, setItemKey] = useState(null);
  const [editUpdateModal, setEditUpdateModal] = useState(false);
  const [addUpdateModal, setAddUpdateModal] = useState(false);
  const [deleteUpdateModal, setDeleteUpdateModal] = useState(false);
  const [frondData, setFrondData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { frondId } = useParams();
  const authToken = localStorage.getItem("token");

  const timeAgo = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffInSeconds = Math.floor((now - date) / 1000);

    const timeUnits = [
      { name: "year", seconds: 31536000 },
      { name: "month", seconds: 2592000 },
      { name: "day", seconds: 86400 },
      { name: "hour", seconds: 3600 },
      { name: "minute", seconds: 60 },
      { name: "second", seconds: 1 },
    ];

    for (const unit of timeUnits) {
      const interval = Math.floor(diffInSeconds / unit.seconds);
      if (interval >= 1) {
        return `${interval} ${unit.name}${interval > 1 ? "s" : ""} ago`;
      }
    }

    return "just now";
  };

  const getUpdates = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/projects/${frondId}/updates`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setFrondData(data || []);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getUpdates();
  }, [frondId]);

  const { isAddUpdate, isDeleteUpdate } = useContext(PopUpContext);

  useEffect(() => {
    if (isAddUpdate || isDeleteUpdate) {
      getUpdates();
    }
  }, [isAddUpdate, isDeleteUpdate]);

  useEffect(() => {
    if (itemKey === "delete") {
      setDeleteUpdateModal(true);
      setItemKey(null);
    } else if (itemKey === "edit") {
      setEditUpdateModal(true);
      setItemKey(null);
    }
  }, [itemKey]);

  const openModal = (key, id) => {
    setItemKey(key);
    setTargetId(id);
  };

  const handleDotsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const { loadSettings } = useSettings({ projectId: frondId });
  const { frondSettings } = useContext(SettingsContext);

  const [showUpdate, setShowUpdate] = useState(false);

  useEffect(() => {
    if (!loadSettings) {
      if (frondSettings.can_edit_updates && !frondSettings.is_owner) {
        setShowUpdate(true);
      } else if (frondSettings.is_owner) {
        setShowUpdate(true);
      } else {
        setShowUpdate(false);
      }
    }
  }, [loadSettings, frondSettings]);

  return (
    <>
      {loadSettings ? (
        <div className="min-h-[200px] flex justify-center items-center">
          <DataLoader />
        </div>
      ) : (
        <>
          <section className="updates">
            <div className="count-action flex items-center justify-between gap-2.5 mb-[15px]">
              <p className="text-textColor font-bold phone:text-lg text-[12px] leading-[20.7px]">
                {frondData?.length > 0 ? frondData?.length : 0} New Updates
              </p>
              {showUpdate ? (
                <button
                  onClick={() => {
                    setAddUpdateModal(true);
                  }}
                  type="button"
                  className="bg-mainGradiant rounded-4xl phone:w-[146px] phone:h-[38px] phone:py-2.5 py-[5px] phone:px-[15px] px-2 flex justify-center items-center gap-2.5"
                >
                  <Image
                    src={addIcon}
                    alt="Add Member"
                    className="phone:w-[18px] phone:h-[18px] w-[12px] h-[12px]"
                  />
                  <span className="leading-[18.4px] text-white phone:text-base text-[12px]">
                    Add Update
                  </span>
                </button>
              ) : null}
            </div>
            {frondData.length > 0 && (
              <div className="flex flex-col gap-[15px]">
                {frondData.map((item, idx) => (
                  <div
                    key={item?.update?.id}
                    className={`flex justify-between w-full gap-2.5 ${
                      idx !== frondData.length - 1
                        ? "pb-[15px] border-b-1 border-solid border-b-grayBorder"
                        : ""
                    }`}
                  >
                    <div className="flex items-start gap-2.5">
                      <Image
                        src={item?.assigned_to?.img_url}
                        className="phone:min-w-[45px] phone:min-h-[45px] max-w-[30px] max-h-[30px] rounded-full"
                        alt={item?.assigned_to?.name}
                      />
                      <div>
                        <h4 className="text-base text-textColor font-bold leading-[18.4px] mb-[3px]">
                          {item?.assigned_to?.name || "-"}
                        </h4>
                        <p className="text-sm text-cardGrayColor mb-2.5">
                          <span className="me-2.5">
                            {timeAgo(item?.update?.created_at) || "-"}
                          </span>
                        </p>
                        <p className="text-textColor leading-[22px] break-all">
                          {item?.update?.content || "-"}
                        </p>
                      </div>
                    </div>
                    {showUpdate ? (
                      <Dropdown
                        classNames={{
                          content:
                            "min-w-[88px] max-w-[88px] py-0 px-[5px] rounded-[10px]",
                        }}
                        placement="left-start"
                      >
                        <DropdownTrigger>
                          <button
                            className="w-[24px] h-[24px]"
                            onClick={handleDotsClick}
                          >
                            <img
                              src={dots}
                              alt="Dots"
                              className="min-w-[24px] min-h-[24px]"
                            />
                          </button>
                        </DropdownTrigger>
                        <DropdownMenu
                          aria-label="Frond Actions"
                          onAction={(key) => openModal(key, item?.update?.id)}
                        >
                          <DropdownItem
                            textValue="edit note"
                            key="edit"
                            className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                          >
                            Edit
                          </DropdownItem>
                          <DropdownItem
                            key="delete"
                            className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                            color="danger"
                          >
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    ) : null}
                  </div>
                ))}
              </div>
            )}
          </section>
        </>
      )}
      {editUpdateModal && targetId && (
        <AddUpdate
          isOpen={editUpdateModal}
          onClose={() => setEditUpdateModal(false)}
          frondId={frondId}
          isModalOpen={true}
          taskId={targetId}
        />
      )}
      {addUpdateModal && (
        <AddUpdate
          isOpen={addUpdateModal}
          onClose={() => setAddUpdateModal(false)}
          frondId={frondId}
          isModalOpen={true}
        />
      )}
      {deleteUpdateModal && targetId && (
        <DeleteUpdate
          isOpen={deleteUpdateModal}
          onClose={() => setDeleteUpdateModal(false)}
          updateId={targetId}
        />
      )}
    </>
  );
}
