import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { PopUpContext } from "Context/PopUpContext";
import { Gallery } from "react-grid-gallery";
import checkIcon from "../../../Assets/my-fronds/check.svg";
import { DeleteScrapbookImagesContext } from "Context/DeleteScrapbookImagesContext";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // Import the lightbox CSS
import useSettings from "hooks/useSettings";
import { SettingsContext } from "Context/SettingsContext";

const ScrapbookImages = () => {
  const { frondId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [frondData, setFrondData] = useState([]);
  let authToken = localStorage.getItem("token");

  useSettings({ projectId: frondId });
  const { frondSettings } = useContext(SettingsContext);

  const getScrapbookImages = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/scrapbook-images/${frondId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFrondData(data?.response?.data);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getScrapbookImages();
  }, []);

  const { isAddScrapbookImage, isAddScrapbookUrl } = useContext(PopUpContext);

  useEffect(() => {
    if (isAddScrapbookImage || isAddScrapbookUrl) {
      getScrapbookImages();
    }
  }, [isAddScrapbookImage, isAddScrapbookUrl]);

  const { openDeleteCheckbox, deleteProjects, setDeleteProjects } = useContext(
    DeleteScrapbookImagesContext
  );

  const toggleDeleteImage = (imageId) => {
    if (deleteProjects.includes(imageId)) {
      setDeleteProjects(deleteProjects.filter((id) => id !== imageId));
    } else {
      setDeleteProjects([...deleteProjects, imageId]);
    }
  };

  const images = [...(frondData?.files || []), ...(frondData?.url || [])].map(
    (image) => ({
      src: image.image,
      width: "auto",
      height: "100%",
      customOverlay: (
        <div
          onClick={() => {
            toggleDeleteImage(image.id);
          }}
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            cursor: openDeleteCheckbox ? "pointer" : "auto",
            opacity: openDeleteCheckbox ? "1" : "0",
            pointerEvents: openDeleteCheckbox ? "auto" : "none",
            transition: "opacity 0.3s ease",
            borderRadius: "5px",
          }}
        >
          <div
            className={`absolute right-[22px] top-[22px] w-[29px] h-[29px] rounded-[7px] flex justify-center items-center bg-white z-30
              ${openDeleteCheckbox ? "opacity-100" : "opacity-0"}`}
            style={{
              pointerEvents: "none",
            }}
          >
            <div className="w-[21px] h-[21px] border-cardGrayColor rounded-[7px] border-2 border-solid relative flex justify-center items-center">
              <div
                className={`${
                  deleteProjects.includes(image.id)
                    ? "opacity-100"
                    : "opacity-0"
                } flex justify-center absolute inset-[-2px] items-center rounded-[7px] bg-offWhite transition-opacity duration-300`}
              >
                <img
                  src={checkIcon}
                  alt="Check"
                  className="w-[12.61px] h-[8.41px]"
                />
              </div>
            </div>
          </div>
        </div>
      ),
    })
  );

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  return (
    <>
      <section className="scrapbook-images">
        {images.length > 0 ? (
          <Gallery
            onClick={(index) => {
              setIsOpen(true);
              setPhotoIndex(index);
            }}
            enableImageSelection={false}
            images={images}
            containerWidth={window.innerWidth * 0.9}
            thumbnailStyle={{
              cursor: "pointer",
              maxWidth: "none",
              height: "180px",
              marginLeft: "0",
              marginTop: "0",
              borderRadius: "5px",
            }}
          />
        ) : null}
      </section>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].src}
          nextSrc={images[(photoIndex + 1) % images.length].src}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </>
  );
};

export default ScrapbookImages;
