import {
  getKeyValue,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import axios from "axios";
import { DeleteScrapbookFilesContext } from "Context/DeleteScrapbookFilesContext";
import { PopUpContext } from "Context/PopUpContext";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import checkIcon from "../../../Assets/my-fronds/check.svg";
import useSettings from "hooks/useSettings";
import { SettingsContext } from "Context/SettingsContext";

export default function ScrapbookFiles() {
  const { frondId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [frondData, setFrondData] = useState([]);
  let authToken = localStorage.getItem("token");

  const getScrapbookFiles = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/scrapbook-files/${frondId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFrondData(data?.response?.data?.files);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getScrapbookFiles();
  }, []);

  const { isAddScrapbookFile } = useContext(PopUpContext);

  useEffect(() => {
    if (isAddScrapbookFile) {
      getScrapbookFiles();
    }
  }, [isAddScrapbookFile]);

  const {
    openDeleteFilesCheckbox,
    deleteFilesProjects,
    setDeleteFilesProjects,
  } = useContext(DeleteScrapbookFilesContext);

  const toggleDeleteFile = (fileId) => {
    if (deleteFilesProjects.includes(fileId)) {
      setDeleteFilesProjects(deleteFilesProjects.filter((id) => id !== fileId));
    } else {
      setDeleteFilesProjects([...deleteFilesProjects, fileId]);
    }
  };

  const timeAgo = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffInSeconds = Math.floor((now - date) / 1000);

    const timeUnits = [
      { name: "year", seconds: 31536000 },
      { name: "month", seconds: 2592000 },
      { name: "day", seconds: 86400 },
      { name: "hour", seconds: 3600 },
      { name: "minute", seconds: 60 },
      { name: "second", seconds: 1 },
    ];

    for (const unit of timeUnits) {
      const interval = Math.floor(diffInSeconds / unit.seconds);
      if (interval >= 1) {
        return `${interval} ${unit.name}${interval > 1 ? "s" : ""} ago`;
      }
    }

    return "just now";
  };

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop();
    link.click();
  };

  const getFileName = (filePath) => {
    const parts = filePath.split("/");
    const fileName = parts[parts.length - 1];
    return fileName.substring(0, fileName.lastIndexOf("."));
  };

  const getFileType = (filePath) => {
    const parts = filePath.split(".");
    return parts[parts.length - 1];
  };

  const columns = ["Name", "Type", "Created At", "Download"];

  useSettings({ projectId: frondId });
  const { frondSettings } = useContext(SettingsContext);

  return (
    <section className="scrapbook-files">
      {frondData?.length > 0 ? (
        <Table
          classNames={{
            th: "text-white font-bold text-sm bg-mainPink",
            td: "p-4 bg-cardColor",
          }}
          aria-label="Frond Files"
        >
          <TableHeader>
            {columns.map((column, idx) => (
              <TableColumn key={idx}>{column}</TableColumn>
            ))}
          </TableHeader>
          <TableBody>
            {frondData.map((item) => (
              <TableRow key={item?.id}>
                <TableCell className="flex items-center">
                  <button
                    type="button"
                    onClick={() => toggleDeleteFile(item.id)}
                    className={`rounded-[7px] self-start bg-transparent transition-all duration-300 overflow-hidden ${
                      openDeleteFilesCheckbox
                        ? "w-[29px] h-[29px] me-5"
                        : "w-0 h-0 me-0"
                    }`}
                  >
                    <div className="w-[21px] h-[21px] border-cardGrayColor rounded-[7px] border-2 border-solid relative flex justify-center items-center">
                      <div
                        className={`${
                          deleteFilesProjects.includes(item.id)
                            ? "opacity-100"
                            : "opacity-0"
                        } flex justify-center absolute inset-[-2px] items-center rounded-[7px] bg-offWhite transition-opacity duration-300`}
                      >
                        <img
                          src={checkIcon}
                          alt="Check"
                          className="w-[12.61px] h-[8.41px]"
                        />
                      </div>
                    </div>
                  </button>
                  <div className="overflow-x-auto max-w-[150px]">
                    <button
                      onClick={() => handleDownload(item?.image)}
                      className="text-darkBlue underline whitespace-nowrap"
                    >
                      {getFileName(item?.image) || "-"}
                    </button>
                  </div>
                </TableCell>
                <TableCell className="text-textColor">
                  <div className="overflow-x-auto max-w-[100px] whitespace-nowrap">
                    {getFileType(item?.image) || "-"}
                  </div>
                </TableCell>
                <TableCell className="text-textColor">
                  <div className="overflow-x-auto max-w-[150px] whitespace-nowrap">
                    {timeAgo(item?.created_at) || "-"}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="overflow-x-auto max-w-[100px] whitespace-nowrap">
                    {item?.image && (
                      <button
                        onClick={() => handleDownload(item?.image)}
                        className="text-darkBlue underline"
                      >
                        View
                      </button>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : null}
    </section>
  );
}
