import axios from 'axios';
import { SettingsContext } from 'Context/SettingsContext';
import { useContext, useEffect, useState } from 'react';

export default function useSettings({ projectId }) {
  const token = localStorage.getItem("token");
  const {  setFrondSettings, setIsOwner } = useContext(SettingsContext);
  const [loadSettings, setLoadSettings] = useState(true);

  const getSettings = async () => {
    try {
      const { data } = await axios.get(`https://frond-admin.code-minds.tech/api/settings/${projectId}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.response.data.is_owner === true) {
        setIsOwner(true);
        setFrondSettings(data.response.data);
      } else {
        setIsOwner(false);
        setFrondSettings(data.response.data);
      }
      setLoadSettings(false);
    } catch (error) {
      setIsOwner(false);
      setFrondSettings(false);
      setLoadSettings(false);
    }
  };

  useEffect(() => {
    if (projectId) {
      getSettings();
    } else {
      setLoadSettings(false);
    }
  }, [projectId]);

  return { loadSettings };
}
