import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import {
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Radio,
  RadioGroup,
  Textarea,
} from "@nextui-org/react";
import closeIcon from "../../Assets/popup/cross-square.svg";
import { Controller, useForm } from "react-hook-form";
import mapMarker from "../../Assets/create-frond/geolocation.svg";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import L from "leaflet";
import locationIcon from "../../Assets/create-frond/location.svg";
import { OpenStreetMapProvider } from "react-leaflet-geosearch";
import debounce from "lodash.debounce";
import tempMap from "../../Assets/create-frond/temp-map.png";
import { toast } from "react-toastify";
import axios from "axios";
import removeImg from "../../Assets/frond-details/removed-img.svg";
import placholderIcon from "../../Assets/frond-details/placholder-icon.svg";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";

// css
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";

// Import Doka. For testing purpose only, if you're intrested in using Doka
// in your project please purchase a license at https://pqina.nl/doka/
import "../../components/FrondDetails/vendor/doka.min.css";
import { create } from "../../components/FrondDetails/vendor/doka.esm.min";
import uplodaIcon from "../../Assets/create-frond/Upload.svg";
import { AuthContext } from "Context/AuthContext";
import HighlightWithinTextarea from "react-highlight-within-textarea";
import { PopUpContext } from "Context/PopUpContext";

export default function AddPostModal({
  isOpen,
  onClose,
  frondData,
  isAddLocation,
  editImage,
}) {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      location: frondData?.editLocation,
      desc: frondData?.contnet || "",
      image: "",
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [descLength, setDescLength] = useState(0);
  const [hashtags, setHashtags] = useState([]);

  // Location

  const [address, setAddress] = useState(frondData.editLocation);
  const [location, setLocation] = useState({
    latitude: frondData?.editLatitude || null,
    longitude: frondData?.editLongitude || null,
  });

  const [city, setCity] = useState(frondData?.editCity || "");
  const [country, setCountry] = useState(frondData?.editCountry || "");

  useEffect(() => {
    if (frondData?.editContent) {
      setValue("desc", frondData.editContent);
    }
  }, [frondData]);

  const [isShowMap, setIsShowMap] = useState(true);
  const [position, setPosition] = useState([0, 0]);
  const [mapLoaded, setMapLoaded] = useState(false);

  const labelLocationHtml = `Location <span class='text-xs text-cardGrayColor'>(Optional)</span>`;

  useLayoutEffect(() => {
    setAddress(frondData.editLocation);
    if (frondData.editLocation) {
      setValue("location", frondData.editLocation);
    }
  }, [frondData.editLocation]);

  useLayoutEffect(() => {
    setAddress(address);
    if (address) {
      setValue("location", address);
    }
  }, [address]);

  const provider = new OpenStreetMapProvider();

  useEffect(() => {
    if (address) {
      if (!location.latitude || !location.longitude) {
        handleSearch(address);
      } else {
        setPosition([location.latitude, location.longitude]);
        handleSearch(address);
      }
    } else {
      setPosition([0, 0]);
      setIsShowMap(true);
    }
  }, [address]);

  useEffect(() => {
    if (frondData.editLatitude && frondData.editLongitude) {
      setPosition([frondData.editLatitude, frondData.editLongitude]);
    }
  }, [frondData, frondData.editLocation]);

  const CustomMarker = () => {
    const map = useMap();
    map.setView(position, 12);

    const icon = L.divIcon({
      html: `<img src="${mapMarker}" style="width: 28px; height: 28px;" />`,
      className: "",
    });

    return <Marker position={position} icon={icon}></Marker>;
  };

  const handleSearch = useCallback(
    debounce(async (query) => {
      const results = await provider.search({ query });
      if (results.length > 0) {
        const { x, y } = results[0];
        setPosition([y, x]);
        setLocation({ latitude: y, longitude: x });
        setIsShowMap(false);
      } else {
        setIsShowMap(true);
      }
    }, 500),
    []
  );

  const handleInputChange = (e) => {
    const query = e.target.value;
    setAddress(query);
    if (query.length > 3) {
      handleSearch(query);
    } else {
      setIsShowMap(true);
    }
  };

  const handleTileLoad = () => {
    setMapLoaded(true);
  };

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  useEffect(() => {
    if (!frondData.editLocation && !address) {
      getLocation();
    }
  }, [frondData.editLocation]);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      toast("Geolocation is not supported by this browser.", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  const showPosition = async (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setLocation({ latitude, longitude });

    try {
      const response = await axios.get(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
      );
      setCity(response?.data?.city);
      setCountry(response?.data?.countryName);
      setAddress(response?.data?.city);
      setPosition([latitude, longitude]);
      setIsShowMap(false);
    } catch (error) {
      setIsShowMap(true);
      toast("Error fetching country name", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setCity("");
        setCountry("");
        setAddress("");
        setPosition([0, 0]);
        setValue("location", "");
        break;
      case error.POSITION_UNAVAILABLE:
        setCity("");
        setCountry("");
        setAddress("");
        setPosition([0, 0]);
        setValue("location", "");
        toast("Location information is unavailable.", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
        break;
      case error.TIMEOUT:
        setCity("");
        setCountry("");
        setAddress("");
        setPosition([0, 0]);
        setValue("location", "");
        toast("The request to get user location timed out.", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
        break;
      case error.UNKNOWN_ERROR:
        setCity("");
        setCountry("");
        setAddress("");
        setPosition([0, 0]);
        setValue("location", "");
        toast("An unknown error occurred.", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
        break;
      default:
        setCity("");
        setCountry("");
        setAddress("");
        setPosition([0, 0]);
        setValue("location", "");
        toast("An unknown error occurred.", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
        break;
    }
  };

  const getCityCountry = async (position) => {
    const latitude = position[0];
    const longitude = position[1];

    try {
      const apiKey = "4ca6d111075d4f3baf96605ac6a8a96b";
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}&language=en`
      );
      const result = response?.data?.results[0];
      if (result) {
        const city =
          result.components.city ||
          result.components.town ||
          result.components.village ||
          result.components.hamlet;
        const country = result.components.country;
        setCity(city);
        setCountry(country);
      } else {
        toast("Location not found", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
      }
    } catch (error) {
      toast("Error fetching location data", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  useEffect(() => {
    if (position.length > 0) {
      getCityCountry([position[0], position[1]]);
    }
  }, [position]);

  // Hashtags

  const extractHashtags = (text) => {
    const hashtagPattern = /#[\w]+/g;
    const extractedHashtags = text.match(hashtagPattern) || [];
    setHashtags([...new Set(extractedHashtags.map((tag) => tag.substring(1)))]);
  };

  const handleDescChange = (value) => {
    setDescLength(value.length);
    extractHashtags(value);
  };

  // Image

  const { token } = useContext(AuthContext);

  const imgTitle = `<Image src=${uplodaIcon} alt="Upload" /> <p>Drag and Drop Image here</p> <p>Or</p> <span>Browse Images</span>`;

  registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginImageResize,
    FilePondPluginImageTransform,
    FilePondPluginImageEdit
    // FilePondPluginFileEncode,
  );

  const [files, setFiles] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);

  async function deleteImg(reqBody) {
    setIsLoading(true);
    setIsDisabled(true);

    try {
      const { data } = await axios.post(
        `https://frond-admin.code-minds.tech/api/frond-about/deleteimage/${frondData?.editId}`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (data.status === 200) {
        toast("Image Deleted Successfully", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast success-toast",
        });
        // getFrondData();
      }
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDisabled(false);
    setIsLoading(false);
  }

  const [imgAnimation, setImgAnimation] = useState(false);

  useEffect(() => {
    if (files.length > 0) {
      setIsDisabled(false);
    }
  }, [files]);

  useEffect(() => {
    if (editImage) {
      setImagePreview(editImage);
    }
  }, [editImage]);

  // Add Post Api

  const { setIsAddPost } = useContext(PopUpContext);

  async function createPost(reqBody) {
    setIsLoading(true);
    setIsDisabled(true);

    try {
      const { data } = await axios.post(
        `https://frond-admin.code-minds.tech/api/posts`,
        {
          content: reqBody.desc,
          images: [uploadedFile],
          hashtags: hashtags,
          longitude: position[1] ? position[1] : null,
          latitude: position[0] ? position[0] : null,
          location: reqBody.location,
          country: country ? country : "Country",
          city: city ? city : "City",
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast("Post Added", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });

      setIsAddPost(true);
      setTimeout(() => {
        setIsAddPost(false);
      }, 1000);

      onClose();
      reset();
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsLoading(false);
    setIsDisabled(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onClose}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999]",
        footer: "justify-center items-center pb-2",
        body: "border-b-1 border-solid border-grayBorder pt-0 pb-5 px-5 gap-[30px]",
        closeButton: "hidden",
        base: "rounded-[10px]",
      }}
    >
      <ModalContent>
        {(closeModal) => (
          <>
            <ModalHeader className="flex justify-between gap-2.5 items-center p-0 pb-[15px] pt-[15px] sm:px-5 px-2.5 mb-[15px] relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[0.5px] after:bg-grayBorder">
              <h3 className="text-lg text-textColor font-bold leading-[10.35px]">
                Add Post
              </h3>
              <button onClick={closeModal} aria-label="Close">
                <img src={closeIcon} alt="Close" className="w-6 h-6" />
              </button>
            </ModalHeader>
            <ModalBody className="overflow-auto">
              <form onSubmit={handleSubmit(createPost)}>
                <div className="mb-[30px]">
                  <Controller
                    name="desc"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Post content is required",
                      },
                      minLength: {
                        value: 2,
                        message: "Minimum number of characters is 2",
                      },
                      maxLength: {
                        value: 500,
                        message: "Maximum number of characters is 500",
                      },
                    }}
                    render={({ field }) => (
                      <div>
                        <p className="text-sm text-textColor">
                          Post{" "}
                          <span className="text-xs text-cardGrayColor">
                            (Required)
                          </span>
                        </p>
                        <HighlightWithinTextarea
                          {...field}
                          placeholder="Text here..."
                          value={field.value || ""}
                          onChange={(value) => {
                            field.onChange(value);
                            handleDescChange(value); // Extract hashtags here
                          }}
                          highlight={/#[\w]+/g} // Highlight hashtags
                        />
                        {errors.desc?.message && (
                          <p className="text-red-500 text-xs mt-1">
                            {errors.desc.message}
                          </p>
                        )}
                        {/* {!errors.desc?.message && 500 - descLength > 0 && (
                          <p className="text-[.75rem] text-mainBlue mt-1">
                            {500 - descLength} remaining characters
                          </p>
                        )} */}
                      </div>
                    )}
                  />
                </div>

                <div className="relative mb-[30px] about-edit-img sm:min-h-[295px] sm:max-h-[295px] min-h-[200px] max-h-[200px] sm:min-w-[100%] sm:max-w-[100%] min-w-[100%] max-w-[100%]">
                  {imagePreview && (
                    <div
                      className={`absolute right-0 left-0 top-0 z-50 border-1 border-dashed border-[#BBBBBB] rounded-[20px] transition-all duration-400 ${
                        !imgAnimation ? "h-[100%]" : "h-0"
                      }`}
                    >
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setImgAnimation(true);
                          setTimeout(() => {
                            setImagePreview(null);
                            setImgAnimation(false);
                            deleteImg();
                          }, 400);
                        }}
                        className={`absolute right-[25px] top-[25px] transition-all duration-400 ${
                          !imgAnimation ? "opacity-1" : "opacity-0"
                        }`}
                      >
                        <Image
                          src={removeImg}
                          alt="Remove Image"
                          className="w-[24px] h-[24px]"
                        />
                      </button>
                      <img
                        src={imagePreview}
                        alt="Preview"
                        className={`w-full ${
                          !imgAnimation ? "h-[100%]" : "h-0"
                        } border-1 border-dashed border-[#BBBBBB] rounded-[20px] transition-all duration-400`}
                      />
                    </div>
                  )}
                  <FilePond
                    files={files}
                    allowReorder={true}
                    allowMultiple={false}
                    allowFileEncode={false}
                    onupdatefiles={setFiles}
                    onpreparefile={(file, output) => {
                      setUploadedFile(output);
                    }}
                    imageResizeTargetWidth={1000}
                    imageResizeTargetHeight={500}
                    imageResizeUpscale={false}
                    imageResizeMode="force"
                    acceptedFileTypes={["image/*"]}
                    name="file"
                    imageEditEditor={create({
                      cropMinImageWidth: 500,
                      cropMinImageHeight: 146,
                    })}
                    labelIdle={imgTitle}
                    allowImagePreview={true}
                  />
                </div>
                <div className="flex flex-col gap-2.5 items-center">
                  <div className="sm:min-h-[224px] sm:max-h-[224px] min-h-[150px] max-h-[150px] w-[100%] relative map-box">
                    <MapContainer
                      center={position}
                      zoom={12}
                      dragging={false}
                      doubleClickZoom={false}
                      zoomControl={false}
                      scrollWheelZoom={false}
                      style={{
                        height: "289.6px",
                        width: "100%",
                        position: "relative",
                        zIndex: "20",
                      }}
                      whenCreated={(map) => {
                        map.on("load", handleMapLoad);
                      }}
                    >
                      <TileLayer
                        eventHandlers={{
                          tileload: handleTileLoad,
                        }}
                        attribution='<a href="https://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://tile.jawg.io/jawg-lagoon/{z}/{x}/{y}{r}.png?access-token=bGzRVT1WzXv8lPpb0ecTZTmZC0ZK2AIyJA2YMyPE5Dp1FNFqlX4sVyI6PSbA0iMH"
                      />
                      <CustomMarker />
                    </MapContainer>
                    {!mapLoaded && (
                      <div
                        className={`bg-gray-400 rounded-[20px] absolute inset-0 transition-all duration-300 flex justify-center items-center ${
                          mapLoaded ? "opacity-0 z-10" : "opacity-100 z-30"
                        }`}
                      >
                        <span className="flex gap-2 justify-center items-center text-white">
                          <i className="fa-solid fa-spinner fa-spin text-[30px]"></i>
                        </span>
                      </div>
                    )}
                    {isShowMap ? (
                      <div
                        className={`bg-gray-400 absolute inset-0 transition-all duration-300 rounded-[20px] ${
                          isShowMap ? "opacity-100 z-30" : "opacity-0 z-10"
                        }`}
                      >
                        <Image
                          classNames={{ wrapper: "min-w-full h-full" }}
                          src={tempMap}
                          alt="Map"
                          className="size-full"
                        />
                      </div>
                    ) : null}
                  </div>
                  <Controller
                    name="location"
                    control={control}
                    rules={{
                      validate: {
                        noTrailingSpace: (value) =>
                          !/\s$/.test(value) ||
                          "Location shouldn't end with a space",
                      },
                      pattern: {
                        value:
                          /^(?! )[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=\/\\]+(?!.*\s{2})[a-zA-Z0-9\-\/\\\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\= ]*$/gi,
                        message:
                          "Location may contain characters, numbers and special characters",
                      },
                      required: "Location is required",
                    }}
                    render={({ field }) => (
                      <div className="about-location-wrapper relative group w-full">
                        <div className="absolute left-0 right-0 top-[-10px] z-30 flex justify-center items-center bg-white rounded-2xl p-[5px] border-1 border-solid border-grayBorder transition-all duration-300 opacity-0 group-hover:opacity-100">
                          <span className="text-[13.8px] font-normal text-textColor">
                            Location may contain country, city, town or postcode
                          </span>
                        </div>
                        <Input
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          {...field}
                          type="text"
                          variant="bordered"
                          placeholder="Search Location"
                          labelPlacement="outside"
                          onInput={handleInputChange}
                          startContent={
                            <Image
                              src={locationIcon}
                              className="min-w-[18px] max-w-[18px] h-[18px] me-1"
                            />
                          }
                          classNames={{
                            base: "input-noMargin h-[110px] justify-center location-field w-full",
                            label: "text-textColor font-normal text-sm",
                            input: "border-none px-[18px]",
                            mainWrapper: "h-[42px]",
                            innerWrapper: "h-[42px] px-[18px]",
                            inputWrapper: `border-[0.86px] p-0 border-solid ${
                              errors.location?.message
                                ? "invalid-text"
                                : "border-[#E5E4EB]"
                            } rounded-[8px] h-[42px]`,
                          }}
                          label={
                            <span
                              dangerouslySetInnerHTML={{
                                __html: labelLocationHtml,
                              }}
                            />
                          }
                          isInvalid={errors.location?.message ? true : false}
                          errorMessage={errors.location?.message}
                        />
                      </div>
                    )}
                  />
                </div>
                <div className="flex justify-center">
                  <button
                    disabled={isDisabled || descLength === 0}
                    type="submit"
                    className="text-base font-bold leading-[18.4px] text-white flex justify-center items-center bg-mainGradiant rounded-[27px] py-[11px] px-[25px] sm:w-[234px] w-[117px] sm:min-h-[39px] sm:max-h-[39px] min-h-[30px] max-h-[30px]"
                  >
                    {isLoading ? (
                      <span className="flex justify-center items-center text-white">
                        <i className="fa-solid fa-spinner fa-spin"></i>
                      </span>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </form>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
