import React, { useEffect, useState } from "react";
import rocket from "../../Assets/frond-details/rocket.svg";
import dollar from "../../Assets/frond-details/dollar.svg";
import calendar from "../../Assets/frond-details/calendar.svg";
import like from "../../Assets/frond-details/like.svg";
import { Image } from "@nextui-org/react";
import L from "leaflet";
import mapMarker from "../../Assets/create-frond/geolocation.svg";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import tempMap from "../../Assets/create-frond/temp-map.png";

const ShareBody = ({ frondData }) => {
  // Map

  const [isShowMap, setIsShowMap] = useState(true);
  const [position, setPosition] = useState([0, 0]);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [startDateArr, setStartDateArr] = useState("");
  const [endDateArr, setEndDateArr] = useState("");

  useEffect(() => {}, [position]);

  const [mapLoaded, setMapLoaded] = useState(false);

  const handleTileLoad = () => {
    setMapLoaded(true);
  };

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  const CustomMarker = () => {
    const map = useMap();
    map.setView(position, 12);

    const icon = L.divIcon({
      html: `<img src="${mapMarker}" style="width: 28px; height: 28px;" />`,
      className: "",
    });

    return <Marker position={position} icon={icon}></Marker>;
  };

  useEffect(() => {
    if (frondData?.frond?.start_date) {
      setStartDateArr(frondData?.frond?.start_date.split("/"));
    }
    if (frondData?.frond?.end_date) {
      setEndDateArr(frondData.frond?.end_date.split("/"));
    }
    if (frondData?.latitude && frondData?.longitude) {
      setPosition([frondData?.latitude, frondData?.longitude]);
      setIsShowMap(false);
    }
  }, [frondData]);

  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setIsPageLoaded(true);
    };

    // Check if the page is already loaded
    if (document.readyState === "complete") {
      setTimeout(() => {
        handleLoad();
      }, 1000);
    } else {
      window.addEventListener("load", handleLoad);
      // Cleanup the event listener when the component unmounts
      return () => window.removeEventListener("load", handleLoad);
    }
  }, []);

  return (
    <>
      <section className="about-frond">
        <div className="flex gap-x-2.5 gap-y-2.5 items-start justify-between timelineMedia:flex-nowrap flex-wrap">
          <div className="content flex flex-col">
            <div className="flex items-start gap-5">
              <div className="info flex items-start phone:gap-[45px] gap-5 mb-5">
                <div className="flex flex-col gap-2">
                  <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                    Category
                  </h5>
                  <div className="flex items-center gap-[5px]">
                    <Image
                      src={rocket}
                      alt="Rocket"
                      className="phone:min-w-[18px] phone:min-h-[18px] phone:max-w-[18px] phone:max-h-[18px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                    />
                    <span className="leading-normal text-textColor phone:text-base text-[10px]">
                      {frondData?.frond?.category}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                    Importance
                  </h5>
                  <div
                    className={`${
                      !frondData?.frond?.priority && "text-center text-[10px]"
                    }`}
                  >
                    <span
                      className={`leading-normal ${
                        frondData?.frond?.priority === "High" &&
                        "text-[#FF3D00E5]"
                      } ${
                        frondData?.frond?.priority === "Medium" &&
                        "text-[#FFC000]"
                      } ${
                        frondData?.frond?.priority === "Low" && "text-[#5CCDB0]"
                      } ${!frondData?.frond?.priority && "text-textColor"}`}
                    >
                      {frondData?.frond?.priority
                        ? frondData?.frond?.priority
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                    Budget
                  </h5>
                  <div className="flex items-center gap-[5px]">
                    <Image
                      src={dollar}
                      alt="Dollar"
                      className="phone:min-w-[18px] phone:min-h-[18px] phone:max-w-[18px] phone:max-h-[18px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                    />
                    <span className="leading-normal text-textColor phone:text-base text-[10px]">
                      {frondData?.frond?.budget
                        ? frondData?.frond?.budget
                        : "-"}
                    </span>
                    <span className="leading-normal text-textColor phone:text-base text-[10px]">
                      {frondData?.frond?.currency
                        ? frondData?.frond?.currency
                        : "$"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="info flex items-start gap-[22px] mb-5">
              <div className="flex flex-col gap-2">
                <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                  Start Date
                </h5>
                <div className="flex items-center gap-[5px]">
                  <Image
                    src={calendar}
                    alt="Calendar"
                    className="phone:min-w-[18px] phone:min-h-[18px] phone:max-w-[18px] phone:max-h-[18px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                  />
                  <span className="leading-normal text-textColor phone:text-base text-[10px]">
                    {startDateArr
                      ? `${startDateArr[0]} ${months[startDateArr[1] - 1]}, ${
                          startDateArr[2]
                        }`
                      : "-"}
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                  End Date
                </h5>
                <div className="flex items-center gap-[5px]">
                  <Image
                    src={calendar}
                    alt="Calendar"
                    className="phone:min-w-[18px] phone:min-h-[18px] phone:max-w-[18px] phone:max-h-[18px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                  />
                  <span className="leading-normal text-textColor phone:text-base text-[10px]">
                    {endDateArr
                      ? `${endDateArr[0]} ${months[endDateArr[1] - 1]}, ${
                          endDateArr[2]
                        }`
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className="info flex items-start gap-[22px] mb-5">
              <div className="flex flex-col gap-2">
                <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                  Description
                </h5>
                <div className={`${!frondData?.frond?.desc && "text-center"}`}>
                  <p className="leading-normal text-textColor phone:text-base text-[10px] break-all">
                    {frondData?.frond?.desc ? frondData?.frond?.desc : "-"}
                  </p>
                </div>
              </div>
            </div>
            <div className="info flex items-start gap-[22px] mb-5">
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-[5px]">
                  <Image
                    src={like}
                    alt="Heart"
                    className="phone:min-w-[18px] phone:min-h-[18px] phone:max-w-[18px] phone:max-h-[18px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                  />
                  <span className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                    Tags
                  </span>
                </div>
                <div
                  className={`flex items-center phone:text-base text-[10px] ${
                    frondData?.tags?.length === 0 && "justify-center"
                  } gap-2.5 flex-wrap`}
                >
                  {frondData?.tags?.length > 0
                    ? frondData?.tags.map((item, idx) => (
                        <div
                          key={idx}
                          className="py-1.5 px-2.5 rounded-[20px] bg-mainBlue text-white flex justify-center items-center"
                        >
                          <span className="phone:text-sm text-[10px] leading-[16.1px]">
                            {item.name.en}
                          </span>
                        </div>
                      ))
                    : "-"}
                </div>
              </div>
            </div>
          </div>
          {isPageLoaded && (
            <div className="map timelineMedia:min-w-[314px] timelineMedia:max-w-[314px] min-w-full max-w-full min-h-[296px] border-2 border-solid border-grayBorder rounded-[20px] relative">
              <div className="w-full h-[224px] relative">
                <MapContainer
                  className="frond-details-map"
                  center={position}
                  zoom={12}
                  dragging={false}
                  doubleClickZoom={false}
                  zoomControl={false}
                  scrollWheelZoom={false}
                  style={{
                    height: "224px",
                    width: "100%",
                    position: "relative",
                    zIndex: "20",
                  }}
                  whenCreated={(map) => {
                    map.on("load", handleMapLoad);
                  }}
                >
                  <TileLayer
                    eventHandlers={{
                      tileload: handleTileLoad,
                    }}
                    attribution='<a href="https://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://tile.jawg.io/jawg-lagoon/{z}/{x}/{y}{r}.png?access-token=bGzRVT1WzXv8lPpb0ecTZTmZC0ZK2AIyJA2YMyPE5Dp1FNFqlX4sVyI6PSbA0iMH"
                  />
                  <CustomMarker />
                </MapContainer>
                {!mapLoaded && (
                  <div
                    className={`bg-gray-400 rounded-t-[20px] absolute inset-0 transition-all duration-300 flex justify-center items-center ${
                      mapLoaded ? "opacity-0 z-10" : "opacity-100 z-40"
                    }`}
                  >
                    <span className="flex gap-2 justify-center items-center text-white">
                      <i className="fa-solid fa-spinner fa-spin text-[30px]"></i>
                    </span>
                  </div>
                )}
                {isShowMap ? (
                  <div
                    className={`bg-gray-400 absolute inset-0 transition-all duration-300 rounded-t-[20px] ${
                      isShowMap ? "opacity-100 z-40" : "opacity-0 z-10"
                    }`}
                  >
                    <Image
                      classNames={{
                        wrapper: "w-full h-full min-w-full",
                        img: "temp-map-details rounded-[20px]",
                      }}
                      src={tempMap}
                      alt="Map"
                      className="size-full"
                    />
                  </div>
                ) : null}
              </div>
              <div className="flex justify-between items-start py-2.5 px-5 min-h-[72px]">
                <div className="flex flex-col gap-2">
                  <h5 className="text-base text-textColor font-normal leading-[18.4px]">
                    {frondData?.frond?.country
                      ? `${frondData?.frond?.country}, `
                      : `Country, `}
                    {frondData?.frond?.city ? frondData?.frond?.city : "City"}
                  </h5>
                  <p className="text-sm text-cardGrayColor">
                    {frondData?.frond?.location
                      ? frondData?.frond?.location
                      : "Location"}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ShareBody;
