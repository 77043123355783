import { createContext, useState } from "react";

export let DeleteScrapbookFilesContext = createContext();

export default function DeleteScrapbookFilesContextProvider({ children }) {
    const [openDeleteFilesCheckbox, setOpenDeleteFilesCheckbox] = useState(false);
    const [deleteFilesProjects, setDeleteFilesProjects] = useState([]);

    return (
        <DeleteScrapbookFilesContext.Provider value={{ openDeleteFilesCheckbox, setOpenDeleteFilesCheckbox, deleteFilesProjects, setDeleteFilesProjects }}>
            {children}
        </DeleteScrapbookFilesContext.Provider>
    );
}
