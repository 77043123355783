import React from "react";

export default function ProfileData({ userEmail, userPhone }) {
  return (
    <>
      <div className="cards flex items-center gap-5 mb-[30px] sm:flex-nowrap flex-wrap px-5 justify-center sm:mt-0 mt-5">
        <div className="item w-[129px] h-[86px] border-1 border-solid border-grayBorder no-focus rounded-[10px] p-2 flex flex-col items-center justify-center gap-2">
          <h3 className="text-textColor leading-[22px] text-lg font-bold">
            18
          </h3>
          <h4 className="text-textColor leading-[16.1px] text-sm font-normal">
            All fronds
          </h4>
        </div>
        <div className="item w-[129px] h-[86px] border-1 border-solid border-grayBorder no-focus rounded-[10px] p-2 flex flex-col items-center justify-center gap-2">
          <h3 className="text-textColor leading-[22px] text-lg font-bold">
            16
          </h3>
          <h4 className="text-textColor leading-[16.1px] text-sm font-normal">
            Shared fronds
          </h4>
        </div>
        <div className="item w-[129px] h-[86px] border-1 border-solid border-grayBorder no-focus rounded-[10px] p-2 flex flex-col items-center justify-center gap-2">
          <h3 className="text-textColor leading-[22px] text-lg font-bold">2</h3>
          <h4 className="text-textColor leading-[16.1px] text-sm font-normal">
            Completed fronds
          </h4>
        </div>
      </div>
      <div className="info sm:w-auto w-[75%] sm:mx-0 mx-auto">
        <div className="email mb-5 pb-5 border-b-1 border-solid border-grayBorder">
          <h3 className="text-cardGrayColor leading-[16.1px] text-sm font-normal mb-[5px]">
            Email
          </h3>
          <p className="text-textColor font-bold">{userEmail}</p>
        </div>
        <div className="phone">
          <h3 className="text-cardGrayColor leading-[16.1px] text-sm font-normal mb-[5px]">
            Phone
          </h3>
          <p className="text-textColor font-bold">{userPhone}</p>
        </div>
      </div>
    </>
  );
}
