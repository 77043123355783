import React, { useEffect, useState } from "react";
import {
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from "@nextui-org/react";

import closeIcon from "../../Assets/popup/cross-square.svg";
import magnifier from "../../Assets/my-fronds/magnifier.svg";
import { Hits, SearchBox } from "react-instantsearch";

function Hit({ hit }) {
  return (
    <div className="bg-grayBorder rounded-lg p-2.5 mb-2.5">
      <p className="text-transparent bg-clip-text bg-mainGradiant text-base leading-[22px]">
        {hit?.title}
      </p>
      <p className="text-textColor text-base leading-[22px]">{hit?.desc}</p>
    </div>
  );
}

export default function GlobalSearch({ isOpen, onClose }) {
  const handleClose = () => {
    onClose();
  };

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {}, [inputValue]);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={() => {
        onClose();
      }}
      placement="center"
      backdrop="opaque"
      size="4xl"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999]",
        footer: "justify-center items-center py-5",
        body: "border-b-1 border-solid border-grayBorder pt-2 pb-4",
        closeButton: "hidden",
        base: "p-5 search-modal",
      }}
      hideCloseButton={true}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex items-center justify-end">
              <button onClick={handleClose} className="" aria-label="Close">
                <img src={closeIcon} alt="Close" className="w-6 h-6" />
              </button>
            </ModalHeader>
            <ModalBody>
              <SearchBox
                onInput={(e) => {
                  setInputValue(e.target.value);
                }}
                translations={{
                  placeholder: "Search frond...",
                }}
                submitIconComponent={() => (
                  <Image
                    src={magnifier}
                    alt="magnifier"
                    className="min-w-[18px] min-h-[18px]"
                  />
                )}
                placeholder="Search frond..."
                classNames={{
                  root: "w-full h-[39px] bg-[#F5F6FF] rounded-[20px] search-navbar px-2.5",
                  input: "border-none bg-[#F5F6FF] w-full rounded-[20px]",
                }}
              />
              {inputValue.length > 0 && <Hits hitComponent={Hit} />}
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
