import { createContext, useState } from "react";

export let HashtagsContext = createContext();

export default function HashtagsContextProvider({ children }) {
    const [targetHashtag, setTargetHashtag] = useState(null);

    return (
        <HashtagsContext.Provider value={{ targetHashtag, setTargetHashtag }}>
            {children}
        </HashtagsContext.Provider>
    );
}