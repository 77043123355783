import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

import "@eidellev/react-tag-input/dist/index.css";

import {
  Image,
  Input,
  Select,
  SelectItem,
  Textarea,
  Tooltip,
} from "@nextui-org/react";

import tempMap from "../../Assets/create-frond/temp-map.png";

import { Controller, useForm } from "react-hook-form";

import { DateRangePicker } from "@nextui-org/react";
import { makeUploadRequest } from "./cloudinary";
import mapMarker from "../../Assets/create-frond/geolocation.svg";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import L from "leaflet";

import { WithContext as ReactTags } from "react-tag-input";
import { toast } from "react-toastify";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";

// css
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";

// Import Doka. For testing purpose only, if you're intrested in using Doka
// in your project please purchase a license at https://pqina.nl/doka/
import "./vendor/doka.min.css";
import { create } from "./vendor/doka.esm.min";
// import FilePondPluginFileEncode from "filepond-plugin-file-encode";
// import { parseDate } from "@internationalized/date";
import axios from "axios";

import uplodaIcon from "../../Assets/create-frond/Upload.svg";
import calendar from "../../Assets/create-frond/calendar.svg";
import locationIcon from "../../Assets/create-frond/location.svg";
import euro from "../../Assets/create-frond/euro.svg";
import up from "../../Assets/create-frond/up.svg";
import RemoveComponent from "./RemoveComponent";
import { AuthContext } from "Context/AuthContext";
import { OpenStreetMapProvider } from "react-leaflet-geosearch";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import useSize from "hooks/useSize";
import { SidebarContext } from "Context/SidebarContext";

// Register the FilePond plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginImageEdit
  // FilePondPluginFileEncode,
);

export default function CreateFrondForm() {
  const [files, setFiles] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [address, setAddress] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");

  const [isShowMap, setIsShowMap] = useState(true);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      dateRange: "",
      category: "",
      location: address,
      budget: "",
      currency: "",
      importance: "",
      tags: [],
      desc: "",
    },
  });

  const [isDisabled, setIsDisabled] = useState(false);

  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  function discardFrond() {
    navigate("/");
    setFiles([]);
    setUploadedFile([]);
    setPosition([0, 0]);
    reset();

    setTags([]);
    setImportance(null);
  }

  async function addFrond(reqBody) {
    setIsLoading(true);
    setIsDisabled(true);
    const start_date = reqBody.dateRange.start
      ? `${reqBody.dateRange.start.day}/${reqBody.dateRange.start.month}/${reqBody.dateRange.start.year}`
      : null;
    const end_date = reqBody.dateRange.end
      ? `${reqBody.dateRange.end.day}/${reqBody.dateRange.end.month}/${reqBody.dateRange.end.year}`
      : null;
    const tags = reqBody.tags.map((tag) => tag.text);
    if (country && city) {
      try {
        const { data } = await axios.post(
          "https://frond-admin.code-minds.tech/api/frond",
          {
            title: reqBody.title,
            start_date,
            end_date,
            category: reqBody.category,
            desc: reqBody.desc,
            budget: reqBody.budget,
            currency: reqBody.currency,
            location: reqBody.location,
            latitude: position[0] ? position[0] : null,
            longitude: position[1] ? position[1] : null,
            tags: tags,
            image: uploadedFile,
            priority: importance ? importance : null,
            country: country ? country : null,
            city: city ? city : null,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (data.status === 201) {
          toast("Frond Created Successfully", {
            autoClose: 1500,
            position: "bottom-right",
            hideProgressBar: true,
            className: "rounded-4xl custom-toast success-toast",
          });
          navigate(`/frond/about/${data?.response?.data?.project?.id}`);
          setFiles([]);
          setUploadedFile([]);
          setPosition([0, 0]);
          reset();

          setTags([]);
          setImportance(null);
        }
      } catch (error) {
        toast(error?.response?.data?.message, {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
      }
    } else {
      getCityCountry(position[0], position[1]);
      try {
        const { data } = await axios.post(
          "https://frond-admin.code-minds.tech/api/frond",
          {
            title: reqBody.title,
            start_date,
            end_date,
            category: reqBody.category,
            desc: reqBody.desc,
            budget: reqBody.budget,
            currency: reqBody.currency,
            location: reqBody.location,
            latitude: position[0] ? position[0] : null,
            longitude: position[1] ? position[1] : null,
            tags: tags,
            image: uploadedFile,
            priority: importance ? importance : null,
            country: country ? country : null,
            city: city ? city : null,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (data.status === 201) {
          toast("Frond saved succesfully", {
            autoClose: 1500,
            position: "bottom-right",
            hideProgressBar: true,
            className: "rounded-4xl custom-toast success-toast",
          });
          navigate(`/frond/about/${data?.response?.data?.project?.id}`);
          setFiles([]);
          setUploadedFile([]);
          setPosition([0, 0]);
          reset();

          setTags([]);
          setImportance(null);
        }
      } catch (error) {
        toast(error?.response?.data?.message, {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
      }
    }
    setIsDisabled(false);
    setIsLoading(false);
  }

  const categories = ["Home", "Event", "Escape", "Venture", "Other"];
  const [importance, setImportance] = useState(null);

  const imgTitle = `<Image src=${uplodaIcon} alt="Upload" /> <p>Drag and Drop Image here</p> <p>Or</p> <span>Browse Images</span>`;
  const labelHtml = `Location <span class='text-xs text-cardGrayColor'>(Optional)</span>`;
  const labelBudgetHtml = `Budget <span class='text-xs text-cardGrayColor'>(Optional)</span>`;
  const labelCurrencyHtml = `Currency <span class='text-xs text-cardGrayColor'>(Optional)</span>`;
  const labelDescHtml = `Description <span class='text-xs text-cardGrayColor'>(Optional)</span>`;
  const labelDateHtml = `Start Date - End Date <span class='text-xs text-cardGrayColor'>(Optional)</span>`;

  // currencies

  const [currenciesList, setCurrenciesList] = useState([]);

  const getCurrencies = async () => {
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/currencies`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCurrenciesList(data?.response?.data[0]);
      console.log(data?.response?.data[0]);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  useEffect(() => {
    getCurrencies();
  }, []);

  //Tags

  const [tags, setTags] = useState([]);
  const [tagError, setTagError] = useState("");
  const [tagLimit, setTagLimit] = useState(false);

  useEffect(() => {
    setValue("tags", tags);

    if (tags.length === 10) {
      setTagLimit(true);

      setTimeout(() => {
        setTagLimit(false);
      }, 1500);
    }
  }, [tags, setValue]);

  const handleValidation = (tags) => {
    for (let tag of tags) {
      if (tag.text.length < 2 || tag.text.length > 60) {
        return "Each tag must be between 2 and 60 characters";
      }
      if (
        !/^[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=\/\\]+(\s[A-Za-z0-9\-\/\\\_\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+)*$/gi.test(
          tag.text
        )
      ) {
        return "Tag may contain characters, numbers or special characters and shouldn't end with space";
      }
    }
    return true;
  };

  const handleDelete = (i) => {
    const newTags = tags.filter((tag, index) => index !== i);
    setTags(newTags);
    setValue("tags", newTags);

    const validation = handleValidation(newTags);
    if (validation === true) {
      setTagError("");
    } else {
      setTagError(validation);

      setTimeout(() => {
        setTagError("");
      }, 1500);
    }
  };

  const handleAddition = (tag) => {
    const newTags = [...tags, tag];
    const validation = handleValidation(newTags);

    if (validation === true) {
      setTags(newTags);
      setValue("tags", newTags);
      setTagError("");
    } else {
      setTagError(validation);

      setTimeout(() => {
        setTagError("");
      }, 1500);
    }
  };

  const process = (fieldName, file, load, error, progress, abort) => {
    const abortRequest = makeUploadRequest({
      file,
      fieldName,
      successCallback: load,
      errorCallback: error,
      progressCallback: progress,
    });

    return {
      abort: () => {
        abortRequest();
        abort();
      },
    };
  };

  const [position, setPosition] = useState([0, 0]);

  const provider = new OpenStreetMapProvider();

  const CustomMarker = () => {
    const map = useMap();
    map.setView(position, 12); // Center the map on the position

    const icon = L.divIcon({
      html: `<img src="${mapMarker}" style="width: 28px; height: 28px;" />`,
      className: "",
    });

    return <Marker position={position} icon={icon}></Marker>;
  };

  useEffect(() => {
    const box = document.querySelector(".date-box");
    box.addEventListener("click", (e) => {
      if (e.target.classList.contains("no-action")) {
        e.preventDefault();
      }
    });
  }, []);

  const handleSearch = useCallback(
    debounce(async (query) => {
      const results = await provider.search({ query });
      if (results.length > 0) {
        const { x, y } = results[0];
        setPosition([y, x]);
        setIsShowMap(false);
      } else {
        setIsShowMap(true);
      }
    }, 500),
    []
  );

  const handleInputChange = (e) => {
    const query = e.target.value;
    setAddress(query);
    if (query.length > 3) {
      handleSearch(query);
      setIsShowMap(false);
    } else {
      setIsShowMap(true);
    }
  };

  useEffect(() => {
    if (!address) {
      setPosition([0, 0]);
      setIsShowMap(true);
    }
  }, [address]);

  useEffect(() => {}, [position, address]);

  const [mapLoaded, setMapLoaded] = useState(false);

  const handleTileLoad = () => {
    setMapLoaded(true);
  };

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  const [descLength, setDescLength] = useState(0);

  // Get City and Country

  const getCityCountry = async (position) => {
    const latitude = position[0];
    const longitude = position[1];

    try {
      const apiKey = "4ca6d111075d4f3baf96605ac6a8a96b";
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}&language=en`
      );

      const result = response?.data?.results[0];
      if (result) {
        const city =
          result.components.city ||
          result.components.town ||
          result.components.village ||
          result.components.hamlet;
        const country = result.components.country;
        setCity(city);
        setCountry(country);
      } else {
        toast("Location not found", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
      }
    } catch (error) {
      // toast("Error fetching location data", {
      //   autoClose: 1500,
      //   position: "bottom-right",
      //   hideProgressBar: true,
      //   className: "rounded-4xl custom-toast error-toast",
      // });
    }
  };

  useEffect(() => {
    if (position.length > 0) {
      getCityCountry([position[0], position[1]]);
    }
  }, [position]);

  // Location

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      toast("Geolocation is not supported by this browser.", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  const showPosition = async (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setLocation({ latitude, longitude });

    try {
      const response = await axios.get(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
      );
      setCity(response?.data?.city);
      setCountry(response?.data?.countryName);
      setAddress(response?.data?.city);
      setValue("location", response?.data?.city);
      setPosition([latitude, longitude]);
      setIsShowMap(false);
    } catch (error) {
      setIsShowMap(true);
      // toast("Error fetching country name", {
      //   autoClose: 1500,
      //   position: "bottom-right",
      //   hideProgressBar: true,
      //   className: "rounded-4xl custom-toast error-toast",
      // });
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  const { isOpen } = useContext(SidebarContext);

  useEffect(() => {}, [isOpen]);

  useEffect(() => {}, [isShowMap]);

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setCity("");
        setCountry("");
        setAddress("");
        setPosition([0, 0]);
        setValue("location", "");
        break;
      case error.POSITION_UNAVAILABLE:
        setCity("");
        setCountry("");
        setAddress("");
        setPosition([0, 0]);
        setValue("location", "");
        toast("Location information is unavailable.", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
        break;
      case error.TIMEOUT:
        setCity("");
        setCountry("");
        setAddress("");
        setPosition([0, 0]);
        setValue("location", "");
        toast("The request to get user location timed out.", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
        break;
      case error.UNKNOWN_ERROR:
        setCity("");
        setCountry("");
        setAddress("");
        setPosition([0, 0]);
        setValue("location", "");
        toast("An unknown error occurred.", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
        break;
      default:
        setCity("");
        setCountry("");
        setAddress("");
        setPosition([0, 0]);
        setValue("location", "");
        toast("An unknown error occurred.", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
        break;
    }
  };

  const { width } = useSize();

  if (width === 0) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(addFrond)}>
      <div className="flex items-center gap-6 mb-6 lg:flex-nowrap flex-wrap">
        <div className="xl:min-w-[calc(68%-24px)] xl:max-w-[calc(68%-24px)] lg:w-1/2 w-full smallPhone:min-h-[289.6px] smallPhone:max-h-[289.6px] min-h-[180px] max-h-[180px] create-img-input">
          <FilePond
            files={files}
            allowReorder={true}
            allowMultiple={false}
            allowFileEncode={false}
            onupdatefiles={setFiles}
            onpreparefile={(file, output) => {
              setUploadedFile(output);
            }}
            imageResizeTargetWidth={300}
            imageResizeTargetHeight={300}
            imageResizeUpscale={false}
            imageResizeMode="force"
            acceptedFileTypes={[
              "image/png",
              "image/jpeg",
              "image/gif",
              "image/bmp",
              "image/tiff",
              "image/webp",
            ]}
            name="file"
            allowImageEdit={false}
            labelIdle={imgTitle}
          />
        </div>
        {width >= 1024 && (
          <div className="min-h-[224px] relative xl:min-w-[32%] xl:max-w-[32%] lg:w-1/2 w-full">
            <MapContainer
              center={position}
              zoom={12}
              dragging={false}
              doubleClickZoom={false}
              zoomControl={false}
              scrollWheelZoom={false}
              style={{
                height: "289.6px",
                width: "100%",
                position: "relative",
                zIndex: "20",
              }}
              whenCreated={(map) => {
                map.on("load", handleMapLoad);
              }}
            >
              <TileLayer
                eventHandlers={{
                  tileload: handleTileLoad,
                }}
                attribution='<a href="https://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://tile.jawg.io/jawg-lagoon/{z}/{x}/{y}{r}.png?access-token=bGzRVT1WzXv8lPpb0ecTZTmZC0ZK2AIyJA2YMyPE5Dp1FNFqlX4sVyI6PSbA0iMH"
              />
              <CustomMarker />
            </MapContainer>
            {!mapLoaded && (
              <div
                className={`bg-gray-400 rounded-[20px] absolute inset-0 transition-all duration-300 flex justify-center items-center ${
                  mapLoaded ? "opacity-0 z-10" : "opacity-100 z-30"
                }`}
              >
                <span className="flex gap-2 justify-center items-center text-white">
                  <i className="fa-solid fa-spinner fa-spin text-[30px]"></i>
                </span>
              </div>
            )}
            {isShowMap ? (
              <div
                className={`bg-gray-400 absolute inset-0 transition-all duration-300 rounded-[20px] ${
                  isShowMap ? "opacity-100 z-30" : "opacity-0 z-10"
                }`}
              >
                <Image
                  classNames={{ wrapper: "w-full h-full" }}
                  src={tempMap}
                  alt="Map"
                  className="size-full"
                />
              </div>
            ) : null}
          </div>
        )}
      </div>

      <div
        className={`grid xl:grid-cols-3 lg:grid-cols-2 gap-6 ${
          errors.title?.message ||
          errors.dateRange?.message ||
          errors.location?.message
            ? "mb-10"
            : "mb-6"
        } items-start`}
      >
        <Controller
          name="title"
          control={control}
          rules={{
            required: "Title is required",
            minLength: {
              value: 3,
              message: "Minimum number of characters is 3",
            },
            maxLength: {
              value: 100,
              message: "Maximum number of characters is 100",
            },
            validate: {
              noTrailingSpace: (value) =>
                !/\s$/.test(value) || "Title shouldn't end with a space",
            },
            pattern: {
              value:
                // /^[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+[\sa-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+$/gi,
                /^(?! )[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=\/\\\:]+(?!.*\s{2})[a-zA-Z0-9\-\/\\\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=\: ]*$/gi,
              message:
                "Title may contain characters, numbers and special characters",
            },
          }}
          render={({ field }) => (
            <Input
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              {...field}
              type="text"
              variant="bordered"
              label="Title"
              placeholder="Title here..."
              labelPlacement="outside"
              classNames={{
                base: "input-noMargin h-[68px]",
                label:
                  "text-textColor font-normal smallPhone:text-sm text-[12px]",
                input: "border-none px-[18px] smallPhone:text-sm text-[10px]",
                mainWrapper: "h-[42px]",
                innerWrapper: "h-[42px]",
                inputWrapper: `border-[0.86px] p-0 border-solid ${
                  errors.title?.message ? "invalid-text" : "border-[#E5E4EB]"
                } rounded-[8px] h-[42px]`,
              }}
              isInvalid={errors.title?.message ? true : false}
              errorMessage={errors.title?.message}
            />
          )}
        />

        {width >= 1280 && (
          <Controller
            name="dateRange"
            control={control}
            render={({ field }) => (
              <DateRangePicker
                {...field}
                variant="bordered"
                labelPlacement="outside"
                visibleMonths={2}
                selectorIcon={
                  <Image src={calendar} className="w-[18px] h-[18px]" />
                }
                label={
                  <span dangerouslySetInnerHTML={{ __html: labelDateHtml }} />
                }
                isInvalid={errors.dateRange?.message ? true : false}
                errorMessage={errors.dateRange?.message}
                classNames={{
                  base: "mt-0 h-[68px]",
                  label: "text-textColor font-normal text-sm",
                  inputWrapper:
                    "border-[0.86px] p-0 border-solid border-[#E5E4EB] rounded-[8px] min-h-[42px] flex-row-reverse date-box",
                  innerWrapper: "px-[18px] h-full no-action",
                  segment: "date-picker-frond text-sm font-normal",
                  selectorButton:
                    "order-first min-w-[18px] max-h-[18px] h-[18px] me-1",
                  separator:
                    "mx-0 date-picker-separator text-sm font-normal no-action",
                }}
              />
            )}
          />
        )}

        {width >= 1024 && (
          <Controller
            name="location"
            control={control}
            rules={{
              validate: {
                noTrailingSpace: (value) =>
                  !/\s$/.test(value) || "Location shouldn't end with a space",
              },
              pattern: {
                value:
                  // /^[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+[\sa-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+$/gi,
                  /^(?! )[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=\/\\\:]+(?!.*\s{2})[a-zA-Z0-9\-\/\\\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=\: ]*$/gi,
                message:
                  "Location may contain characters, numbers and special characters",
              },
            }}
            render={({ field }) => (
              <div className="location-wrapper relative group">
                <div className="absolute left-0 right-0 top-[-10px] z-30 flex justify-center items-center bg-white rounded-2xl p-[5px] border-1 border-solid border-grayBorder transition-all duration-300 opacity-0 group-hover:opacity-100">
                  <span className="text-[13.8px] font-normal text-textColor">
                    Location may contain country, city, town or postcode
                  </span>
                </div>
                <Input
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  {...field}
                  type="text"
                  variant="bordered"
                  placeholder="Search Location"
                  labelPlacement="outside"
                  onInput={handleInputChange}
                  startContent={
                    <Image
                      src={locationIcon}
                      className="min-w-[18px] max-w-[18px] h-[18px] me-1"
                    />
                  }
                  classNames={{
                    base: "input-noMargin h-[68px] location-field",
                    label: "text-textColor font-normal text-sm",
                    input: "border-none px-[18px]",
                    mainWrapper: "h-[42px]",
                    innerWrapper: "h-[42px] px-[18px]",
                    inputWrapper: `border-[0.86px] p-0 border-solid ${
                      errors.location?.message
                        ? "invalid-text"
                        : "border-[#E5E4EB]"
                    } rounded-[8px] h-[42px]`,
                  }}
                  label={
                    <span dangerouslySetInnerHTML={{ __html: labelHtml }} />
                  }
                  isInvalid={errors.location?.message ? true : false}
                  errorMessage={errors.location?.message}
                />
              </div>
            )}
          />
        )}
      </div>

      <div
        className={`grid xl:grid-cols-3 lg:grid-cols-2 gap-6 ${
          errors.category?.message ||
          errors.budget?.message ||
          errors.currency?.message
            ? "mb-10"
            : "mb-6"
        }`}
      >
        <Controller
          name="category"
          control={control}
          rules={{
            required: "Category is required",
          }}
          render={({ field }) => (
            <Select
              {...field}
              variant="bordered"
              label="Category"
              labelPlacement="outside"
              selectedKeys={field.value ? [field.value] : []}
              placeholder="Select Category"
              classNames={{
                base: "input-noMargin h-[68px] custom-input-value",
                label:
                  "text-textColor font-normal smallPhone:text-sm text-[12px]",
                mainWrapper: "h-[42px]",
                innerWrapper: "h-[42px] px-[18px]",
                trigger: `p-0 h-[42px] border-[0.86px] border-solid ${
                  errors.location?.message ? "invalid-text" : "border-[#E5E4EB]"
                } rounded-[8px] flex items-center justify-between`,
                value:
                  "text-cardGrayColor smallPhone:text-sm text-[10px] font-normal",
                selectorIcon:
                  "smallPhone:w-[18px] smallPhone:h-[18px] w-[12px] h-[12px]",
              }}
              selectorIcon={<img src={up} />}
              isInvalid={errors.category?.message ? true : false}
              errorMessage={errors.category?.message}
            >
              {categories.map((item) => (
                <SelectItem key={item}>{item}</SelectItem>
              ))}
            </Select>
          )}
        />

        {width >= 1280 && (
          <div className={`flex items-center 2xl:gap-6 xl:gap-[10px]`}>
            <Controller
              name="budget"
              control={control}
              rules={{
                pattern: {
                  value: /^[1-9][0-9]*(\.[0-9]+)?$/,
                  message: "Invalid Budget",
                },
              }}
              render={({ field }) => (
                <Input
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  className="min-w-[140px] max-w-[140px]"
                  {...field}
                  type="text"
                  variant="bordered"
                  placeholder="0"
                  labelPlacement="outside"
                  classNames={{
                    base: "input-noMargin h-[68px]",
                    label: "text-textColor font-normal text-sm",
                    input: "border-none px-[18px]",
                    mainWrapper: "h-[42px]",
                    innerWrapper: "h-[42px] px-[18px]",
                    inputWrapper: `border-[0.86px] p-0 border-solid ${
                      errors.budget?.message
                        ? "invalid-text"
                        : "border-[#E5E4EB]"
                    } rounded-[8px] h-[42px]`,
                  }}
                  isInvalid={errors.budget?.message ? true : false}
                  errorMessage={errors.budget?.message}
                  startContent={
                    <Image
                      src={euro}
                      className="min-w-[18px] max-w-[18px] h-[18px] me-1"
                    />
                  }
                  label={
                    <span
                      dangerouslySetInnerHTML={{ __html: labelBudgetHtml }}
                    />
                  }
                />
              )}
            />

            <Controller
              name="currency"
              control={control}
              render={({ field }) => (
                <Select
                  className="w-[100%]"
                  {...field}
                  variant="bordered"
                  labelPlacement="outside"
                  selectedKeys={field.value ? [field.value] : []}
                  placeholder="Currency"
                  classNames={{
                    base: "input-noMargin h-[68px] custom-input-value",
                    label: "text-textColor font-normal text-sm",
                    mainWrapper: "h-[42px]",
                    innerWrapper: "h-[42px] px-[18px]",
                    trigger: `p-0 h-[42px] border-[0.86px] border-solid ${
                      errors.location?.message
                        ? "invalid-text"
                        : "border-[#E5E4EB]"
                    } rounded-[8px] flex items-center justify-between`,
                    value: "text-cardGrayColor text-sm font-normal",
                    selectorIcon: "w-[18px] h-[18px]",
                  }}
                  selectorIcon={<img src={up} />}
                  isInvalid={errors.currency?.message ? true : false}
                  errorMessage={errors.currency?.message}
                  startContent={
                    <Image
                      src={euro}
                      className="min-w-[18px] max-w-[18px] h-[18px] me-1"
                    />
                  }
                  label={
                    <span
                      dangerouslySetInnerHTML={{ __html: labelCurrencyHtml }}
                    />
                  }
                >
                  {currenciesList.map((item) => (
                    <SelectItem key={item?.currency_code}>
                      {item?.currency_code}
                    </SelectItem>
                  ))}
                </Select>
              )}
            />
          </div>
        )}

        {width >= 1024 && (
          <div className="flex flex-col justify-between">
            <span className="text-textColor font-normal text-sm">
              Importance{" "}
              <span className="text-xs text-cardGrayColor">(Optional)</span>
            </span>
            <div className="flex gap-2 items-center h-[32px]">
              <button
                type="button"
                className={`importanceBtn transition-all duration-300 text-[15.54px] font-normal h-full w-1/3 rounded-l-[20px] flex justify-center items-center ${
                  importance === "High"
                    ? "bg-[#FF3D00E5] opacity-90 shadow-importanceShadow border-2 border-solid border-[#FF3D00E5]"
                    : "border-2 border-solid border-[#FF3D00E5]"
                }`}
                onClick={() => {
                  if (importance === "High") {
                    setImportance(null);
                  } else {
                    setImportance("High");
                  }
                }}
              >
                <span
                  className={`transition-all duration-300 opacity-100 ${
                    importance === "High" ? "text-white" : "text-[#FF3D00E5]"
                  }`}
                >
                  High
                </span>
              </button>
              <button
                type="button"
                className={`importanceBtn transition-all duration-300 text-[15.54px] font-normal h-full w-1/3 flex justify-center items-center ${
                  importance === "Medium"
                    ? "bg-[#FFC000] opacity-90 shadow-importanceShadow border-2 border-solid border-[#FFC000]"
                    : "border-2 border-solid border-[#FFC000]"
                }`}
                onClick={() => {
                  if (importance === "Medium") {
                    setImportance(null);
                  } else {
                    setImportance("Medium");
                  }
                }}
              >
                <span
                  className={`transition-all duration-300 opacity-100 ${
                    importance === "Medium" ? "text-white" : "text-[#FFC000]"
                  }`}
                >
                  Medium
                </span>
              </button>
              <button
                type="button"
                className={`importanceBtn transition-all duration-300 text-[15.54px] font-normal h-full w-1/3 rounded-r-[20px] flex justify-center items-center ${
                  importance === "Low"
                    ? "bg-[#5CCDB0] opacity-90 shadow-importanceShadow border-2 border-solid border-[#5CCDB0]"
                    : "border-2 border-solid border-[#5CCDB0]"
                }`}
                onClick={() => {
                  if (importance === "Low") {
                    setImportance(null);
                  } else {
                    setImportance("Low");
                  }
                }}
              >
                <span
                  className={`transition-all duration-300 opacity-100 ${
                    importance === "Low" ? "text-white" : "text-[#5CCDB0]"
                  }`}
                >
                  Low
                </span>
              </button>
            </div>
          </div>
        )}
      </div>

      <div
        className={`sm:grid flex flex-col xl:grid-cols-3 lg:grid-cols-2 gap-6 ${
          width < 1280 && "mb-6"
        }`}
      >
        <div>
          <Controller
            name="desc"
            control={control}
            rules={{
              minLength: {
                value: 2,
                message: "Minimum number of characters is 2",
              },
              maxLength: {
                value: 255,
                message: "Maximum number of characters is 255",
              },
              pattern: {
                value:
                  /^[a-zA-Z0-9\-\_\.\,\!\@\#\$\%\^\&\*\(\)\+\=\'\"\/\\\:]+(\s[A-Za-z0-9\-\/\\\_\.\,\!\@\#\$\%\^\&\*\(\)\+\=\'\"\:]+)*$/gi,
                message:
                  "Description may contain characters, numbers or special characters and shouldn't end with space",
              },
            }}
            render={({ field }) => (
              <Textarea
                {...field}
                onInput={(e) => setDescLength(e.target.value.length)}
                variant="bordered"
                maxLength={255}
                placeholder="Text here..."
                labelPlacement="outside"
                classNames={{
                  base: "input-noMargin",
                  label:
                    "text-textColor font-normal smallPhone:text-sm text-[12px]",
                  input:
                    "px-[18px] py-[12px] border-none create-frond-desc smallPhone:text-sm text-[10px]",
                  innerWrapper: "p-0 min-h-[116px] max-h-[116px]",
                  inputWrapper: `border-[0.86px] p-0 border-solid ${
                    errors.title?.desc ? "invalid-text" : "border-[#E5E4EB]"
                  } rounded-[8px] min-h-[116px] max-h-[116px]`,
                }}
                label={
                  <span dangerouslySetInnerHTML={{ __html: labelDescHtml }} />
                }
                isInvalid={errors.desc?.message ? true : false}
                errorMessage={errors.desc?.message}
              />
            )}
          />
          {!errors.desc?.message && 255 - descLength > 0 ? (
            <p className="text-[.75rem] text-mainBlue mt-1">
              {255 - descLength} remaining characters
            </p>
          ) : null}
        </div>

        {width >= 1280 && (
          <>
            <Controller
              name="tags"
              control={control}
              rules={{ validate: handleValidation }}
              render={({ field }) => (
                <div>
                  <label
                    className={`block ${
                      // || tagLimit (if there are tag limit enter that after tagError)
                      tagError ? "text-[#f31260]" : "text-textColor"
                    } text-sm font-normal mb-2 transition-all duration-400`}
                    htmlFor="tags"
                  >
                    Tags{" "}
                    <span className="text-xs text-cardGrayColor">
                      (Optional)
                    </span>
                  </label>
                  <ReactTags
                    autoFocus={false}
                    {...field}
                    id="tags"
                    tags={tags}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    allowDragDrop={false}
                    inputFieldPosition="top"
                    separators={["Enter", "Comma"]}
                    placeholder="Add Tags ..."
                    // maxTags={10}
                    removeComponent={RemoveComponent}
                    classNames={{
                      // || tagLimit (if there are tag limit enter that after tagError)
                      tagInputField: `tagInputField bg-white border-[0.86px] w-full px-[18px] border-solid ${
                        tagError ? "invalid-text" : "border-[#E5E4EB]"
                      } rounded-[8px] h-[42px] placeholder:text-sm placeholder:font-normal text-sm font-normal transition-all duration-400 no-focus`,
                      tag: `px-2.5 py-[6px] bg-[#1976D2] rounded-[20px] inline-flex text-white text-sm items-center justify-center gap-1.5 mt-2 me-2
                    `,
                      editTagInput: "flex",
                    }}
                  />
                  {tagError && (
                    <p
                      className={`text-[#f31260] text-xs mt-0.5 transition-opacity duration-400 ${
                        tagError ? "opacity-100" : "opacity-0"
                      }`}
                    >
                      {tagError}
                    </p>
                  )}
                  {/* {tagLimit && (
                <p
                  className={`text-[#f31260] text-xs mt-1 transition-opacity duration-400 ${
                    tagLimit ? "opacity-100" : "opacity-0"
                  }`}
                >
                  Tag limit reached
                </p>
              )} */}
                </div>
              )}
            />

            <div className="actions flex items-center gap-2 self-end justify-end">
              <button
                type="button"
                className="py-3 px-[30px] rounded-4xl bg-transparent gradiantBorder h-[42px] w-[110px] flex justify-center items-center"
                onClick={() => {
                  discardFrond();
                }}
              >
                <span className="bg-clip-text text-transparent bg-mainGradiant text-base font-bold">
                  Discard
                </span>
              </button>
              <button
                disabled={isDisabled}
                type="submit"
                className="py-3 px-[30px] rounded-4xl text-white bg-mainGradiant text-base font-bold h-[42px] w-[110px] flex justify-center items-center"
              >
                {isLoading ? (
                  <span className="flex justify-center items-center text-white">
                    <i className="fa-solid fa-spinner fa-spin"></i>
                  </span>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </>
        )}

        {width < 1280 && (
          <div className="flex flex-col gap-[6px]">
            <Controller
              name="dateRange"
              control={control}
              render={({ field }) => (
                <DateRangePicker
                  {...field}
                  variant="bordered"
                  labelPlacement="outside"
                  visibleMonths={width >= 640 ? 2 : 1}
                  selectorIcon={
                    <Image
                      src={calendar}
                      className="smallPhone:w-[18px] smallPhone:h-[18px] w-[12px] h-[12px]"
                    />
                  }
                  label={
                    <span dangerouslySetInnerHTML={{ __html: labelDateHtml }} />
                  }
                  isInvalid={errors.dateRange?.message ? true : false}
                  errorMessage={errors.dateRange?.message}
                  classNames={{
                    base: "mt-0 h-[68px] lg:mb-0 mb-6",
                    label:
                      "text-textColor font-normal smallPhone:text-sm text-[12px]",
                    inputWrapper:
                      "border-[0.86px] p-0 border-solid border-[#E5E4EB] rounded-[8px] min-h-[42px] flex-row-reverse date-box",
                    innerWrapper:
                      "smallPhone:px-[18px] px-[12px] h-full no-action",
                    segment:
                      "date-picker-frond smallPhone:text-sm text-[10px] font-normal",
                    selectorButton:
                      "order-first smallPhone:min-w-[18px] min-w-[12px] smallPhone:max-h-[18px] smallPhone:h-[18px] max-h-[12px] h-[12px] smallPhone:me-1 me-[2px]",
                    separator:
                      "mx-0 date-picker-separator text-sm font-normal no-action",
                  }}
                />
              )}
            />

            <div className={`flex items-center sm:flex-nowrap flex-wrap gap-6`}>
              <Controller
                name="budget"
                control={control}
                rules={{
                  pattern: {
                    value: /^[1-9][0-9]*(\.[0-9]+)?$/,
                    message: "Invalid Budget",
                  },
                }}
                render={({ field }) => (
                  <Input
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    className="lg:min-w-[140px] lg:max-w-[140px] sm:w-[50%] w-full lg:mb-0"
                    {...field}
                    type="text"
                    variant="bordered"
                    placeholder="0"
                    labelPlacement="outside"
                    classNames={{
                      base: "input-noMargin h-[68px]",
                      label:
                        "text-textColor font-normal smallPhone:text-sm text-[12px]",
                      input:
                        "border-none px-[18px] smallPhone:text-base text-[10px]",
                      mainWrapper: "h-[42px]",
                      innerWrapper: "h-[42px] px-[18px]",
                      inputWrapper: `border-[0.86px] p-0 border-solid ${
                        errors.budget?.message
                          ? "invalid-text"
                          : "border-[#E5E4EB]"
                      } rounded-[8px] h-[42px]`,
                    }}
                    isInvalid={errors.budget?.message ? true : false}
                    errorMessage={errors.budget?.message}
                    startContent={
                      <Image
                        src={euro}
                        className="smallPhone:min-w-[18px] smallPhone:max-w-[18px] smallPhone:h-[18px] smallPhone:me-1 min-w-[12px] max-w-[12px] h-[12px] me-[4px]"
                      />
                    }
                    label={
                      <span
                        dangerouslySetInnerHTML={{ __html: labelBudgetHtml }}
                      />
                    }
                  />
                )}
              />

              <Controller
                name="currency"
                control={control}
                render={({ field }) => (
                  <Select
                    className="lg:w-[100%] sm:w-[50%] w-full"
                    {...field}
                    variant="bordered"
                    labelPlacement="outside"
                    selectedKeys={field.value ? [field.value] : []}
                    placeholder="Currency"
                    classNames={{
                      base: "input-noMargin h-[68px] custom-input-value",
                      label:
                        "text-textColor font-normal smallPhone:text-sm text-[12px]",
                      mainWrapper: "h-[42px]",
                      innerWrapper: "h-[42px] px-[18px]",
                      trigger: `p-0 h-[42px] border-[0.86px] border-solid ${
                        errors.location?.message
                          ? "invalid-text"
                          : "border-[#E5E4EB]"
                      } rounded-[8px] flex items-center justify-between`,
                      value:
                        "text-cardGrayColor smallPhone:text-sm text-[10px] font-normal",
                      selectorIcon:
                        "smallPhone:w-[18px] smallPhone:h-[18px] w-[12px] h-[12px]",
                    }}
                    selectorIcon={<img src={up} />}
                    isInvalid={errors.currency?.message ? true : false}
                    errorMessage={errors.currency?.message}
                    startContent={
                      <Image
                        src={euro}
                        className="smallPhone:min-w-[18px] smallPhone:max-w-[18px] smallPhone:h-[18px] smallPhone:me-1 min-w-[12px] max-w-[12px] h-[12px] me-[4px]"
                      />
                    }
                    label={
                      <span
                        dangerouslySetInnerHTML={{ __html: labelCurrencyHtml }}
                      />
                    }
                  >
                    {currenciesList.map((item) => (
                      <SelectItem key={item?.currency_code}>
                        {item?.currency_code}
                      </SelectItem>
                    ))}
                  </Select>
                )}
              />
            </div>
          </div>
        )}
      </div>

      {width < 1280 && (
        <div className="grid lg:grid-cols-2 gap-6 lg:mb-0 mb-6">
          <Controller
            name="tags"
            control={control}
            rules={{ validate: handleValidation }}
            render={({ field }) => (
              <div>
                <label
                  className={`block ${
                    // || tagLimit (if there are tag limit enter that after tagError)
                    tagError ? "text-[#f31260]" : "text-textColor"
                  } smallPhone:text-sm text-[12px] font-normal mb-2 transition-all duration-400`}
                  htmlFor="tags"
                >
                  Tags{" "}
                  <span className="smallPhone:text-xs text-[10px] text-cardGrayColor">
                    (Optional)
                  </span>
                </label>
                <ReactTags
                  autoFocus={false}
                  {...field}
                  id="tags"
                  tags={tags}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  allowDragDrop={false}
                  inputFieldPosition="top"
                  separators={["Enter", "Comma"]}
                  placeholder="Add Tags ..."
                  // maxTags={10}
                  removeComponent={RemoveComponent}
                  classNames={{
                    // || tagLimit (if there are tag limit enter that after tagError)
                    tagInputField: `tagInputField bg-white border-[0.86px] w-full px-[18px] border-solid ${
                      tagError ? "invalid-text" : "border-[#E5E4EB]"
                    } rounded-[8px] h-[42px] placeholder:smallPhone:text-sm placeholder:smallPhone:text-[10px] placeholder:font-normal smallPhone:text-sm text-[10px] font-normal transition-all duration-400 no-focus`,
                    tag: `px-2.5 py-[6px] bg-[#1976D2] rounded-[20px] inline-flex text-white smallPhone:text-sm text-[10px] items-center justify-center gap-1.5 mt-2 me-2
                    `,
                    editTagInput: "flex",
                  }}
                />
                {tagError && (
                  <p
                    className={`text-[#f31260] text-xs mt-0.5 transition-opacity duration-400 ${
                      tagError ? "opacity-100" : "opacity-0"
                    }`}
                  >
                    {tagError}
                  </p>
                )}
                {/* {tagLimit && (
                <p
                  className={`text-[#f31260] text-xs mt-1 transition-opacity duration-400 ${
                    tagLimit ? "opacity-100" : "opacity-0"
                  }`}
                >
                  Tag limit reached
                </p>
              )} */}
              </div>
            )}
          />

          {width >= 1024 && (
            <div className="actions flex items-center gap-2 self-end justify-end">
              <button
                type="button"
                className="py-3 px-[30px] rounded-4xl bg-transparent gradiantBorder h-[42px] w-[110px] flex justify-center items-center"
                onClick={() => {
                  discardFrond();
                }}
              >
                <span className="bg-clip-text text-transparent bg-mainGradiant text-base font-bold">
                  Discard
                </span>
              </button>
              <button
                disabled={isDisabled}
                type="submit"
                className="py-3 px-[30px] rounded-4xl text-white bg-mainGradiant text-base font-bold h-[42px] w-[110px] flex justify-center items-center"
              >
                {isLoading ? (
                  <span className="flex justify-center items-center text-white">
                    <i className="fa-solid fa-spinner fa-spin"></i>
                  </span>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          )}
        </div>
      )}

      {width < 1024 && (
        <div className="smallPhone:min-h-[224px] min-h-[180px] smallPhone:max-h-[224px] max-h-[180px] relative xl:min-w-[32%] xl:max-w-[32%] lg:w-1/2 w-full mb-6">
          <MapContainer
            center={position}
            zoom={12}
            dragging={false}
            doubleClickZoom={false}
            zoomControl={false}
            scrollWheelZoom={false}
            style={{
              height: "289.6px",
              width: "100%",
              position: "relative",
              zIndex: "20",
            }}
            whenCreated={(map) => {
              map.on("load", handleMapLoad);
            }}
          >
            <TileLayer
              eventHandlers={{
                tileload: handleTileLoad,
              }}
              attribution='<a href="https://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://tile.jawg.io/jawg-lagoon/{z}/{x}/{y}{r}.png?access-token=bGzRVT1WzXv8lPpb0ecTZTmZC0ZK2AIyJA2YMyPE5Dp1FNFqlX4sVyI6PSbA0iMH"
            />
            <CustomMarker />
          </MapContainer>
          {!mapLoaded && (
            <div
              className={`bg-gray-400 rounded-[20px] absolute inset-0 transition-all duration-300 flex justify-center items-center ${
                mapLoaded ? "opacity-0 z-10" : "opacity-100 z-30"
              }`}
            >
              <span className="flex gap-2 justify-center items-center text-white">
                <i className="fa-solid fa-spinner fa-spin text-[30px]"></i>
              </span>
            </div>
          )}
          {isShowMap ? (
            <div
              className={`bg-gray-400 absolute inset-0 transition-all duration-300 rounded-[20px] ${
                isShowMap ? "opacity-100 z-30" : "opacity-0 z-10"
              }`}
            >
              <Image
                classNames={{ wrapper: "w-full h-full" }}
                src={tempMap}
                alt="Map"
                className="size-full"
              />
            </div>
          ) : null}
        </div>
      )}

      {width < 1024 && (
        <Controller
          name="location"
          control={control}
          rules={{
            validate: {
              noTrailingSpace: (value) =>
                !/\s$/.test(value) || "Location shouldn't end with a space",
            },
            pattern: {
              value:
                // /^[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+[\sa-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+$/gi,
                /^(?! )[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=\/\\\:]+(?!.*\s{2})[a-zA-Z0-9\-\/\\\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=\: ]*$/gi,
              message:
                "Location may contain characters, numbers and special characters",
            },
          }}
          render={({ field }) => (
            <div className="location-wrapper relative group mb-6">
              <div className="absolute left-0 right-0 top-[-10px] z-30 flex justify-center items-center bg-white rounded-2xl p-[5px] border-1 border-solid border-grayBorder transition-all duration-300 opacity-0 group-hover:opacity-100">
                <span className="smallPhone:text-[13.8px] text-[12px] font-normal text-textColor">
                  Location may contain country, city, town or postcode
                </span>
              </div>
              <Input
                onKeyDown={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
                {...field}
                type="text"
                variant="bordered"
                placeholder="Search Location"
                labelPlacement="outside"
                onInput={handleInputChange}
                startContent={
                  <Image
                    src={locationIcon}
                    className="smallPhone:min-w-[18px] smallPhone:max-w-[18px] smallPhone:h-[18px] min-w-[12px] max-w-[12px] h-[12px] me-1"
                  />
                }
                classNames={{
                  base: "input-noMargin h-[68px] location-field",
                  label:
                    "text-textColor font-normal smallPhone:text-sm text-[12px]",
                  input:
                    "border-none px-[18px] smallPhone:text-base text-[10px]",
                  mainWrapper: "h-[42px]",
                  innerWrapper: "h-[42px] px-[18px]",
                  inputWrapper: `border-[0.86px] p-0 border-solid ${
                    errors.location?.message
                      ? "invalid-text"
                      : "border-[#E5E4EB]"
                  } rounded-[8px] h-[42px]`,
                }}
                label={<span dangerouslySetInnerHTML={{ __html: labelHtml }} />}
                isInvalid={errors.location?.message ? true : false}
                errorMessage={errors.location?.message}
              />
            </div>
          )}
        />
      )}

      {width < 1024 && (
        <div className="flex flex-col justify-between mb-6">
          <span className="text-textColor font-normal smallPhone:text-sm text-[12px] mb-2">
            Importance{" "}
            <span className="smallPhone:text-xs text-[10px] text-cardGrayColor">
              (Optional)
            </span>
          </span>
          <div className="flex gap-2 items-center h-[32px]">
            <button
              type="button"
              className={`importanceBtn transition-all duration-300 smallPhone:text-[15.54px] text-[12px] font-normal h-full w-1/3 rounded-l-[20px] flex justify-center items-center ${
                importance === "High"
                  ? "bg-[#FF3D00E5] opacity-90 shadow-importanceShadow border-2 border-solid border-[#FF3D00E5]"
                  : "border-2 border-solid border-[#FF3D00E5]"
              }`}
              onClick={() => {
                if (importance === "High") {
                  setImportance(null);
                } else {
                  setImportance("High");
                }
              }}
            >
              <span
                className={`transition-all duration-300 opacity-100 ${
                  importance === "High" ? "text-white" : "text-[#FF3D00E5]"
                }`}
              >
                High
              </span>
            </button>
            <button
              type="button"
              className={`importanceBtn transition-all duration-300 smallPhone:text-[15.54px] text-[12px] font-normal h-full w-1/3 flex justify-center items-center ${
                importance === "Medium"
                  ? "bg-[#FFC000] opacity-90 shadow-importanceShadow border-2 border-solid border-[#FFC000]"
                  : "border-2 border-solid border-[#FFC000]"
              }`}
              onClick={() => {
                if (importance === "Medium") {
                  setImportance(null);
                } else {
                  setImportance("Medium");
                }
              }}
            >
              <span
                className={`transition-all duration-300 opacity-100 ${
                  importance === "Medium" ? "text-white" : "text-[#FFC000]"
                }`}
              >
                Medium
              </span>
            </button>
            <button
              type="button"
              className={`importanceBtn transition-all duration-300 smallPhone:text-[15.54px] text-[12px] font-normal h-full w-1/3 rounded-r-[20px] flex justify-center items-center ${
                importance === "Low"
                  ? "bg-[#5CCDB0] opacity-90 shadow-importanceShadow border-2 border-solid border-[#5CCDB0]"
                  : "border-2 border-solid border-[#5CCDB0]"
              }`}
              onClick={() => {
                if (importance === "Low") {
                  setImportance(null);
                } else {
                  setImportance("Low");
                }
              }}
            >
              <span
                className={`transition-all duration-300 opacity-100 ${
                  importance === "Low" ? "text-white" : "text-[#5CCDB0]"
                }`}
              >
                Low
              </span>
            </button>
          </div>
        </div>
      )}

      {width < 1024 && (
        <div className="actions flex items-center gap-2 self-end justify-center">
          <button
            type="button"
            className="smallPhone:py-3 py-[6px] smallPhone:px-[30px] px-2.5 rounded-4xl bg-transparent gradiantBorder smallPhone:h-[42px] h-[31px] smallPhone:w-[110px] w-[65px] flex justify-center items-center"
            onClick={() => {
              discardFrond();
            }}
          >
            <span className="bg-clip-text text-transparent bg-mainGradiant smallPhone:text-base text-[12px] font-bold">
              Discard
            </span>
          </button>
          <button
            disabled={isDisabled}
            type="submit"
            className="smallPhone:py-3 py-[6px] smallPhone:px-[30px] px-2.5 rounded-4xl text-white bg-mainGradiant smallPhone:text-base text-[12px] font-bold smallPhone:h-[42px] h-[31px] smallPhone:w-[110px] w-[65px] flex justify-center items-center"
          >
            {isLoading ? (
              <span className="flex justify-center items-center text-white">
                <i className="fa-solid fa-spinner fa-spin"></i>
              </span>
            ) : (
              "Save"
            )}
          </button>
        </div>
      )}
    </form>
  );
}
