import { Image } from "@nextui-org/react";

import placholderIcon from "../../Assets/frond-details/placholder-icon.svg";

const ShareHeader = ({ cardImg = "", cardTitle = "" }) => {
  return (
    <>
      <div className="frond-title mb-5">
        <div className="card-img relative">
          <div
            className={`absolute inset-0 ${
              cardImg ? "bg-frondDetails" : "bg-textColor"
            } z-20 rounded-[20px]`}
          ></div>
          <div className="absolute top-5 phone:left-5 phone:right-5 left-2.5 right-2.5 flex justify-between items-center z-30 phone:gap-[30px] gap-2.5">
            <div className="flex phone:gap-2.5 gap-[5px] items-center overflow-hidden">
              <span className="phone:text-lg text-[12px] font-bold leading-[20.7px] text-white whitespace-nowrap">
                {cardTitle ? cardTitle : "-"}
              </span>
            </div>
          </div>
          {cardImg ? (
            <div
              className="w-full h-[350px] rounded-[20px] overflow-hidden"
              style={{
                backgroundImage: `url(${cardImg})`,
                backgroundSize: "100%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            />
          ) : (
            <div className="flex justify-center phone:h-[350px] h-[160px] items-center">
              <Image
                classNames={{ wrapper: "z-30" }}
                src={placholderIcon}
                alt={cardTitle}
                className="w-[46px] h-[50px]"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ShareHeader;
