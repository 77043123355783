import React, { useContext, useEffect, useState } from "react";
import closeIcon from "../../../../Assets/popup/cross-square.svg";
import {
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Tab,
  Tabs,
} from "@nextui-org/react";
import RemoveComponent from "../../../../pages/CreateAFrond/RemoveComponent";
import "../../modalStyle.css";
import EditImageLinkTab from "./EditImageLinkTab";
import EditImageTab from "./EditImageTab";
import { useParams } from "react-router-dom";
import { PopUpContext } from "Context/PopUpContext";

const EditScrapbookImgModal = ({ isOpen, onClose, isModalOpen }) => {
  const [activeLink, setActiveLink] = useState("image");
  const { frondId } = useParams();
  const { isAddScrapbookImage, isAddScrapbookUrl, closeUrlImageModal } =
    useContext(PopUpContext);

  useEffect(() => {
    if (closeUrlImageModal) {
      onClose();
    }
  }, [closeUrlImageModal]);

  const handleResize = () => {
    const scrollOutter = document.querySelector(".scroll-outter");
    const scrollInner = document.querySelector(".scroll-inner");

    if (scrollOutter && scrollInner) {
      const scrollOutterWidth = scrollOutter.offsetWidth;
      const scrollInnerWidth = scrollInner.offsetWidth;
      scrollOutter.style.width = `calc(100% + ${
        scrollOutterWidth - scrollInnerWidth
      }px)`;
    }

    const scrollOutter2 = document.querySelector(".scroll-outter2");
    const scrollInner2 = document.querySelector(".scroll-inner2");

    if (scrollOutter2 && scrollInner2) {
      const scrollOutterWidth2 = scrollOutter2.offsetWidth;
      const scrollInnerWidth2 = scrollInner2.offsetWidth;
      scrollOutter2.style.width = `calc(100% + ${
        scrollOutterWidth2 - scrollInnerWidth2
      }px)`;
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [activeLink, isAddScrapbookImage, isAddScrapbookUrl]);

  useEffect(() => {
    if (activeLink) {
      setTimeout(() => {
        handleResize();
      }, 1000);
    }
  }, [activeLink]);

  useEffect(() => {
    if (isAddScrapbookImage) {
      handleResize();
    }
  }, [isAddScrapbookImage]);

  useEffect(() => {
    if (isAddScrapbookUrl) {
      handleResize();
    }
  }, [isAddScrapbookUrl]);

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        handleResize();
      }, 1000);
    }
  }, [isModalOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onClose}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999] overflow-hidden",
        footer:
          "justify-center items-center absolute z-30 left-0 right-0 bottom-0 h-[60px] bg-white",
        body: "px-5 mb-2.5",
        closeButton: "hidden",
        base: "rounded-[20px] p-5",
      }}
    >
      <div>
        <ModalContent>
          {(closeModal) => (
            <>
              <ModalHeader className="flex justify-between gap-2.5 items-center p-0 pb-[15px] pt-[15px] sm:px-5 px-2.5 mb-[15px] relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[0.5px] after:bg-grayBorder">
                <h3 className="text-lg text-textColor font-bold leading-[10.35px]">
                  Add Image
                </h3>
                <button onClick={closeModal} aria-label="Close">
                  <img src={closeIcon} alt="Close" className="w-6 h-6" />
                </button>
              </ModalHeader>
              <Tabs
                selectedKey={activeLink}
                onSelectionChange={setActiveLink}
                variant="underlined"
                aria-label="Tabs"
                classNames={{
                  panel: "px-0",
                  base: "w-full relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[2px] after:bg-grayBorder after:z-[10] no-focus",
                  tabList: "w-full justify-evenly relative z-20",
                  tab: "w-fit",
                  tabContent:
                    "text-cardGrayColor font-normal transition-all duration-100 phone:text-base text-xs leading-[18.4px] group-data-[selected=true]:font-bold group-data-[selected=true]:text-transparent group-data-[selected=true]:bg-mainGradiant group-data-[selected=true]:bg-clip-text",
                  cursor:
                    "bg-mainGradiant z-20 bottom-[-4px] rounded-[6px] h-[2px] w-full",
                }}
              >
                <Tab key={"image"} title={"Add Image"}>
                  <div className="max-h-[60vh] overflow-y-auto scroll-outter">
                    <ModalBody className="w-[100] item-center gap-5 scroll-inner">
                      <EditImageTab frondId={frondId} />
                    </ModalBody>
                  </div>
                </Tab>
                <Tab key={"image-link"} title={"Add Image Link"}>
                  <div className="max-h-[60vh] overflow-y-auto scroll-outter2">
                    <ModalBody className="w-[100%] item-center gap-5 scroll-inner2">
                      <EditImageLinkTab frondId={frondId} />
                    </ModalBody>
                  </div>
                </Tab>
              </Tabs>
            </>
          )}
        </ModalContent>
      </div>
    </Modal>
  );
};

export default EditScrapbookImgModal;
