import { Image, Tab, Tabs } from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import DetailsActions from "../DetailsActions";
import { Link, useLocation, useParams } from "react-router-dom";
import { SettingsContext } from "Context/SettingsContext";
import useSettings from "hooks/useSettings";

const ScrapbookTabs = ({ actions }) => {
  const { frondId } = useParams();
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState(pathname);
  useEffect(() => {
    setActiveLink(pathname.split("/")[3]);
  }, [pathname, activeLink]);

  const { loadSettings } = useSettings({ projectId: frondId });
  const { frondSettings } = useContext(SettingsContext);

  return (
    <div className="tabs flex items-center gap-x-2.5 gap-y-5 justify-between 2xl:flex-nowrap flex-wrap mb-[15px]">
      <Tabs
        selectedKey={activeLink}
        aria-label="Scrapbook Tabs"
        radius="full"
        classNames={{
          tabList:
            "bg-white border-1 border-solid border-grayBorder p-0 w-full",
          tab: "py-[6px] xl:px-[30px] scrapbook-tab",
          tabContent:
            "phone:text-base text-[12px] font-normal leading-[18.4px] text-cardGrayColor",
          base: "xl:w-auto w-full",
        }}
      >
        <Tab
          key="images"
          title="Images"
          as={Link}
          to={`/frond/scrapbook/images/${frondId}`}
        />
        <Tab
          key="files"
          title="Files"
          as={Link}
          to={`/frond/scrapbook/files/${frondId}`}
        />
        <Tab
          key="notes"
          title="Notes"
          as={Link}
          to={`/frond/scrapbook/notes/${frondId}`}
        />
        <Tab
          key="voice-memos"
          title="Voice Memos"
          as={Link}
          to={`/frond/scrapbook/voice-memos/${frondId}`}
        />
        <Tab
          key="links"
          title="Links"
          as={Link}
          to={`/frond/scrapbook/links/${frondId}`}
        />
      </Tabs>
      <DetailsActions actions={actions} />
    </div>
  );
};

export default ScrapbookTabs;
