import useSettings from "hooks/useSettings";
import { createContext, useState } from "react";

export let SettingsContext = createContext();

export default function SettingsContextProvider({ children }) {
    const [frondSettings, setFrondSettings] = useState(false);
    const [isOwner, setIsOwner] = useState(false);

    return (
        <SettingsContext.Provider value={{ frondSettings, setFrondSettings,isOwner, setIsOwner }}>
            {children}
        </SettingsContext.Provider>
    );
}
