import { createContext, useState } from "react";

export let AuthContext = createContext();

export default function AuthContextProvider({ children }) {
  const [token, setToken] = useState(localStorage.getItem("token"));

  //Verify Protected Routes
  const [isVerified, setIsVerified] = useState("unknown");

  //Forget Password Data
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const [forgetOtp, setForgetOtp] = useState("");
  const [forgetEmail, setForgetEmail] = useState("");

  //Register Data
  const [registerFname, setRegisterFname] = useState("");
  const [registerLname, setRegisterLname] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPass, setRegisterPass] = useState("");

  // New Email Data

  const [newEmail, setNewEmail] = useState("");
  const [isMailAdded, setIsMailAdded] = useState("unknown")

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        isVerified,
        setIsVerified,
        isForgetPassword,
        setIsForgetPassword,
        forgetOtp,
        setForgetOtp,
        forgetEmail,
        setForgetEmail,
        registerFname,
        setRegisterFname,
        registerLname,
        setRegisterLname,
        registerEmail,
        setRegisterEmail,
        registerPass,
        setRegisterPass,
        newEmail,
        setNewEmail,
        isMailAdded,
        setIsMailAdded
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
