import { useEffect, useState } from "react";

function useTypewriter(text = "", speed = 50, flag = false) {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    if (!text || text.length === 0) return;

    let currentIndex = flag ? -1 : 0;
    setDisplayedText(""); // Reset displayed text
    const interval = setInterval(() => {
      if (currentIndex !== text.length - 1) {
        setDisplayedText((prev) => prev + text[currentIndex]);
        currentIndex++;
      } else {
        
        clearInterval(interval);
      }
    }, speed);

    return () => clearInterval(interval);
  }, [text, speed]);

  return displayedText;
}

export default useTypewriter;
