import React, { useContext, useEffect, useState } from "react";
import addIcon from "../../../Assets/filter/plus-squared.svg";
import crownIcon from "../../../Assets/people/crown.svg";
import loadingIcon from "../../../Assets/people/loading.svg";
import userIcon from "../../../Assets/people/user.svg";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
} from "@nextui-org/react";
import dots from "../../../Assets/people/dots-vertical.svg";
import InvitePeople from "./InvitePeople";
import { useParams } from "react-router-dom";
import { PopUpContext } from "Context/PopUpContext";
import useMembers from "../../../hooks/useMembers"; // Import the custom hook
import DataLoader from "pages/DataLoader/DataLoader";
import axios from "axios";
import { toast } from "react-toastify";
import useSettings from "hooks/useSettings";
import { SettingsContext } from "Context/SettingsContext";
import ChangeAccessModal from "./ChangeAccessModal";

const People = () => {
  const { frondId } = useParams();
  const { isAddMember } = useContext(PopUpContext);

  const [invitePeopleModal, setInvitePeopleModal] = useState(false);
  const [targetId, setTargetId] = useState(null);
  const [itemKey, setItemKey] = useState(null);

  // Use the custom hook
  const authToken = localStorage.getItem("token");
  const { members, isLoading, getMembers } = useMembers(frondId, authToken);
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const [accessType, setAccessType] = useState(null);

  useEffect(() => {
    if (isAddMember) {
      getMembers();
    }
  }, [isAddMember, getMembers]);

  const openModal = (key, id, role) => {
    setItemKey(key);
    setTargetId(id);
    setAccessType(role);
    console.log(role);
  };

  const handleDotsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const cancelRequest = async () => {
    let authToken = localStorage.getItem("token");

    try {
      const { data } = await axios.post(
        `https://frond-admin.code-minds.tech/api/projects/${frondId}/cancel-invite/${targetId}`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast("Invitation canceled", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });

      getMembers();
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  const removeMember = async () => {
    let authToken = localStorage.getItem("token");

    try {
      const { data } = await axios.post(
        `https://frond-admin.code-minds.tech/api/projects/${frondId}/remove-member/${targetId}`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast("Member removed", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });

      getMembers();
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  const { loadSettings } = useSettings({ projectId: frondId });
  const { frondSettings } = useContext(SettingsContext);

  const [showMember, setShowMember] = useState(false);

  useEffect(() => {
    if (!loadSettings) {
      if (frondSettings.can_edit_members && !frondSettings.is_owner) {
        setShowMember(true);
      } else if (frondSettings.is_owner) {
        setShowMember(true);
      } else {
        setShowMember(false);
      }
    }
  }, [loadSettings, frondSettings]);

  useEffect(() => {
    if (itemKey === "cancel request") {
      cancelRequest();
    } else if (itemKey === "remove member") {
      removeMember();
    } else if (itemKey === "change access") {
      setOpenAccessModal(true);
    }
    setItemKey(null);
  }, [itemKey, accessType]);

  return (
    <>
      <section className="people">
        {isLoading || loadSettings ? (
          <div className="min-h-[200px] flex justify-center items-center">
            <DataLoader />
          </div>
        ) : (
          <>
            <div className="count-action flex items-center justify-between gap-2.5 mb-[15px]">
              <p className="text-textColor font-bold phone:text-lg text-[12px] leading-[20.7px]">
                {members.length > 0 ? `${members.length} Members` : "0 Members"}
              </p>
              {showMember && !loadSettings ? (
                <button
                  type="button"
                  onClick={() => {
                    setInvitePeopleModal(true);
                  }}
                  className="bg-mainGradiant rounded-4xl phone:w-[158px] phone:h-[38px] phone:py-2.5 py-[5px] phone:px-[15px] px-2 flex justify-center items-center gap-2.5"
                >
                  <Image
                    src={addIcon}
                    alt="Add Member"
                    className="phone:w-[18px] phone:h-[18px] w-[12px] h-[12px]"
                  />
                  <span className="leading-[18.4px] text-white phone:text-base text-[12px]">
                    Add Members
                  </span>
                </button>
              ) : null}
            </div>
            {members.length > 0 && (
              <div className="members flex flex-col sm:mx-5">
                {members.map((member, idx) => (
                  <div
                    key={member.id}
                    className={`item flex justify-between gap-2.5 items-center phone:flex-nowrap flex-wrap ${
                      idx !== members.length - 1 &&
                      "mb-5 pb-5 border-b-1 border-solid border-grayBorder"
                    }`}
                  >
                    <div className="info flex gap-x-[15px] gap-y-5 items-center phone:w-auto w-full">
                      <Image
                        src={member?.img_url}
                        alt={member.name}
                        className="sm:min-w-[60px] sm:max-w-[60px] sm:min-h-[60px] sm:max-h-[60px] min-w-[45px] max-w-[45px] min-h-[45px] max-h-[45px] rounded-full object-cover"
                      />
                      <div>
                        <h3 className="font-bold sm:text-base phone:text-sm text-[12px] text-textColor leading-[22px] mb-[5px]">
                          {member.name}
                        </h3>
                        {member?.tasks_count > 0 && (
                          <p className="text-cardGrayColor leading-[18.4px] sm:text-base phone:text-sm text-[12px]">
                            {member?.tasks_count} Assigned{" "}
                            {member?.tasks_count === 1 ? "Task" : "Tasks"}
                          </p>
                        )}
                      </div>
                      {/* {member.role === "owner" &&
                        member.status !== "pending" && (
                          <Dropdown
                            classNames={{
                              content:
                                "min-w-[123px] max-w-[123px] py-0 px-[5px] rounded-[10px] phone:hidden block",
                            }}
                            placement="left-start"
                          >
                            <DropdownTrigger className="phone:hidden block self-start ms-auto">
                              <button
                                className="w-[24px] h-[24px]"
                                onClick={handleDotsClick}
                              >
                                <img src={dots} alt="Dots" />
                              </button>
                            </DropdownTrigger>
                            <DropdownMenu
                              aria-label="Frond Actions"
                              onAction={(key) => openModal(key, 1)}
                            >
                              <DropdownItem
                                textValue="change owner"
                                key="change owner"
                                className="text-cardGrayColor text-sm font-normal py-[2px] px-[5px] selected-action"
                              >
                                Change Owner
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        )} */}
                      {member.role === "member" &&
                      member.status !== "pending" &&
                      showMember ? (
                        <Dropdown
                          classNames={{
                            content:
                              "min-w-[140px] max-w-[140px] py-0 px-[5px] rounded-[10px] phone:hidden block",
                          }}
                          placement="left-start"
                        >
                          <DropdownTrigger className="phone:hidden block self-start ms-auto">
                            <button
                              className="w-[24px] h-[24px]"
                              onClick={handleDotsClick}
                            >
                              <img src={dots} alt="Dots" />
                            </button>
                          </DropdownTrigger>
                          <DropdownMenu
                            aria-label="Frond Actions"
                            onAction={(key) => {
                              openModal(
                                key,
                                member?.pivot?.user_id,
                                member?.role
                              );
                            }}
                          >
                            <DropdownItem
                              textValue="change access"
                              key="change access"
                              className="text-textColor text-sm font-bold py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                            >
                              Change Access
                            </DropdownItem>
                            <DropdownItem
                              textValue="remove member"
                              key="remove member"
                              className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                              color="danger"
                            >
                              Remove Member
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      ) : null}

                      {member.role === "guest" &&
                      member.status !== "pending" &&
                      showMember ? (
                        <Dropdown
                          classNames={{
                            content:
                              "min-w-[140px] max-w-[140px] py-0 px-[5px] rounded-[10px] phone:hidden block",
                          }}
                          placement="left-start"
                        >
                          <DropdownTrigger className="phone:hidden block self-start ms-auto">
                            <button
                              className="w-[24px] h-[24px]"
                              onClick={handleDotsClick}
                            >
                              <img src={dots} alt="Dots" />
                            </button>
                          </DropdownTrigger>
                          <DropdownMenu
                            aria-label="Frond Actions"
                            onAction={(key) => {
                              openModal(
                                key,
                                member?.pivot?.user_id,
                                member?.role
                              );
                            }}
                          >
                            <DropdownItem
                              textValue="change access"
                              key="change access"
                              className="text-textColor text-sm font-bold py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                            >
                              Change Access
                            </DropdownItem>
                            <DropdownItem
                              textValue="remove member"
                              key="remove member"
                              className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                              color="danger"
                            >
                              Remove Guest
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      ) : null}

                      {member.status === "pending" && showMember ? (
                        <Dropdown
                          classNames={{
                            content:
                              "min-w-[140px] max-w-[140px] py-0 px-[5px] rounded-[10px] phone:hidden block",
                          }}
                          placement="left-start"
                        >
                          <DropdownTrigger className="phone:hidden block self-start ms-auto">
                            <button
                              className="w-[24px] h-[24px]"
                              onClick={handleDotsClick}
                            >
                              <img src={dots} alt="Dots" />
                            </button>
                          </DropdownTrigger>
                          <DropdownMenu
                            aria-label="Frond Actions"
                            onAction={(key) =>
                              openModal(key, member?.pivot?.user_id)
                            }
                          >
                            <DropdownItem
                              textValue="Cancel Request"
                              key="cancel request"
                              className="text-textColor text-sm font-bold py-[2px] px-[5px] selected-action"
                            >
                              Cancel Request
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      ) : null}
                    </div>
                    <div className="actions gap-2.5 flex items-center">
                      <div
                        className={`${
                          member.role === "owner" && member.status !== "pending"
                            ? "bg-darkBlue"
                            : ""
                        } ${
                          showMember && member.role === "owner"
                            ? "me-[35px]"
                            : ""
                        } ${
                          member.role === "member" &&
                          member.status !== "pending"
                            ? "bg-greenTimeline"
                            : ""
                        } ${
                          member.role === "guest" && member.status !== "pending"
                            ? "bg-mainOrange"
                            : ""
                        } ${
                          member.status === "pending" && "bg-cardGrayColor"
                        } w-[98px] h-[28px] rounded-[6px] py-[6px] px-2.5 flex justify-center items-center gap-2.5`}
                      >
                        {member.role === "owner" &&
                          member.status !== "pending" && (
                            <Image
                              src={crownIcon}
                              alt={member.name}
                              className="w-[16px] h-[16px]"
                            />
                          )}
                        {member.role === "member" &&
                          member.status !== "pending" && (
                            <Image
                              src={userIcon}
                              alt={member.name}
                              className="w-[16px] h-[16px]"
                            />
                          )}
                        {member.role === "guest" &&
                          member.status !== "pending" && (
                            <i class="fa-solid fa-user-group text-sm text-white"></i>
                          )}
                        {/* {member.status === "pending" && (
                          <Image
                            src={loadingIcon}
                            alt={member.name}
                            className="w-[16px] h-[16px]"
                          />
                        )} */}
                        {member.status === "pending" ? (
                          <span className="text-white leading-[16.1px] text-sm">
                            Pending
                          </span>
                        ) : (
                          <span className="text-white leading-[16.1px] text-sm">
                            {member?.role[0].toUpperCase() +
                              member?.role.slice(1)}
                          </span>
                        )}
                      </div>
                      {/* {member.role === "owner" &&
                        member.status !== "pending" && (
                          <Dropdown
                            classNames={{
                              content:
                                "min-w-[123px] max-w-[123px] py-0 px-[5px] rounded-[10px] phone:block hidden",
                            }}
                            placement="left-start"
                          >
                            <DropdownTrigger className="phone:block hidden">
                              <button
                                className="w-[24px] h-[24px]"
                                onClick={handleDotsClick}
                              >
                                <img src={dots} alt="Dots" />
                              </button>
                            </DropdownTrigger>
                            <DropdownMenu
                              aria-label="Frond Actions"
                              onAction={(key) => openModal(key, 1)}
                            >
                              <DropdownItem
                                textValue="change owner"
                                key="change owner"
                                className="text-cardGrayColor text-sm font-normal py-[2px] px-[5px] selected-action"
                              >
                                Change Owner
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        )} */}
                      {member.role === "member" &&
                      member.status !== "pending" &&
                      showMember ? (
                        <Dropdown
                          classNames={{
                            content:
                              "min-w-[140px] max-w-[140px] py-0 px-[5px] rounded-[10px] phone:block hidden",
                          }}
                          placement="left-start"
                        >
                          <DropdownTrigger className="phone:block hidden">
                            <button
                              className="w-[24px] h-[24px]"
                              onClick={handleDotsClick}
                            >
                              <img src={dots} alt="Dots" />
                            </button>
                          </DropdownTrigger>
                          <DropdownMenu
                            aria-label="Frond Actions"
                            onAction={(key) =>
                              openModal(
                                key,
                                member?.pivot?.user_id,
                                member?.role
                              )
                            }
                          >
                            <DropdownItem
                              textValue="change access"
                              key="change access"
                              className="text-textColor text-sm font-bold py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                            >
                              Change Access
                            </DropdownItem>
                            <DropdownItem
                              textValue="remove member"
                              key="remove member"
                              className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                              color="danger"
                            >
                              Remove Member
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      ) : null}

                      {member.role === "guest" &&
                      member.status !== "pending" &&
                      showMember ? (
                        <Dropdown
                          classNames={{
                            content:
                              "min-w-[140px] max-w-[140px] py-0 px-[5px] rounded-[10px] phone:block hidden",
                          }}
                          placement="left-start"
                        >
                          <DropdownTrigger className="phone:block hidden">
                            <button
                              className="w-[24px] h-[24px]"
                              onClick={handleDotsClick}
                            >
                              <img src={dots} alt="Dots" />
                            </button>
                          </DropdownTrigger>
                          <DropdownMenu
                            aria-label="Frond Actions"
                            onAction={(key) =>
                              openModal(
                                key,
                                member?.pivot?.user_id,
                                member?.role
                              )
                            }
                          >
                            <DropdownItem
                              textValue="change access"
                              key="change access"
                              className="text-textColor text-sm font-bold py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                            >
                              Change Access
                            </DropdownItem>
                            <DropdownItem
                              textValue="remove member"
                              key="remove member"
                              className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                              color="danger"
                            >
                              Remove Guest
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      ) : null}

                      {member.status === "pending" && showMember ? (
                        <Dropdown
                          classNames={{
                            content:
                              "min-w-[140px] max-w-[140px] py-0 px-[5px] rounded-[10px] phone:block hidden",
                          }}
                          placement="left-start"
                        >
                          <DropdownTrigger className="phone:block hidden">
                            <button
                              className="w-[24px] h-[24px]"
                              onClick={handleDotsClick}
                            >
                              <img src={dots} alt="Dots" />
                            </button>
                          </DropdownTrigger>
                          <DropdownMenu
                            aria-label="Frond Actions"
                            onAction={(key) =>
                              openModal(key, member?.pivot?.user_id)
                            }
                          >
                            <DropdownItem
                              textValue="cancel request"
                              key="cancel request"
                              className="text-textColor text-sm font-bold py-[2px] px-[5px] selected-action"
                            >
                              Cancel Request
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </section>
      {invitePeopleModal && (
        <InvitePeople
          isOpen={invitePeopleModal}
          onClose={() => setInvitePeopleModal(false)}
          frondId={frondId}
        />
      )}
      {openAccessModal && (
        <ChangeAccessModal
          isOpen={openAccessModal}
          onClose={() => setOpenAccessModal(false)}
          frondId={frondId}
          userId={targetId}
          role={accessType}
        />
      )}
    </>
  );
};

export default People;
