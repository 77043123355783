import { SettingsContext } from "Context/SettingsContext";
import useSettings from "hooks/useSettings";
import React, { useContext, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

const ProtectedSettings = (props) => {
    const {frondId} = useParams();
    useSettings({ projectId: frondId });
    const {frondSettings} = useContext(SettingsContext);


    useEffect(() => {

    }, [frondSettings]);

    if (frondSettings) {
        return props.children
    } else {
        return <Navigate to={"/"}></Navigate>;
    }
};

export default ProtectedSettings;
