import {
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
} from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import closeIcon from "../../../../Assets/popup/cross-square.svg";
import { AuthContext } from "Context/AuthContext";
import { Controller, useForm, useWatch } from "react-hook-form";
import addNoteIcon from "../../../../Assets/scrapbook/add-note-icon.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { PopUpContext } from "Context/PopUpContext";

const AddNote = ({ isOpen, onClose, frondId, isModalOpen, noteId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      note: "",
    },
  });

  const [descLength, setDescLength] = useState(0);

  const getNoteData = async () => {
    setIsDataLoading(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/scrapbook-notes/${frondId}/${noteId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (data.status === 200) {
        reset(data?.response?.data);
        setDescLength(data?.response?.data.note?.length || 0);
      }
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDataLoading(false);
  };

  useEffect(() => {
    if (isOpen && noteId) {
      getNoteData();
    }
  }, [isOpen, noteId]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        handleResize();
      }, 1000);
    }
  }, [isModalOpen]);

  const handleResize = () => {
    const scrollOutter = document.querySelector(".scroll-outter");
    const scrollInner = document.querySelector(".scroll-inner");

    if (scrollOutter && scrollInner) {
      const scrollOutterWidth = scrollOutter.offsetWidth;
      const scrollInnerWidth = scrollInner.offsetWidth;
      scrollOutter.style.width = `calc(100% + ${
        scrollOutterWidth - scrollInnerWidth
      }px)`;
    }
  };

  const { token } = useContext(AuthContext);
  const { setIsAddNote } = useContext(PopUpContext);

  async function addNote(reqBody) {
    setIsLoading(true);
    setIsDisabled(true);

    try {
      if (noteId) {
        const { data } = await axios.post(
          `https://frond-admin.code-minds.tech/api/scrapbook-notes/${frondId}/${noteId}?_method=PUT`,
          {
            title: reqBody.title,
            note: reqBody.note,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (data.status === 200) {
          toast("Updates saved", {
            autoClose: 1500,
            position: "bottom-right",
            hideProgressBar: true,
            className: "rounded-4xl custom-toast success-toast",
          });
          setIsAddNote(true);
          setTimeout(() => {
            setIsAddNote(false);
          }, 1000);
          reset();
          onClose();
        }
      } else {
        const { data } = await axios.post(
          `https://frond-admin.code-minds.tech/api/scrapbook-notes/${frondId}`,
          {
            title: reqBody.title,
            note: reqBody.note,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (data.status === 201) {
          toast("Note created successfully", {
            autoClose: 1500,
            position: "bottom-right",
            hideProgressBar: true,
            className: "rounded-4xl custom-toast success-toast",
          });
          setIsAddNote(true);
          setTimeout(() => {
            setIsAddNote(false);
          }, 1000);
          reset();
          onClose();
        }
      }
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDisabled(false);
    setIsLoading(false);
  }

  useEffect(() => {
    handleResize();
  }, [descLength]);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onClose}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999] overflow-hidden",
        footer: "justify-center items-center",
        body: "px-[30px] mb-2.5",
        closeButton: "hidden",
        base: "rounded-[20px] p-5",
      }}
    >
      <div>
        <ModalContent>
          {(closeModal) => (
            <>
              <ModalHeader className="flex justify-between gap-2.5 items-center p-0 pb-[15px] pt-[15px] sm:px-5 px-2.5 mb-[15px] relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[0.5px] after:bg-grayBorder">
                <h3 className="text-lg text-textColor font-bold leading-[10.35px]">
                  {noteId ? "Edit Note" : "Add New Note"}
                </h3>
                <button onClick={closeModal} aria-label="Close">
                  <img src={closeIcon} alt="Close" className="w-6 h-6" />
                </button>
              </ModalHeader>
              <form onSubmit={handleSubmit(addNote)}>
                <div className="max-h-[60vh] overflow-y-auto scroll-outter">
                  <ModalBody className="w-[100%] scroll-inner">
                    <div className="relative about-edit-img sm:min-h-[295px] sm:max-h-[295px] min-h-[200px] max-h-[200px] sm:min-w-[100%] sm:max-w-[100%] min-w-[100%] max-w-[100%]">
                      <div className="content flex flex-col gap-5 items-center">
                        <Image
                          src={addNoteIcon}
                          className="w-[96px] h-[96px]"
                          alt="Add Note"
                        />

                        <Controller
                          name="title"
                          control={control}
                          rules={{
                            required: "Title is required",
                            minLength: {
                              value: 3,
                              message: "Minimum number of characters is 3",
                            },
                            maxLength: {
                              value: 100,
                              message: "Maximum number of characters is 100",
                            },
                            validate: {
                              noTrailingSpace: (value) =>
                                !/\s$/.test(value) ||
                                "Title shouldn't end with a space",
                            },
                            pattern: {
                              value:
                                // /^[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+[\sa-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+$/gi,
                                /^(?! )[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=\/\\\:]+(?!.*\s{2})[a-zA-Z0-9\-\/\\\_\'\"\.\,\!\@\:\#\$\%\^\&\*\(\)\+\= ]*$/gi,
                              message:
                                "Title may contain characters, numbers and special characters",
                            },
                          }}
                          render={({ field }) => (
                            <Input
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              {...field}
                              type="text"
                              variant="bordered"
                              placeholder="*Note Title"
                              isInvalid={errors.title?.message ? true : false}
                              errorMessage={errors.title?.message}
                              classNames={{
                                base: `input-noMargin ${
                                  errors.title?.message
                                    ? "h-[68px]"
                                    : "h-[39px]"
                                }`,
                                label:
                                  "text-textColor font-normal phone:text-sm text-[10px]",
                                input:
                                  "border-none px-[18px] phone:text-base text-[10px]",
                                mainWrapper: `${
                                  errors.title?.message
                                    ? "h-[68px]"
                                    : "h-[39px]"
                                }`,
                                innerWrapper: "h-[39px]",
                                inputWrapper: `border-[0.86px] p-0 border-solid ${
                                  errors.title?.message
                                    ? "invalid-text"
                                    : "border-[#E5E4EB]"
                                } rounded-[8px] h-[39px]`,
                              }}
                            />
                          )}
                        />

                        <div className="w-full mb-5">
                          <Controller
                            name="note"
                            control={control}
                            rules={{
                              required: "Note content is required",
                              minLength: {
                                value: 2,
                                message: "Minimum number of characters is 2",
                              },
                              maxLength: {
                                value: 255,
                                message: "Maximum number of characters is 255",
                              },
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9\-\_\.\,\!\@\#\$\%\^\&\*\(\)\+\=\'\\/\\\"\:]+(\s[A-Za-z0-9\-\/\\\_\.\,\!\@\#\$\%\^\&\*\(\)\+\=\'\"\:]+)*$/gi,
                                message:
                                  "Note content may contain characters, numbers or special characters and shouldn't end with space",
                              },
                            }}
                            render={({ field }) => (
                              <Textarea
                                {...field}
                                onInput={(e) =>
                                  setDescLength(e.target.value.length)
                                }
                                maxLength={255}
                                type="text"
                                variant="bordered"
                                placeholder="Share your note ..."
                                classNames={{
                                  base: "input-noMargin",
                                  label:
                                    "text-textColor font-normal phone:text-sm text-[10px]",
                                  input:
                                    "border-none px-[18px] min-h-[102px] phone:text-base text-[10px] overflow-y-auto",
                                  mainWrapper: "h-[102px]",
                                  innerWrapper: "h-[102px] overflow-y-auto",
                                  inputWrapper: `border-[0.86px] p-0 border-solid ${
                                    errors.note?.message
                                      ? "invalid-text"
                                      : "border-[#E5E4EB]"
                                  } rounded-[8px] h-[102px] overflow-y-auto`,
                                }}
                                isInvalid={errors.note?.message ? true : false}
                                errorMessage={errors.note?.message}
                              />
                            )}
                          />
                          {!errors.note?.message && 255 - descLength > 0 ? (
                            <p className="text-[.75rem] text-mainBlue mt-1">
                              {255 - descLength} remaining characters
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                </div>
                <ModalFooter className="shadow-modalShadow">
                  <button
                    disabled={isDisabled}
                    type="submit"
                    className="text-base font-bold leading-[18.4px] text-white flex justify-center items-center bg-mainGradiant rounded-[27px] py-[11px] px-[25px] w-[88px] sm:min-h-[39px] sm:max-h-[39px] min-h-[30px] max-h-[30px]"
                  >
                    {isLoading ? (
                      <span className="flex justify-center items-center text-white">
                        <i className="fa-solid fa-spinner fa-spin"></i>
                      </span>
                    ) : (
                      "Save"
                    )}
                  </button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </div>
    </Modal>
  );
};

export default AddNote;
