import React, { useContext, useEffect, useState } from "react";
import CardHeader from "./CardHeader";
import editPen from "../../Assets/frond-details/edit-pen.svg";
import rocket from "../../Assets/frond-details/rocket.svg";
import dollar from "../../Assets/frond-details/dollar.svg";
import calendar from "../../Assets/frond-details/calendar.svg";
import like from "../../Assets/frond-details/like.svg";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
} from "@nextui-org/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { OpenStreetMapProvider } from "react-leaflet-geosearch";
import L from "leaflet";
import mapMarker from "../../Assets/create-frond/geolocation.svg";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import tempMap from "../../Assets/create-frond/temp-map.png";
import axios from "axios";
import { toast } from "react-toastify";
import EditDetailsModal from "./EditDetailsModal";
import EditLocationModal from "./EditLocationModal";
import { SettingsContext } from "Context/SettingsContext";
import useSettings from "hooks/useSettings";
import placholderIcon from "../../Assets/frond-details/placholder-icon.svg";
import arrowLeft from "../../Assets/frond-details/arrow-left.svg";
import dots from "../../Assets/my-fronds/frond/dots.svg";
import editImgIcon from "../../Assets/frond-details/message-edit.svg";
import { PopUpContext } from "Context/PopUpContext";
import ShareFrond from "components/MyFrondsFuctions/ShareFrond";
import LeaveProjectModal from "components/SharedFrondsFunctions/LeaveProjectsModal";
import DeleteModal from "components/MyFrondsFuctions/DeleteModal";
import ArchiveModal from "components/MyFrondsFuctions/ArchiveModal";
import EditFrondImageModal from "./EditFrondImageModal";

const AboutFrondDetails = () => {
  // Map

  const [isShowMap, setIsShowMap] = useState(true);
  const [position, setPosition] = useState([0, 0]);
  const [isLoading, setIsLoading] = useState(false);
  const [frondData, setFrondData] = useState([]);
  const [editDetailsModal, setEditDetailsModal] = useState(false);
  const [editLoactionModal, setEditLocationModal] = useState(false);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [startDateArr, setStartDateArr] = useState("");
  const [endDateArr, setEndDateArr] = useState("");

  useEffect(() => {}, [position]);

  const [mapLoaded, setMapLoaded] = useState(false);

  const handleTileLoad = () => {
    setMapLoaded(true);
  };

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  const CustomMarker = () => {
    const map = useMap();
    map.setView(position, 12);

    const icon = L.divIcon({
      html: `<img src="${mapMarker}" style="width: 28px; height: 28px;" />`,
      className: "",
    });

    return <Marker position={position} icon={icon}></Marker>;
  };

  const { frondId } = useParams();
  let authToken = localStorage.getItem("token");

  const getFrondData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/frond/${frondId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFrondData(data?.response?.data);
      if (data?.response?.data?.frond?.start_date) {
        setStartDateArr(data?.response?.data?.frond?.start_date.split("/"));
      }
      if (data?.response?.data?.frond?.end_date) {
        setEndDateArr(data?.response?.data?.frond?.end_date.split("/"));
      }
      if (data?.response?.data?.latitude && data?.response?.data?.longitude) {
        setPosition([
          data?.response?.data?.latitude,
          data?.response?.data?.longitude,
        ]);
        setIsShowMap(false);
      }
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getFrondData();
  }, []);

  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setIsPageLoaded(true);
    };

    if (document.readyState === "complete") {
      setTimeout(() => {
        handleLoad();
      }, 1000);
    } else {
      window.addEventListener("load", handleLoad);
      return () => window.removeEventListener("load", handleLoad);
    }
  }, []);

  useSettings({ projectId: frondId });
  const { frondSettings } = useContext(SettingsContext);

  // Realtime

  useEffect(() => {
    getFrondData();
  }, [frondId]);

  // Change Img

  const [editImagenModal, setEditImageModal] = useState(false);

  const [itemKey, setItemKey] = useState(null);

  const [archiveFrondModal, setArchiveFrondModal] = useState(false);
  const [deleteFrondModal, setDeleteFrondModal] = useState(false);
  const [leaveFrondModal, setLeaveFrondModal] = useState(false);
  const [shareFrondModal, setShareFrondModal] = useState(false);

  const [imgDimensions, setImgDimensions] = useState([]);

  function getImageDimensions(url) {
    const img = document.createElement("img");

    return new Promise((resolve, reject) => {
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }

  getImageDimensions(frondData?.image)
    .then((dimensions) => {
      setImgDimensions([dimensions.width, dimensions.height]);
    })
    .catch((error) => {});

  const navigate = useNavigate();

  const { setIsEditTitle, setIsArchiveProject } = useContext(PopUpContext);

  const openModal = (key) => {
    setItemKey(key);

    if (key === "delete") {
      setDeleteFrondModal(true);
    } else if (key === "archive") {
      setArchiveFrondModal(true);
    } else if (key === "leave") {
      setLeaveFrondModal(true);
    } else if (key === "share") {
      setShareFrondModal(true);
    } else if (key === "unArchive") {
      unArchiveFrond();
    }
  };

  const unArchiveFrond = async () => {
    let authToken = localStorage.getItem("token");
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/projects/${frondId}/restore`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast("Project restored successfully", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });

      setIsArchiveProject(true);
      setTimeout(() => {
        setIsArchiveProject(false);
      }, 1000);

      setIsEditTitle(true);

      setTimeout(() => {
        setIsEditTitle(false);
      }, 1000);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  useSettings({ projectId: frondId });

  return (
    <>
      <section className="about-frond">
        <div className="flex gap-x-2.5 gap-y-2.5 items-start justify-between timelineMedia:flex-nowrap flex-wrap">
          <div className="content flex flex-col">
            <div className="flex items-start gap-5">
              <div className="info flex items-start phone:gap-[45px] gap-5 mb-5">
                <div className="flex flex-col gap-2">
                  <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                    Category
                  </h5>
                  <div className="flex items-center gap-[5px]">
                    <Image
                      src={rocket}
                      alt="Rocket"
                      className="phone:min-w-[18px] phone:min-h-[18px] phone:max-w-[18px] phone:max-h-[18px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                    />
                    <span className="leading-normal text-textColor phone:text-base text-[10px]">
                      {frondData?.frond?.category}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                    Importance
                  </h5>
                  <div
                    className={`${
                      !frondData?.frond?.priority && "text-center text-[10px]"
                    }`}
                  >
                    <span
                      className={`leading-normal ${
                        frondData?.frond?.priority === "High" &&
                        "text-[#FF3D00E5]"
                      } ${
                        frondData?.frond?.priority === "Medium" &&
                        "text-[#FFC000]"
                      } ${
                        frondData?.frond?.priority === "Low" && "text-[#5CCDB0]"
                      } ${!frondData?.frond?.priority && "text-textColor"}`}
                    >
                      {frondData?.frond?.priority
                        ? frondData?.frond?.priority
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                    Budget
                  </h5>
                  <div className="flex items-center gap-[5px]">
                    <Image
                      src={dollar}
                      alt="Dollar"
                      className="phone:min-w-[18px] phone:min-h-[18px] phone:max-w-[18px] phone:max-h-[18px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                    />
                    <span className="leading-normal text-textColor phone:text-base text-[10px]">
                      {frondData?.frond?.budget
                        ? frondData?.frond?.budget
                        : "-"}
                    </span>
                    <span className="leading-normal text-textColor phone:text-base text-[10px]">
                      {frondData?.frond?.currency
                        ? frondData?.frond?.currency
                        : "$"}
                    </span>
                  </div>
                </div>
              </div>
              {frondSettings.is_owner && (
                <button onClick={() => setEditDetailsModal(true)}>
                  <Image
                    src={editPen}
                    alt="Pen"
                    className="phone:min-w-6 phone:min-h-6 phone:max-w-6 phone:max-h-6 min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                  />
                </button>
              )}
            </div>
            <div className="info flex items-start gap-[22px] mb-5">
              <div className="flex flex-col gap-2">
                <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                  Start Date
                </h5>
                <div className="flex items-center gap-[5px]">
                  <Image
                    src={calendar}
                    alt="Calendar"
                    className="phone:min-w-[18px] phone:min-h-[18px] phone:max-w-[18px] phone:max-h-[18px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                  />
                  <span className="leading-normal text-textColor phone:text-base text-[10px]">
                    {startDateArr
                      ? `${startDateArr[0]} ${months[startDateArr[1] - 1]}, ${
                          startDateArr[2]
                        }`
                      : "-"}
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                  End Date
                </h5>
                <div className="flex items-center gap-[5px]">
                  <Image
                    src={calendar}
                    alt="Calendar"
                    className="phone:min-w-[18px] phone:min-h-[18px] phone:max-w-[18px] phone:max-h-[18px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                  />
                  <span className="leading-normal text-textColor phone:text-base text-[10px]">
                    {endDateArr
                      ? `${endDateArr[0]} ${months[endDateArr[1] - 1]}, ${
                          endDateArr[2]
                        }`
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className="info flex items-start gap-[22px] mb-5">
              <div className="flex flex-col gap-2">
                <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                  Description
                </h5>
                <div className={`${!frondData?.frond?.desc && "text-center"}`}>
                  <p className="leading-normal text-textColor phone:text-base text-[10px] break-all">
                    {frondData?.frond?.desc ? frondData?.frond?.desc : "-"}
                  </p>
                </div>
              </div>
            </div>
            <div className="info flex items-start gap-[22px] mb-5">
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-[5px]">
                  <Image
                    src={like}
                    alt="Heart"
                    className="phone:min-w-[18px] phone:min-h-[18px] phone:max-w-[18px] phone:max-h-[18px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                  />
                  <span className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                    Tags
                  </span>
                </div>
                <div
                  className={`flex items-center phone:text-base text-[10px] ${
                    frondData?.tags?.length === 0 && "justify-center"
                  } gap-2.5 flex-wrap`}
                >
                  {frondData?.tags?.length > 0
                    ? frondData?.tags.map((item, idx) => (
                        <div
                          key={idx}
                          className="py-1.5 px-2.5 rounded-[20px] bg-mainBlue text-white flex justify-center items-center"
                        >
                          <span className="phone:text-sm text-[10px] leading-[16.1px]">
                            {item.name.en}
                          </span>
                        </div>
                      ))
                    : "-"}
                </div>
              </div>
            </div>
          </div>
          {/* {isPageLoaded && (
            <div className="map timelineMedia:min-w-[314px] timelineMedia:max-w-[314px] min-w-full max-w-full min-h-[296px] border-2 border-solid border-grayBorder rounded-[20px] relative">
              <div className="w-full h-[224px] relative">
                <MapContainer
                  className="frond-details-map"
                  center={position}
                  zoom={12}
                  dragging={false}
                  doubleClickZoom={false}
                  zoomControl={false}
                  scrollWheelZoom={false}
                  style={{
                    height: "224px",
                    width: "100%",
                    position: "relative",
                    zIndex: "20",
                  }}
                  whenCreated={(map) => {
                    map.on("load", handleMapLoad);
                  }}
                >
                  <TileLayer
                    eventHandlers={{
                      tileload: handleTileLoad,
                    }}
                    attribution='<a href="https://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://tile.jawg.io/jawg-lagoon/{z}/{x}/{y}{r}.png?access-token=bGzRVT1WzXv8lPpb0ecTZTmZC0ZK2AIyJA2YMyPE5Dp1FNFqlX4sVyI6PSbA0iMH"
                  />
                  <CustomMarker />
                </MapContainer>
                {!mapLoaded && (
                  <div
                    className={`bg-gray-400 rounded-t-[20px] absolute inset-0 transition-all duration-300 flex justify-center items-center ${
                      mapLoaded ? "opacity-0 z-10" : "opacity-100 z-40"
                    }`}
                  >
                    <span className="flex gap-2 justify-center items-center text-white">
                      <i className="fa-solid fa-spinner fa-spin text-[30px]"></i>
                    </span>
                  </div>
                )}
                {isShowMap ? (
                  <div
                    className={`bg-gray-400 absolute inset-0 transition-all duration-300 rounded-t-[20px] ${
                      isShowMap ? "opacity-100 z-40" : "opacity-0 z-10"
                    }`}
                  >
                    <Image
                      classNames={{
                        wrapper: "w-full h-full min-w-full",
                        img: "temp-map-details rounded-[20px]",
                      }}
                      src={tempMap}
                      alt="Map"
                      className="size-full"
                    />
                  </div>
                ) : null}
              </div>
              <div className="flex justify-between items-start py-2.5 px-5 min-h-[72px]">
                <div className="flex flex-col gap-2">
                  <h5 className="text-base text-textColor font-normal leading-[18.4px]">
                    {frondData?.frond?.country
                      ? `${frondData?.frond?.country}, `
                      : `Country, `}
                    {frondData?.frond?.city ? frondData?.frond?.city : "City"}
                  </h5>
                  <p className="text-sm text-cardGrayColor">
                    {frondData?.frond?.location
                      ? frondData?.frond?.location
                      : "Location"}
                  </p>
                </div>
                {frondSettings.is_owner && (
                  <button onClick={() => setEditLocationModal(true)}>
                    <Image src={editPen} alt="Pen" className="w-6 h-6" />
                  </button>
                )}
              </div>
            </div>
          )} */}
          <div className="card-img relative">
            <div
              className={`absolute inset-0 ${
                frondData?.image ? "bg-frondDetails" : "bg-textColor"
              } z-20 rounded-[20px]`}
            ></div>
            <div className="absolute top-5 phone:left-5 phone:right-5 left-2.5 right-2.5 flex justify-end items-center z-30 phone:gap-[30px] gap-2.5">
              {/* <div className="flex phone:gap-2.5 gap-[5px] items-center overflow-hidden">
                <button
                  onClick={() => navigate(-1)}
                  className="phone:min-w-[24px] phone:min-h-[24px] min-w-[12px] min-h-[12px]"
                >
                  <Image
                    src={arrowLeft}
                    alt="Arrow"
                    className="phone:min-w-[24px] phone:min-h-[24px] min-w-[12px] min-h-[12px]"
                  />
                </button>
                <span className="phone:text-lg text-[12px] font-bold leading-[20.7px] text-white whitespace-nowrap">
                  {frondData?.frond?.title ? frondData?.frond?.title : "-"}
                </span>
              </div> */}
              <div className="flex gap-2.5 items-center min-w-fit">
                {frondSettings.is_owner && (
                  <button
                    onClick={() => setEditImageModal(true)}
                    className="flex gap-2.5 items-center shadow-editImgShadow"
                  >
                    <Image
                      src={editImgIcon}
                      alt="Edit Image"
                      className="phone:min-w-[24px] phone:min-h-[24px] phone:max-w-[24px] phone:max-h-[24px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                    />
                    <span className="leading-[18.4px] text-white phone:text-base text-[12px]">
                      Edit Image
                    </span>
                  </button>
                )}
                {/* <Dropdown
                  classNames={{
                    content: `${
                      !frondData?.is_archived
                        ? "min-w-[88px] max-w-[88px]"
                        : "min-w-[92px] max-w-[92px]"
                    } py-0 px-[5px] rounded-[10px]`,
                  }}
                  placement="left-start"
                >
                  <DropdownTrigger>
                    <button className="w-[24px] h-[24px] relative z-40">
                      <img
                        src={dots}
                        alt="Dots"
                        className="phone:min-w-[24px] phone:max-w-[24px] phone:min-h-[24px] phone:max-h-[24px] min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px]"
                      />
                    </button>
                  </DropdownTrigger>
                  <DropdownMenu
                    aria-label="Frond Actions"
                    onAction={(key) => {
                      openModal(key);
                    }}
                  >
                    {!frondData?.is_shared && (
                      <DropdownItem
                        textValue="edit frond"
                        key="share"
                        className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                      >
                        Share
                      </DropdownItem>
                    )}
                    {!frondData?.is_archived && !frondData?.is_shared && (
                      <DropdownItem
                        textValue="edit frond"
                        key="archive"
                        className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                      >
                        Archive
                      </DropdownItem>
                    )}
                    {frondData?.is_archived && (
                      <DropdownItem
                        textValue="edit frond"
                        key="unArchive"
                        className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                      >
                        Unarchive
                      </DropdownItem>
                    )}
                    {frondData?.is_shared && (
                      <DropdownItem
                        textValue="edit frond"
                        key="leave"
                        className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                        color="danger"
                      >
                        Leave
                      </DropdownItem>
                    )}
                    {!frondData?.is_shared && (
                      <DropdownItem
                        key="delete"
                        className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                        color="danger"
                      >
                        Delete
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown> */}
              </div>
            </div>
            {frondData?.image ? (
              // <div
              //   className="w-full h-[350px] rounded-[20px] overflow-hidden"
              //   style={{
              //     backgroundImage: `url(${cardImg})`,
              //     backgroundSize: "contain",
              //     backgroundPosition: "top-center",
              //     backgroundRepeat: "no-repeat",
              //   }}
              // />

              // <div
              //   style={{
              //     backgroundImage: `url(${cardImg})`,
              //     backgroundSize: "100%",
              //     backgroundRepeat: "no-repeat",
              //     backgroundPosition: "25% 40%",
              //     width: "100%",
              //     height: "calc(100vw * 0.75)",
              //     maxHeight: "500px",
              //     borderRadius: "20px",
              //     overflow: "hidden",
              //   }}
              // />

              <div
                style={{
                  backgroundImage: `url(${frondData?.image})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  width: "300px",
                  height: "300px",
                  maxHeight: "300px",
                  borderRadius: "20px",
                  overflow: "hidden",
                }}
              />
            ) : (
              <div className="flex justify-center w-[300px] h-[300px] items-center">
                <Image
                  classNames={{ wrapper: "z-30" }}
                  src={placholderIcon}
                  alt={frondData?.frond?.title}
                  className="w-[46px] h-[50px]"
                />
              </div>
            )}
          </div>
        </div>
      </section>
      {editDetailsModal && (
        <EditDetailsModal
          isOpen={editDetailsModal}
          isModalOpen={true}
          onClose={() => {
            setEditDetailsModal(false);
          }}
          getFrondData={getFrondData}
          frondData={{
            editId: frondData?.frond?.id,
            editTitle: frondData?.frond?.title,
            editCategory: frondData?.frond?.category,
            editDesc: frondData?.frond?.desc,
            editStartDate: startDateArr,
            editEndDate: endDateArr,
            editBudget: frondData?.frond?.budget,
            editCurrecy: frondData?.frond?.currency,
            editTags: frondData?.tags,
            editImportance: frondData?.frond?.priority,
            editLatitude: frondData?.latitude,
            editLongitude: frondData?.longitude,
            editCountry: frondData?.frond?.country,
            editCity: frondData?.frond?.city,
            editLocation: frondData?.frond?.location,
          }}
        />
      )}
      {editLoactionModal && (
        <EditLocationModal
          isOpen={editLoactionModal}
          onClose={() => {
            setEditLocationModal(false);
          }}
          getFrondData={getFrondData}
          isModalOpen={true}
          frondData={{
            editId: frondData?.frond?.id,
            editLatitude: frondData?.latitude,
            editLongitude: frondData?.longitude,
            editCountry: frondData?.frond?.country,
            editCity: frondData?.frond?.city,
            editLocation: frondData?.frond?.location,
          }}
        />
      )}
      {editImagenModal && (
        <EditFrondImageModal
          isOpen={editImagenModal}
          onClose={() => {
            setEditImageModal(false);
          }}
          editId={frondData?.frond?.id}
          editTitle={frondData?.frond?.title}
          editCategory={frondData?.frond?.category}
          editImage={frondData?.image}
          editBudget={frondData?.frond?.budget}
          editCurrecy={frondData?.frond?.currency}
          editDesc={frondData?.frond?.desc}
          editStartDate={startDateArr}
          editEndDate={endDateArr}
          editImportance={frondData?.frond?.priority}
          editLatitude={frondData?.latitude}
          editLongitude={frondData?.longitude}
          editCountry={frondData?.frond?.country}
          editCity={frondData?.frond?.city}
          editLocation={frondData?.frond?.location}
          editTags={frondData?.tags}
          getFrondData={getFrondData}
        />
      )}
      {itemKey === "archive" && (
        <ArchiveModal
          isOpen={archiveFrondModal}
          onClose={() => {
            setArchiveFrondModal(false);
          }}
          frondId={frondId}
          flag={true}
        />
      )}
      {itemKey === "delete" && (
        <DeleteModal
          isOpen={deleteFrondModal}
          onClose={() => {
            setDeleteFrondModal(false);
          }}
          flag={true}
          frondId={frondId}
        />
      )}
      {itemKey === "leave" && (
        <LeaveProjectModal
          isOpen={leaveFrondModal}
          onClose={() => {
            setLeaveFrondModal(false);
          }}
          frondId={frondId}
          flag={true}
        />
      )}
      {itemKey === "share" && (
        <ShareFrond
          isOpen={shareFrondModal}
          onClose={() => {
            setShareFrondModal(false);
          }}
          isModalOpen={shareFrondModal}
          frondId={frondId}
        />
      )}
    </>
  );
};

export default AboutFrondDetails;
