import {
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import closeIcon from "../../../../Assets/popup/cross-square.svg";
import { AuthContext } from "Context/AuthContext";
import addVoiceIcon from "../../../../Assets/scrapbook/add-voice-icon.svg";
import pauseVoiceIcon from "../../../../Assets/scrapbook/pause-voice.svg";

import axios from "axios";
import { toast } from "react-toastify";
import { PopUpContext } from "Context/PopUpContext";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";

const VoiceModal = ({ isOpen, onClose, frondId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(null);
  const [record, setRecord] = useState(null);
  const { token } = useContext(AuthContext);
  const { setIsAddRecord } = useContext(PopUpContext);
  const [recordState, setRecordState] = useState(null);

  const [timer, setTimer] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  const start = () => {
    setRecordState(RecordState.START);
    if (!intervalId) {
      const id = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
      setIntervalId(id);
    }
  };

  const pause = () => {
    setRecordState(RecordState.PAUSE);
    clearInterval(intervalId);
    setIntervalId(null);
  };

  const stop = () => {
    const titleValidationError = validateTitle(title);
    if (titleValidationError) {
      setTitleError(titleValidationError);
      setRecordState(RecordState.PAUSE);
      clearInterval(intervalId);
      setIntervalId(null);
      return;
    } else {
      setRecordState(RecordState.STOP);
      clearInterval(intervalId);
      setIntervalId(null);
      setTimer(0);
    }
  };

  const onStop = (audioData) => {
    handleSave(audioData);
  };

  const validateTitle = (title) => {
    if (!title) {
      return "Title is required";
    }
    if (title.length < 3) {
      return "Minimum number of characters is 3";
    }
    if (title.length > 100) {
      return "Maximum number of characters is 100";
    }
    if (/\s$/.test(title)) {
      return "Title shouldn't end with a space";
    }
    if (
      !/^(?! )[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=\/\\\:]+(?!.*\s{2})[a-zA-Z0-9\-\/\\\_\'\"\.\,\!\:\@\#\$\%\^\&\*\(\)\+\= ]*$/gi.test(
        title
      )
    ) {
      return "Title may contain characters, numbers and special characters";
    }
    return null;
  };

  const handleSave = async (voice) => {
    if (!voice) {
      toast("No recording available. Please record your memo.", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
      return;
    }
    setTitleError(null);
    setIsLoading(true);
    setIsDisabled(true);

    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append(
        "memo",
        new File([voice.blob], "recording.mp3", { type: "audio/mp3" })
      );

      const { data } = await axios.post(
        `https://frond-admin.code-minds.tech/api/scrapbook-memos/${frondId}`,
        formData,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (data.status === 200) {
        setIsAddRecord(true);
        setTimeout(() => {
          setIsAddRecord(false);
        }, 1000);
        toast("Record created successfully", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast success-toast",
        });
      }
      onClose();
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }

    setIsDisabled(false);
    setIsLoading(false);
  };

  const formatTime = (timer) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onClose}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999] overflow-hidden",
        footer: "justify-center items-center",
        body: "px-[30px] mb-2.5",
        closeButton: "hidden",
        base: "rounded-[20px] p-5",
      }}
    >
      <div>
        <ModalContent>
          {(closeModal) => (
            <>
              <ModalHeader className="flex justify-between gap-2.5 items-center p-0 pb-[15px] pt-[15px] sm:px-5 px-2.5 mb-[15px] relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[0.5px] after:bg-grayBorder">
                <h3 className="text-lg text-textColor font-bold leading-[10.35px]">
                  Add Voice Memo
                </h3>
                <button onClick={closeModal} aria-label="Close">
                  <img src={closeIcon} alt="Close" className="w-6 h-6" />
                </button>
              </ModalHeader>
              <div className="max-h-[60vh] overflow-y-auto w-[calc(100%+17px)]">
                <ModalBody className="w-[calc(100%-17px)] item-center">
                  <AudioReactRecorder state={recordState} onStop={onStop} />
                  <div className="relative about-edit-img sm:min-h-[295px] sm:max-h-[295px] min-h-[200px] max-h-[200px] sm:min-w-[100%] sm:max-w-[100%] min-w-[100%] max-w-[100%]">
                    <div className="content flex flex-col gap-5 items-center">
                      <Image
                        src={addVoiceIcon}
                        className="w-[96px] h-[96px]"
                        alt="Add Note"
                      />
                      <Input
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                          setTitleError(validateTitle(e.target.value));
                        }}
                        type="text"
                        variant="bordered"
                        placeholder="*Voice Memo Title"
                        classNames={{
                          base: "input-noMargin h-[39px]",
                          label:
                            "text-textColor font-normal phone:text-sm text-[10px]",
                          input:
                            "border-none px-[18px] phone:text-base text-[10px]",
                          mainWrapper: "h-[39px]",
                          innerWrapper: "h-[39px]",
                          inputWrapper: `border-[0.86px] p-0 border-solid ${
                            titleError ? "invalid-text" : "border-[#E5E4EB]"
                          } rounded-[8px] h-[39px]`,
                        }}
                        isInvalid={!!titleError}
                        errorMessage={titleError}
                      />
                      <div className="mb-5">
                        <div className="flex flex-col gap-5 items-center justify-center">
                          <p className="text-textColor leading-[20.7px] text-lg font-bold">
                            {formatTime(timer)}
                          </p>
                          {recordState === null ||
                          recordState === "pause" ||
                          recordState === "stop" ? (
                            <button
                              type="button"
                              onClick={start}
                              className="w-[54px] h-[54px] bg-[#E65C6C1A] rounded-full flex justify-center items-center"
                            >
                              <div className="w-[34.2px] h-[34.2px] bg-[#E65C6C] rounded-full"></div>
                            </button>
                          ) : null}
                          {recordState === "start" && (
                            <button
                              type="button"
                              onClick={pause}
                              className="w-[54px] h-[54px] bg-[#E65C6C1A] rounded-full flex justify-center items-center"
                            >
                              <div className="w-[34.2px] h-[34.2px] bg-[#E65C6C] rounded-full flex justify-center items-center">
                                <Image
                                  src={pauseVoiceIcon}
                                  alt="Pause Record"
                                  className="w-[24px] h-[24px]"
                                />
                              </div>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </div>
              <ModalFooter className="shadow-modalShadow">
                <button
                  disabled={isDisabled}
                  type="button"
                  onClick={stop}
                  className="text-base font-bold leading-[18.4px] text-white flex justify-center items-center bg-mainGradiant rounded-[27px] py-[11px] px-[25px] w-[88px] sm:min-h-[39px] sm:max-h-[39px] min-h-[30px] max-h-[30px]"
                >
                  {isLoading ? (
                    <span className="flex justify-center items-center text-white">
                      <i className="fa-solid fa-spinner fa-spin"></i>
                    </span>
                  ) : (
                    "Save"
                  )}
                </button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </div>
    </Modal>
  );
};

export default VoiceModal;
