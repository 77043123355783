import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import plus from "../../Assets/timeline/plus-squared.svg";
import dots from "../../Assets/timeline/dots-vertical.svg";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
} from "@nextui-org/react";
import AddTaskEvent from "./TimelineFunctions/AddTaskEvent";
import axios from "axios";
import { PopUpContext } from "Context/PopUpContext";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { LoadingContext } from "Context/LoadingContext";
import useSettings from "hooks/useSettings";
import { SettingsContext } from "Context/SettingsContext";

const Timeline = () => {
  const [isOpenAddEvent, setIsOpenAddEvent] = useState(false);

  const [targetId, setTargetId] = useState(null);
  const [itemKey, setItemKey] = useState(null);

  const { frondId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [frondTasks, setFrondTasks] = useState([]);
  let authToken = localStorage.getItem("token");

  const { isTimelineLoaded, setIsTimelineLoaded } = useContext(LoadingContext);

  useLayoutEffect(() => {
    if (!isTimelineLoaded) {
      setTimeout(() => {
        setIsTimelineLoaded(true);
      }, 2000);
    }
  }, [isTimelineLoaded]);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const getFrondTasks = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/projects/${frondId}/events`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      const sortedData = data.sort((a, b) => {
        const dateA = a?.event?.due_date
          ? new Date(
              a.event.due_date.split("/").reverse().join("-") // Convert DD/MM/YYYY to YYYY-MM-DD
            )
          : null;
        const dateB = b?.event?.due_date
          ? new Date(
              b.event.due_date.split("/").reverse().join("-") // Convert DD/MM/YYYY to YYYY-MM-DD
            )
          : null;

        // Sort elements with dates first, and elements without dates last
        if (!dateA) return 1; // Place 'a' at the end if no date
        if (!dateB) return -1; // Place 'b' at the end if no date

        // Compare the two dates (earlier dates come first)
        return dateA - dateB;
      });

      setFrondTasks(sortedData);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getFrondTasks();
  }, []);

  const { isAddEvent, setEdtiTimeline } = useContext(PopUpContext);

  useEffect(() => {
    if (isAddEvent) {
      getFrondTasks();
    }
  }, [isAddEvent]);

  const deleteRecord = async () => {
    if (!targetId) return;
    try {
      const { data } = await axios.delete(
        `https://frond-admin.code-minds.tech/api/events/${targetId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      getFrondTasks();
      toast("Event Deleted Successfully", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  useEffect(() => {
    if (itemKey === "delete") {
      deleteRecord();
      setItemKey(null);
    } else if (itemKey === "edit") {
      setIsOpenAddEvent(true);
      setItemKey(null);
    }
  }, [itemKey]);

  const openModal = (key, id, type) => {
    setItemKey(key);
    setTargetId(id);
    if (type === "milestone") {
      setEdtiTimeline("milestone");
    } else {
      setEdtiTimeline("task");
    }
  };

  const handleDotsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  useSettings({ projectId: frondId });
  const { frondSettings } = useContext(SettingsContext);

  const [showTimeline, setShowTimeline] = useState(false);

  useEffect(() => {
    if (frondSettings.can_edit_timeline && !frondSettings.is_owner) {
      setShowTimeline(true);
    } else if (frondSettings.is_owner) {
      setShowTimeline(true);
    } else {
      setShowTimeline(false);
    }
  }, [frondSettings]);

  return (
    <>
      <section
        className={`timeline hidden lg:block p-5 right-5 lg:min-w-[317px] lg:max-w-[317px] bg-white rounded-4xl border-2 border-solid border-grayBorder fixed z-20 no-focus transition-all duration-400 ${
          !isTimelineLoaded ? "opacity-0" : "opacity-100"
        }`}
      >
        <div className="flex justify-between items-center gap-2.5 border-b-1 border-solid border-grayBorder mb-2.5 pb-2.5">
          <h3 className="font-bold text-textColor leading-[20.7px] text-lg">
            Timeline
          </h3>
          {showTimeline ? (
            <button
              type="button"
              onClick={() => {
                setTargetId(null);
                setIsOpenAddEvent(true);
              }}
              className="flex items-center justify-center w-[122px] h-[30px] py-1.5 px-2.5 rounded-4xl bg-darkBlue gap-2.5 shadow-addEventBtnShadow"
            >
              <Image
                src={plus}
                alt="Add Timeline"
                className="min-w-[18px] min-h-[18px] max-w-[18px] max-h-[18px]"
              />
              <h4 className="text-white leading-[18.4px] text-base font-normal">
                Add Event
              </h4>
            </button>
          ) : null}
        </div>
        {frondTasks?.length > 0 && (
          <div className="content flex flex-col gap-3">
            {frondTasks.map((item, idx) => (
              <div
                key={idx}
                className="flex items-center justify-between gap-2.5 min-h-14"
              >
                {item?.event?.due_date ? (
                  <div className="text-textColor text-center">
                    <p className="font-bold leading-[22px]">
                      {item?.event?.due_date?.split("/")[0]}
                    </p>
                    <p className="text-sm leading-[22px]">
                      {months[item?.event?.due_date?.split("/")[1] - 1]}
                    </p>
                  </div>
                ) : (
                  <div className="text-textColor text-center flex-1">
                    <p className="font-bold leading-[22px]">DD</p>
                    <p className="text-sm leading-[22px]">MM</p>
                  </div>
                )}
                <div
                  className={`flex gap-[14px] ${
                    item?.event?.type === "milestone" && "items-center"
                  }`}
                >
                  {item?.event?.type === "milestone" ? (
                    <div
                      className={`min-w-[14.14px] max-w-[14.14px] min-h-[14.14px] max-h-[14.14px] ${
                        item?.event?.status === "pending" && "bg-blueTimeline"
                      } ${
                        item?.event?.status === "completed" &&
                        "bg-greenTimeline"
                      } ${
                        item?.event?.status === "in_progress" &&
                        "bg-blueTimeline"
                      } rotate-[45deg]`}
                    ></div>
                  ) : (
                    <div
                      className={`min-w-1.5 max-w-1.5 min-h-full ${
                        item?.event?.status === "pending" && "bg-blueTimeline"
                      } ${
                        item?.event?.status === "completed" &&
                        "bg-greenTimeline"
                      } ${
                        item?.event?.status === "in_progress" &&
                        "bg-redTimeline"
                      } rounded-[10px]`}
                    ></div>
                  )}
                  <button
                    onClick={() => {
                      if (showTimeline) {
                        openModal("edit", item?.event?.id, item?.event?.type);
                      }
                    }}
                    className={`bg-white py-1.5 px-[7px] rounded-lg shadow-eventBoxShadow flex justify-between items-start gap-5 w-[205px] ${
                      showTimeline ? "cursor-pointer" : "cursor-auto"
                    }`}
                  >
                    <p className="text-textColor text-sm leading-[22px] break-all">
                      {item?.event?.title?.length > 67
                        ? item?.event?.title.slice(0, 67)
                        : item?.event?.title}
                    </p>
                    {showTimeline ? (
                      <Dropdown
                        classNames={{
                          content:
                            "min-w-[88px] max-w-[88px] py-0 px-[5px] rounded-[10px]",
                        }}
                        placement="left-start"
                      >
                        <DropdownTrigger>
                          <button
                            className="w-[24px] h-[24px]"
                            onClick={handleDotsClick}
                          >
                            <img
                              src={dots}
                              alt="Dots"
                              className="min-w-[24px] min-h-[24px]"
                            />
                          </button>
                        </DropdownTrigger>
                        <DropdownMenu
                          aria-label="Frond Actions"
                          onAction={(key) =>
                            openModal(key, item?.event?.id, item?.event?.type)
                          }
                        >
                          <DropdownItem
                            textValue="edit note"
                            key="edit"
                            className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                          >
                            Edit
                          </DropdownItem>
                          <DropdownItem
                            key="delete"
                            className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                            color="danger"
                          >
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    ) : null}
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </section>
      {isOpenAddEvent && !targetId && (
        <AddTaskEvent
          isOpen={isOpenAddEvent}
          onClose={() => {
            setIsOpenAddEvent(false);
          }}
          isModalOpen={true}
        />
      )}
      {isOpenAddEvent && targetId && (
        <AddTaskEvent
          isOpen={isOpenAddEvent}
          onClose={() => {
            setIsOpenAddEvent(false);
          }}
          targetId={targetId}
          isModalOpen={true}
        />
      )}
    </>
  );
};

export default Timeline;
